import { createSlice } from "@reduxjs/toolkit";
import {
  createtSubDealerThunk,
  deleteSubDealerThunk,
  getSubDealerByIdThunk,
  getSubDealersThunk,
  updateSubDealerThunk,
} from "./subDealerThunk";

type StateType = {
  subDealers: Array<PosPazarTypes.SubDealerItem>;
  subDealersStatus: PosPazarTypes.ApiRequestStatus;
  subDealersError: string | undefined;

  subDealer: PosPazarTypes.SubDealerItem | null;
  subDealerStatus: PosPazarTypes.ApiRequestStatus;
  subDealerError: string | undefined;

  subDealerCreateStatus: PosPazarTypes.ApiRequestStatus;
  subDealerCreateError: string | undefined;
  subDealerUpdateStatus: PosPazarTypes.ApiRequestStatus;
  subDealerUpdateError: string | undefined;
  subDealerDeleteStatus: PosPazarTypes.ApiRequestStatus;
  subDealerDeleteError: string | undefined;
};

const initialState: StateType = {
  subDealers: [],
  subDealersStatus: "idle",
  subDealersError: undefined,

  subDealer: null,
  subDealerStatus: "idle",
  subDealerError: undefined,

  subDealerCreateStatus: "idle",
  subDealerCreateError: undefined,
  subDealerUpdateStatus: "idle",
  subDealerUpdateError: undefined,
  subDealerDeleteStatus: "idle",
  subDealerDeleteError: undefined,
};

const subDealerSlice = createSlice({
  name: "subDealer",
  initialState,
  reducers: {
    resetSubDealerState(state) {
      state.subDealer = null;
      state.subDealerStatus = "idle";
      state.subDealerError = undefined;
      state.subDealerCreateStatus = "idle";
      state.subDealerCreateError = undefined;
      state.subDealerUpdateStatus = "idle";
      state.subDealerUpdateError = undefined;
      state.subDealerDeleteStatus = "idle";
      state.subDealerDeleteError = undefined;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSubDealersThunk.pending, (state) => {
        state.subDealersStatus = "loading";
        state.subDealersError = undefined;
      })
      .addCase(getSubDealersThunk.fulfilled, (state, action) => {
        state.subDealers = action.payload;
        state.subDealersStatus = "succeeded";
        state.subDealersError = undefined;
      })
      .addCase(getSubDealersThunk.rejected, (state, action) => {
        state.subDealers = [];
        state.subDealersStatus = "failed";
        state.subDealersError = action.payload?.message;
      });
    builder
      .addCase(getSubDealerByIdThunk.pending, (state) => {
        state.subDealerStatus = "loading";
        state.subDealerError = undefined;
      })
      .addCase(getSubDealerByIdThunk.fulfilled, (state, action) => {
        state.subDealer = action.payload;
        state.subDealerStatus = "succeeded";
        state.subDealerError = undefined;
      })
      .addCase(getSubDealerByIdThunk.rejected, (state, action) => {
        state.subDealer = null;
        state.subDealerStatus = "failed";
        state.subDealerError = action.payload?.message;
      });
    builder
      .addCase(createtSubDealerThunk.pending, (state) => {
        state.subDealerCreateStatus = "loading";
        state.subDealerCreateError = undefined;
      })
      .addCase(createtSubDealerThunk.fulfilled, (state) => {
        state.subDealerCreateStatus = "succeeded";
        state.subDealerCreateError = undefined;
      })
      .addCase(createtSubDealerThunk.rejected, (state, action) => {
        state.subDealerCreateStatus = "failed";
        state.subDealerCreateError = action.payload?.message;
      });
    builder
      .addCase(updateSubDealerThunk.pending, (state) => {
        state.subDealerUpdateStatus = "loading";
        state.subDealerUpdateError = undefined;
      })
      .addCase(updateSubDealerThunk.fulfilled, (state) => {
        state.subDealerUpdateStatus = "succeeded";
        state.subDealerUpdateError = undefined;
      })
      .addCase(updateSubDealerThunk.rejected, (state, action) => {
        state.subDealerUpdateStatus = "failed";
        state.subDealerUpdateError = action.payload?.message;
      });
    builder
      .addCase(deleteSubDealerThunk.pending, (state) => {
        state.subDealerDeleteStatus = "loading";
        state.subDealerDeleteError = undefined;
      })
      .addCase(deleteSubDealerThunk.fulfilled, (state) => {
        state.subDealerDeleteStatus = "succeeded";
        state.subDealerDeleteError = undefined;
      })
      .addCase(deleteSubDealerThunk.rejected, (state, action) => {
        state.subDealerDeleteStatus = "failed";
        state.subDealerDeleteError = action.payload?.message;
      });
  },
});

export const { resetSubDealerState } = subDealerSlice.actions;
export default subDealerSlice.reducer;
