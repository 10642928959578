import React, { useState } from "react";
import { Trans } from "react-i18next";
import { Card, CardBody, Spinner, Tooltip } from "reactstrap";
import CountUp from "react-countup";

interface DashboardCounterProps {
  value: PosPazarTypes.TransactionStatistics | undefined;
  status: PosPazarTypes.ApiRequestStatus;
  label: string;
  cardColor: string;
  errorMesage?: string;
}

const DashboardWidget = (props: DashboardCounterProps) => {
  const { value, status, errorMesage, cardColor, label } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <React.Fragment>
      <Card className="card-animate" color={cardColor}>
        <CardBody className="bg-marketplace d-flex justify-content-center align-items-center">
          <div className="w-100">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  {label}
                </p>
              </div>
              <div>
                <i className="fs-16 align-middle ri-bank-card-fill"></i>
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h3 className="fs-25 fw-semibold ff-secondary mb-4">
                  {status === "loading" && <Spinner />}
                  {status === "succeeded" && (
                    <CountUp
                      start={0}
                      end={value?.total_amount ?? 0}
                      suffix=" ₺"
                    />
                  )}
                  {status === "failed" && (
                    <div>
                      <i
                        id="CustomTableErrorTooltip"
                        className="ri-error-warning-fill text-danger"
                        color="red"
                      />
                      <Tooltip
                        isOpen={tooltipOpen}
                        target="CustomTableErrorTooltip"
                        toggle={toggleTooltip}
                      >
                        <Trans
                          i18nKey={errorMesage ?? "Unknown error occurred"}
                        />
                      </Tooltip>
                    </div>
                  )}
                </h3>
                <div>
                  <Trans i18nKey="Successful transaction count:" />{" "}
                  {value?.successful_count ?? 0}
                </div>
                <div>
                  <Trans i18nKey="Failed transaction count:" />{" "}
                  {value?.failed_count ?? 0}
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default DashboardWidget;
