import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { toast } from "react-toastify";
import { Button, Form, FormFeedback, Input, Label, Spinner } from "reactstrap";
import { RootState } from "slices";
import { resetPasswordReducer } from "slices/dashboard/dashboardReducer";
import { changePasswordThunk } from "slices/thunks";

const ChangePassword: React.FC<PropsFromRedux> = (props) => {
  const {
    changePasswordError,
    changePasswordStatus,
    changePassword,
    resetPassword,
  } = props;

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordShown, setPasswordShown] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const { t } = useTranslation();
  const isButtonDisabled = !oldPassword || !newPassword || !confirmPassword;

  useEffect(() => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setErrors({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setPasswordShown({
      oldPassword: false,
      newPassword: false,
      confirmPassword: false,
    });

    return () => {
      resetPassword();
    };
  }, [resetPassword]);

  useEffect(() => {
    let toastId: any;
    switch (changePasswordStatus) {
      case "succeeded":
        toastId = toast.success(t("Password changed successfully"), {
          autoClose: 3000,
        });
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        toast.onChange((item) => {
          if (item.id === toastId && item.status === "removed") {
            resetPassword();
          }
        });
        break;
      case "failed":
        toastId = toast.error(
          changePasswordError ?? t("Unknown error occurred"),
          {
            autoClose: 3000,
          }
        );
        toast.onChange((item) => {
          if (item.id === toastId && item.status === "removed") {
            resetPassword();
          }
        });
        break;
      default:
        break;
    }
  }, [changePasswordError, changePasswordStatus, t, resetPassword]);

  const handleChangePassword = () => {
    let isValid = true;
    if (!oldPassword) {
      setErrors((prev) => ({
        ...prev,
        oldPassword: "Old password is required",
      }));
      isValid = false;
    }
    if (!newPassword) {
      setErrors((prev) => ({
        ...prev,
        newPassword: "New password is required",
      }));
      isValid = false;
    } else if (newPassword.length < 6) {
      setErrors((prev) => ({
        ...prev,
        newPassword: "Password must be at least 6 characters",
      }));
      isValid = false;
    }
    if (confirmPassword !== newPassword) {
      setErrors((prev) => ({
        ...prev,
        confirmPassword: "Password and Confirm Password must match",
      }));
      isValid = false;
    }
    if (isValid) {
      changePassword({ old_password: oldPassword, new_password: newPassword });
    }
  };

  return (
    <Form>
      <div className="mb-3">
        <Label className="form-label" htmlFor="password-input">
          <Trans i18nKey="Password" />
        </Label>
        <div className="position-relative auth-pass-inputgroup">
          <Input
            type={passwordShown.oldPassword ? "text" : "password"}
            className="form-control pe-5 password-input"
            id="oldPassword"
            placeholder={t("Password")}
            value={oldPassword}
            onChange={(e) => {
              setOldPassword(e.target.value);
              setErrors((prev) => ({ ...prev, oldPassword: "" }));
            }}
            invalid={!!errors.oldPassword}
          />
          {errors.confirmPassword ? (
            <FormFeedback type="invalid">
              <Trans i18nKey={errors.confirmPassword} />
            </FormFeedback>
          ) : null}
          <Button
            color="link"
            onClick={() =>
              setPasswordShown((prev) => ({
                ...prev,
                oldPassword: !prev.oldPassword,
              }))
            }
            className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
            type="button"
            id="password-addon"
          >
            <i className="ri-eye-fill align-middle"></i>
          </Button>
        </div>
      </div>
      <div className="mb-3">
        <Label for="newPassword">
          <Trans i18nKey="New Password" />
        </Label>
        <div className="position-relative auth-pass-inputgroup">
          <Input
            type={passwordShown.newPassword ? "text" : "password"}
            className="form-control pe-5 password-input"
            id="newPassword"
            placeholder={t("New Password")}
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
              setErrors((prev) => ({ ...prev, newPassword: "" }));
            }}
            invalid={!!errors.newPassword}
          />
          {errors.newPassword ? (
            <FormFeedback type="invalid">
              <Trans i18nKey={errors.newPassword} />
            </FormFeedback>
          ) : null}
          <Button
            color="link"
            onClick={() =>
              setPasswordShown((prev) => ({
                ...prev,
                newPassword: !prev.newPassword,
              }))
            }
            className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
            type="button"
            id="password-addon"
          >
            <i className="ri-eye-fill align-middle"></i>
          </Button>
        </div>
      </div>
      <div className="mb-3">
        <Label for="confirmPassword">
          <Trans i18nKey="Confirm Password" />
        </Label>
        <div className="position-relative auth-pass-inputgroup">
          <Input
            type={passwordShown.confirmPassword ? "text" : "password"}
            className="form-control pe-5 password-input"
            id="confirmPassword"
            placeholder={t("Confirm Password")}
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setErrors((prev) => ({ ...prev, confirmPassword: "" }));
            }}
            invalid={!!errors.confirmPassword}
          />
          {errors.confirmPassword ? (
            <FormFeedback type="invalid">
              <Trans i18nKey={errors.confirmPassword} />
            </FormFeedback>
          ) : null}
          <Button
            color="link"
            onClick={() =>
              setPasswordShown((prev) => ({
                ...prev,
                confirmPassword: !prev.confirmPassword,
              }))
            }
            className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
            type="button"
            id="password-addon"
          >
            <i className="ri-eye-fill align-middle"></i>
          </Button>
        </div>
      </div>

      <div className="mt-4 d-flex">
        <Button
          className="ms-auto"
          onClick={handleChangePassword}
          disabled={isButtonDisabled || changePasswordStatus === "loading"}
        >
          {changePasswordStatus === "loading" && (
            <>
              <Spinner size="sm" />{" "}
            </>
          )}
          <Trans i18nKey="Reset Password" />
        </Button>
      </div>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  changePasswordStatus: state.Dashboard.changePasswordStatus,
  changePasswordError: state.Dashboard.changePasswordError,
});
const mapDispatchToProps = (dispatch: any) => ({
  changePassword: (payload: PosPazarTypes.ChangePasswordPayload) =>
    dispatch(changePasswordThunk(payload)),
  resetPassword: () => dispatch(resetPasswordReducer()),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ChangePassword);
