import ChangePassword from "Components/Profile/ChangePassword";
import Profile from "Components/Profile/Profile";
import { useEffect, useState, useMemo } from "react";
import { Trans } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";

const UserProfile = () => {
  document.title = "Profile | PosPazar";
  const tabs = useMemo(() => ["profile", "password"], []);

  const location = useLocation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("profile");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");
    if (!tab || !tabs.includes(tab)) {
      navigate(`?tab=${tabs[0]}`, { replace: true });
    } else {
      setActiveTab(tab);
    }
  }, [location, tabs, navigate]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Card className="card-height-100">
            <CardBody>
              <Row>
                <Col md="3">
                  <div className="mt-4 text-uppercase fw-bold">
                    {/* <span className="text-muted">{"accountSettings"}</span> */}
                  </div>
                  <Nav className="flex-column" pills={true}>
                    {tabs.map((tab) => (
                      <NavItem key={`tab-${tab}`}>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(`?tab=${tab}`, { replace: true })
                          }
                          active={activeTab === tab}
                        >
                          <Trans
                            i18nKey={tab.charAt(0).toUpperCase() + tab.slice(1)}
                          />
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </Col>
                <Col md="9">
                  {activeTab === "profile" && <Profile />}
                  {activeTab === "password" && <ChangePassword />}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default UserProfile;
