import { trimWhitespace } from "helpers/data/format";
import {
  isEmail,
  isIBAN,
  isIdentityNumber,
  isPhoneNumber,
} from "helpers/data/validate";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { routerPath } from "Routes/allRoutes";
import { RootState } from "slices";
import { resetSubDealerState } from "slices/subDealer/subDealerReducer";
import {
  createtSubDealerThunk,
  deleteSubDealerThunk,
  getAllCities,
  getAllTownships,
  getSubDealerByIdThunk,
  updateSubDealerThunk,
} from "slices/thunks";

const SubDealerEdit = (props: PropsFromRedux) => {
  const { dealer_id } = useParams<{ dealer_id?: string }>();
  const isEdit = Boolean(dealer_id && !isNaN(Number(dealer_id)));
  const {
    cities,
    createSubDealer,
    deleteSubDealer,
    fetchCities,
    fetchTownships,
    getSubDealerById,
    resetState,
    subDealer,
    subDealerCreateError,
    subDealerCreateStatus,
    subDealerDeleteError,
    subDealerDeleteStatus,
    subDealerError,
    subDealerStatus,
    subDealerUpdateError,
    subDealerUpdateStatus,
    townships,
    updateSubDealer,
  } = props;

  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: "",
    authorized_person_full_name: "",
    authorized_person_identification_number: "",
    authorized_person_email_address: "",
    authorized_person_phone_number: "+90",
    tax_office: "",
    tax_number: "",
    address: "",
    city: 0,
    township: 0,
    iban: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    authorized_person_full_name: "",
    authorized_person_identification_number: "",
    authorized_person_email_address: "",
    authorized_person_phone_number: "",
    tax_office: "",
    tax_number: "",
    address: "",
    city: "",
    township: "",
    iban: "",
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);
  const handleDeleteSubDealer = () => {
    if (isEdit && dealer_id) {
      deleteSubDealer(Number(dealer_id));
    }
    toggleDeleteModal();
  };

  useEffect(() => {
    return () => {
      resetState();
      setFormData({
        name: "",
        authorized_person_full_name: "",
        authorized_person_identification_number: "",
        authorized_person_email_address: "",
        authorized_person_phone_number: "+90",
        tax_office: "",
        tax_number: "",
        address: "",
        city: 0,
        township: 0,
        iban: "",
      });
    };
  }, [resetState]);

  useEffect(() => {
    fetchCities();
    if (dealer_id) {
      getSubDealerById(Number(dealer_id));
    }
  }, [fetchCities, getSubDealerById, dealer_id]);

  useEffect(() => {
    if (subDealer) {
      setFormData({
        name: subDealer.name,
        authorized_person_full_name: subDealer.authorized_person_full_name,
        authorized_person_identification_number:
          subDealer.authorized_person_identification_number,
        authorized_person_email_address:
          subDealer.authorized_person_email_address,
        authorized_person_phone_number:
          subDealer.authorized_person_phone_number,
        tax_office: subDealer.tax_office ?? "",
        tax_number: subDealer.tax_number ?? "",
        address: subDealer.address,
        city: subDealer.city,
        township: subDealer.township,
        iban: subDealer.iban ?? "",
      });
      fetchTownships(subDealer.city);
    } else if (cities.length) {
      setFormData((prev) => ({ ...prev, city: cities[0].id }));
      fetchTownships(cities[0].id);
    }
  }, [subDealer, cities, fetchTownships]);

  useEffect(() => {
    if (formData.city > 0) {
      fetchTownships(formData.city);
    }
  }, [formData.city, fetchTownships]);

  useEffect(() => {
    if (townships.length) {
      if (subDealer?.city === formData.city) {
        setFormData((prev) => ({ ...prev, township: subDealer.township }));
      } else {
        setFormData((prev) => ({ ...prev, township: townships[0].id }));
      }
    }
  }, [townships, subDealer, formData.city]);

  useEffect(() => {
    switch (subDealerCreateStatus) {
      case "succeeded":
        const toastId = toast.success(t("Dealer created successfully"), {
          autoClose: 3000,
        });
        toast.onChange((payload) => {
          if (payload.id === toastId && payload.status === "removed") {
            window.location.href = routerPath.Dealer;
          }
        });
        break;
      case "failed":
        toast.error(t(subDealerCreateError ?? "Unknown error occurred"), {
          autoClose: 3000,
        });
        break;
      default:
        break;
    }
  }, [subDealerCreateStatus, subDealerCreateError, t]);

  useEffect(() => {
    switch (subDealerUpdateStatus) {
      case "succeeded":
        const toastId = toast.success(t("Dealer updated successfully"), {
          autoClose: 3000,
        });
        toast.onChange((payload) => {
          if (payload.id === toastId && payload.status === "removed") {
            window.location.href = routerPath.Dealer;
          }
        });
        break;
      case "failed":
        toast.error(t(subDealerUpdateError ?? "Unknown error occurred"), {
          autoClose: 3000,
        });
        break;
      default:
        break;
    }
  }, [subDealerUpdateStatus, subDealerUpdateError, t]);

  useEffect(() => {
    switch (subDealerStatus) {
      case "failed":
        const toastId = toast.error(
          t(subDealerError ?? "Unknown error occurred"),
          {
            autoClose: 3000,
          }
        );
        toast.onChange((payload) => {
          if (payload.id === toastId && payload.status === "removed") {
            window.location.href = routerPath.Dealer;
          }
        });
        break;
      default:
        break;
    }
  }, [subDealerStatus, subDealerError, t]);

  useEffect(() => {
    switch (subDealerDeleteStatus) {
      case "succeeded":
        const toastId = toast.success(t("Dealer deleted successfully"), {
          autoClose: 3000,
        });
        toast.onChange((payload) => {
          if (payload.id === toastId && payload.status === "removed") {
            window.location.href = routerPath.Dealer;
          }
        });
        break;
      case "failed":
        toast.error(t(subDealerDeleteError ?? "Unknown error occurred"), {
          autoClose: 3000,
        });
        break;
      default:
        break;
    }
  }, [subDealerDeleteStatus, subDealerDeleteError, t]);

  const handleSave = () => {
    if (!formIsValid()) {
      return;
    }
    if (isEdit) {
      updateSubDealer(Number(dealer_id), {
        name: formData.name,
        authorized_person_full_name: formData.authorized_person_full_name,
        authorized_person_identification_number:
          formData.authorized_person_identification_number,
        authorized_person_email_address:
          formData.authorized_person_email_address,
        authorized_person_phone_number: formData.authorized_person_phone_number,
        tax_office: formData.tax_office,
        tax_number: formData.tax_number,
        address: formData.address,
        city: formData.city,
        township: formData.township,
        iban: trimWhitespace(formData.iban),
      });
    } else {
      createSubDealer({
        name: formData.name,
        authorized_person_full_name: formData.authorized_person_full_name,
        authorized_person_identification_number:
          formData.authorized_person_identification_number,
        authorized_person_email_address:
          formData.authorized_person_email_address,
        authorized_person_phone_number: formData.authorized_person_phone_number,
        tax_office: formData.tax_office,
        tax_number: formData.tax_number,
        address: formData.address,
        city: formData.city,
        township: formData.township,
        iban: trimWhitespace(formData.iban),
      });
    }
  };

  const formIsValid = () => {
    let isValid = true;
    const newErrors = {
      name: "",
      authorized_person_full_name: "",
      authorized_person_identification_number: "",
      authorized_person_email_address: "",
      authorized_person_phone_number: "",
      tax_office: "",
      tax_number: "",
      address: "",
      city: "",
      township: "",
      iban: "",
    };

    if (!formData.name) {
      newErrors.name = t("Field is required");
      isValid = false;
    }

    if (!formData.authorized_person_full_name) {
      newErrors.authorized_person_full_name = t("Field is required");
      isValid = false;
    }

    const isIdentityNumberValid = isIdentityNumber(
      formData.authorized_person_identification_number
    );
    if (!isIdentityNumberValid.isValid) {
      newErrors.authorized_person_identification_number = t(
        isIdentityNumberValid.message
      );
      isValid = false;
    }

    const isEmailValid = isEmail(formData.authorized_person_email_address);
    if (!isEmailValid.isValid) {
      newErrors.authorized_person_email_address = t(isEmailValid.message);
      isValid = false;
    }

    const isPhoneNumberValid = isPhoneNumber(
      formData.authorized_person_phone_number
    );
    if (!isPhoneNumberValid.isValid) {
      newErrors.authorized_person_phone_number = t(isPhoneNumberValid.message);
      isValid = false;
    }

    if (!formData.tax_office) {
      newErrors.tax_office = t("Field is required");
      isValid = false;
    }

    if (!formData.tax_number) {
      newErrors.tax_number = t("Field is required");
      isValid = false;
    }

    if (!formData.address) {
      newErrors.address = t("Field is required");
      isValid = false;
    }

    if (!formData.city) {
      newErrors.city = t("Field is required");
      isValid = false;
    }

    if (!formData.township) {
      newErrors.township = t("Field is required");
      isValid = false;
    }

    const isIbanValid = isIBAN(formData.iban, true);
    if (!isIbanValid.isValid) {
      newErrors.iban = t(isIbanValid.message);
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardHeader>
              <Trans i18nKey="Sub Dealer" />
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label for="name">
                      <Trans i18nKey="Dealer Name" />
                    </Label>
                    <Input
                      type="text"
                      id="name"
                      value={formData.name}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          name: e.target.value,
                        });
                        setErrors({ ...errors, name: "" });
                      }}
                      invalid={!!errors.name}
                    />
                    {errors.name && (
                      <div className="text-danger">{errors.name}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="iban">IBAN</Label>
                    <Input
                      type="text"
                      id="iban"
                      value={formData.iban}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          iban: e.target.value,
                        });
                        setErrors({
                          ...errors,
                          iban: "",
                        });
                      }}
                      invalid={!!errors.iban}
                    />
                    {errors.iban && (
                      <div className="text-danger">{errors.iban}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="tax_office">
                      <Trans i18nKey="Tax Office" />
                    </Label>
                    <Input
                      type="text"
                      id="tax_office"
                      value={formData.tax_office}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          tax_office: e.target.value,
                        });
                        setErrors({
                          ...errors,
                          tax_office: "",
                        });
                      }}
                      invalid={!!errors.tax_office}
                    />
                    {errors.tax_office && (
                      <div className="text-danger">{errors.tax_office}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="tax_number">
                      <Trans i18nKey="Tax Number" />
                    </Label>
                    <Input
                      type="text"
                      id="tax_number"
                      value={formData.tax_number}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          tax_number: e.target.value,
                        });
                        setErrors({
                          ...errors,
                          tax_number: "",
                        });
                      }}
                      invalid={!!errors.tax_number}
                    />
                    {errors.tax_number && (
                      <div className="text-danger">{errors.tax_number}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="city">
                      <Trans i18nKey="City" />
                    </Label>
                    <Input
                      type="select"
                      id="city"
                      value={formData.city}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          city: Number(e.target.value),
                          township: 0,
                        });
                        setErrors({
                          ...errors,
                          city: "",
                        });
                      }}
                      invalid={!!errors.city}
                    >
                      <option value={0} hidden>
                        <Trans i18nKey="Select" />
                      </option>
                      {cities.map((city) => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                    </Input>
                    {errors.city && (
                      <div className="text-danger">{errors.city}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="township">
                      <Trans i18nKey="Township" />
                    </Label>
                    <Input
                      type="select"
                      id="township"
                      value={formData.township}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          township: Number(e.target.value),
                        });
                        setErrors({
                          ...errors,
                          township: "",
                        });
                      }}
                      invalid={!!errors.township}
                    >
                      <option value={0} hidden>
                        <Trans i18nKey="Select" />
                      </option>
                      {townships.map((township) => (
                        <option key={township.id} value={township.id}>
                          {township.name}
                        </option>
                      ))}
                    </Input>
                    {errors.township && (
                      <div className="text-danger">{errors.township}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="address">
                      <Trans i18nKey="Address" />
                    </Label>
                    <Input
                      type="textarea"
                      id="address"
                      value={formData.address}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          address: e.target.value,
                        });
                        setErrors({
                          ...errors,
                          address: "",
                        });
                      }}
                      invalid={!!errors.address}
                    />
                    {errors.address && (
                      <div className="text-danger">{errors.address}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6" className="d-flex align-items-center">
                  <div className="flex-column">
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="use_main_merchant_credentials"
                        checked={subDealer?.use_main_merchant_credentials}
                        disabled
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="use_main_merchant_credentials"
                      >
                        <Trans i18nKey="Use main dealer POS providers" />
                      </Label>
                    </div>
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="otp_required"
                        checked={subDealer?.status === "active"}
                        disabled
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="otp_required"
                      >
                        <Trans i18nKey="Active" />
                      </Label>
                    </div>
                  </div>
                </Col>
                <Col md="12">
                  <p className="text-muted fw-bolder mt-4 mb-0">
                    <Trans i18nKey="Authorized Person" />
                  </p>
                  <hr className="my-1" />
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="authorized_person_full_name">
                      <Trans i18nKey="Name" /> <Trans i18nKey="Surname" />
                    </Label>
                    <Input
                      type="text"
                      id="authorized_person_full_name"
                      value={formData.authorized_person_full_name}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          authorized_person_full_name: e.target.value,
                        });
                        setErrors({
                          ...errors,
                          authorized_person_full_name: "",
                        });
                      }}
                      invalid={!!errors.authorized_person_full_name}
                    />
                    {errors.authorized_person_full_name && (
                      <div className="text-danger">
                        {errors.authorized_person_full_name}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="authorized_person_identification_number">
                      <Trans i18nKey="Identity Number" />
                    </Label>
                    <Input
                      type="text"
                      id="authorized_person_identification_number"
                      value={formData.authorized_person_identification_number}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          authorized_person_identification_number:
                            e.target.value,
                        });
                        setErrors({
                          ...errors,
                          authorized_person_identification_number: "",
                        });
                      }}
                      invalid={!!errors.authorized_person_identification_number}
                    />
                    {errors.authorized_person_identification_number && (
                      <div className="text-danger">
                        {errors.authorized_person_identification_number}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="authorized_person_email_address">
                      <Trans i18nKey="Email" />
                    </Label>
                    <Input
                      type="text"
                      id="authorized_person_email_address"
                      value={formData.authorized_person_email_address}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          authorized_person_email_address: e.target.value,
                        });
                        setErrors({
                          ...errors,
                          authorized_person_email_address: "",
                        });
                      }}
                      invalid={!!errors.authorized_person_email_address}
                    />
                    {errors.authorized_person_email_address && (
                      <div className="text-danger">
                        {errors.authorized_person_email_address}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="authorized_person_phone_number">
                      <Trans i18nKey="Phone Number" />
                    </Label>
                    <Input
                      type="text"
                      id="authorized_person_phone_number"
                      value={formData.authorized_person_phone_number}
                      onChange={(e) => {
                        const { value } = e.target;
                        const formattedValue = value
                          .replace(/\D/g, "")
                          .slice(2);

                        if (formattedValue.length <= 10) {
                          setFormData({
                            ...formData,
                            authorized_person_phone_number:
                              "+90" + formattedValue,
                          });
                          setErrors({
                            ...errors,
                            authorized_person_phone_number: "",
                          });
                        }
                      }}
                      invalid={!!errors.authorized_person_phone_number}
                    />
                    {errors.authorized_person_phone_number && (
                      <div className="text-danger">
                        {errors.authorized_person_phone_number}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="text-end">
              <Button
                color="danger"
                className="me-2"
                onClick={toggleDeleteModal}
                hidden={!isEdit}
              >
                <Trans i18nKey="Delete" />
              </Button>
              <Button
                color="primary"
                disabled={isEdit && subDealerStatus !== "succeeded"}
                onClick={handleSave}
              >
                <Trans i18nKey="Save" />
              </Button>
            </CardFooter>
          </Card>
        </Container>
      </div>

      <Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal} centered>
        <ModalHeader toggle={toggleDeleteModal}>
          <Trans i18nKey="Confirm Deletion" />
        </ModalHeader>
        <ModalBody>
          <Trans i18nKey="Are you sure you want to continue with the deletion? This action cannot be undone." />
        </ModalBody>
        <ModalFooter>
          {isEdit && (
            <Button color="secondary" onClick={toggleDeleteModal}>
              <Trans i18nKey="Cancel" />
            </Button>
          )}
          <Button
            color="danger"
            onClick={handleDeleteSubDealer}
            disabled={subDealerDeleteStatus === "loading"}
          >
            <Trans i18nKey="Delete" />
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  subDealer: state.SubDealer.subDealer,
  subDealerStatus: state.SubDealer.subDealerStatus,
  subDealerError: state.SubDealer.subDealerError,

  subDealerCreateStatus: state.SubDealer.subDealerCreateStatus,
  subDealerCreateError: state.SubDealer.subDealerCreateError,
  subDealerUpdateStatus: state.SubDealer.subDealerUpdateStatus,
  subDealerUpdateError: state.SubDealer.subDealerUpdateError,
  subDealerDeleteStatus: state.SubDealer.subDealerDeleteStatus,
  subDealerDeleteError: state.SubDealer.subDealerDeleteError,

  cities: state.Core.cities,
  townships: state.Core.townships,
});
const mapDispatchToProps = (dispatch: any) => ({
  getSubDealerById: (subDealerId: number) =>
    dispatch(getSubDealerByIdThunk(subDealerId)),
  createSubDealer: (payload: PosPazarTypes.SubDealerCreatePayload) =>
    dispatch(createtSubDealerThunk(payload)),
  updateSubDealer: (
    subDealerId: number,
    payload: PosPazarTypes.SubDealerCreatePayload
  ) => dispatch(updateSubDealerThunk({ subDealerId, payload })),
  deleteSubDealer: (subDealerId: number) =>
    dispatch(deleteSubDealerThunk(subDealerId)),
  fetchCities: () => dispatch(getAllCities()),
  fetchTownships: (cityId: number) => dispatch(getAllTownships(cityId)),
  resetState: () => dispatch(resetSubDealerState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SubDealerEdit);
