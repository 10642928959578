import { createSlice } from "@reduxjs/toolkit";
import {
  cancelTransactionThunk,
  getTransaction,
  getTransactions,
  refundTransactionThunk,
} from "./transactionThunk";

type StateType = {
  transactions: PosPazarTypes.TransactionListItem[];
  nextPageUrl: string | null;
  prevPageUrl: string | null;
  count: number;
  pageNumber: number;
  transaction: PosPazarTypes.TransactionDetail | null;
  transactionStatus: PosPazarTypes.ApiRequestStatus;
  transactionError: string | null;
  transactionCancelStatus: PosPazarTypes.ApiRequestStatus;
  transactionCancelError: string | null;
  transactionRefundStatus: PosPazarTypes.ApiRequestStatus;
  transactionRefundError: string | null;
  status: PosPazarTypes.ApiRequestStatus;
  error: string | null;
};

const initialState: StateType = {
  transactions: [],
  nextPageUrl: null,
  prevPageUrl: null,
  count: 0,
  pageNumber: 1,
  status: "idle",
  error: null,
  transaction: null,
  transactionStatus: "idle",
  transactionError: null,
  transactionCancelStatus: "idle",
  transactionCancelError: null,
  transactionRefundStatus: "idle",
  transactionRefundError: null,
};

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    resetTransactionReducer: (state: StateType) => {
      state.transaction = null;
      state.status = "idle";
      state.error = null;
      state.transactionStatus = "idle";
      state.transactionError = null;
      state.transactionCancelStatus = "idle";
      state.transactionCancelError = null;
      state.transactionRefundStatus = "idle";
      state.transactionRefundError = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getTransactions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTransactions.rejected, (state, action) => {
        state.error = action.error.message ?? null;
        state.status = "failed";
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        const { count, next, previous, results } = action.payload;
        state.transactions = results;
        state.nextPageUrl = next;
        state.prevPageUrl = previous;
        state.count = count;
        let previousPageNumber = 0;
        try {
          const previousPageUrl = new URL(previous ?? "");
          const previousPage = previousPageUrl.searchParams.get("page");
          if (previousPage) {
            previousPageNumber = Number(previousPage);
          } else {
            previousPageNumber = 1;
          }
        } catch {
          previousPageNumber = 0;
        }
        state.pageNumber = previousPageNumber + 1;
        state.status = "succeeded";
      });
    builder
      .addCase(getTransaction.fulfilled, (state, action) => {
        state.transaction = action.payload;
        state.transactionStatus = "succeeded";
      })
      .addCase(getTransaction.rejected, (state, action) => {
        state.transactionError = action.payload?.message ?? null;
        state.transactionStatus = "failed";
      })
      .addCase(getTransaction.pending, (state) => {
        state.transactionStatus = "loading";
        state.transaction = null;
        state.transactionError = null;
      });
    builder
      .addCase(cancelTransactionThunk.pending, (state) => {
        state.transactionCancelStatus = "loading";
      })
      .addCase(cancelTransactionThunk.rejected, (state, action) => {
        state.transactionCancelError = action.payload?.message ?? null;
        state.transactionCancelStatus = "failed";
      })
      .addCase(cancelTransactionThunk.fulfilled, (state) => {
        state.transactionCancelStatus = "succeeded";
      });
    builder
      .addCase(refundTransactionThunk.pending, (state) => {
        state.transactionRefundStatus = "loading";
      })
      .addCase(refundTransactionThunk.rejected, (state, action) => {
        state.transactionRefundError = action.payload?.message ?? null;
        state.transactionRefundStatus = "failed";
      })
      .addCase(refundTransactionThunk.fulfilled, (state) => {
        state.transactionRefundStatus = "succeeded";
      });
  },
});

export const { resetTransactionReducer } = transactionSlice.actions;
export default transactionSlice.reducer;
