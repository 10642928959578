import { getProviderLogo } from "common/providerLogo";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Container,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { RootState } from "slices";
import { ratesThunk } from "slices/thunks";

const Rates: React.FC<PropsFromRedux> = (props) => {
  const { rates, ratesStatus, ratesError, getRates } = props;
  const [includeRate, setIncludeRate] = useState(false);

  useEffect(() => {
    getRates();
  }, [getRates]);

  const ratesMap = useMemo(() => {
    if (!rates) return [];
    const providerKeys = Object.keys(rates);
    return providerKeys.map((key) => ({
      provider: key,
      installmentsRates: rates[key],
    }));
  }, [rates]);

  const [amount, setAmount] = useState(0);
  const { t } = useTranslation();

  const calculateAmount = useCallback(
    (rate: number, numberOfInstallment: number) => {
      if (includeRate && numberOfInstallment > 1) {
        return {
          installment_amount:
            (amount + amount * (rate / 100)) / numberOfInstallment,
          total_amount: amount,
        };
      }
      return {
        installment_amount: amount / numberOfInstallment,
        total_amount: amount - amount * (rate / 100),
      };
    },
    [amount, includeRate]
  );

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value) || 0;
    setAmount(value);
  };
  return (
    <div className="page-content">
      <Container fluid>
        <Card className="card-height-100">
          <CardHeader>{t("Rates")}</CardHeader>
          <CardBody>
            <div className="text-center">
              <Label for="amountInput" className="h5">
                <Trans i18nKey="Amount to be calculated" />:
              </Label>
              <Input
                id="amountInput"
                type="number"
                value={amount}
                onChange={handleAmountChange}
                placeholder={t("Enter amount")}
                className="text-center"
                style={{ maxWidth: "200px", margin: "0 auto" }}
              />
            </div>
            <div className="text-center mb-4">
              <Input
                type="checkbox"
                id="includeRateCheckbox"
                value={includeRate ? "1" : "0"}
                onChange={(e) => setIncludeRate(e.target.checked)}
              />
              <Label for="includeRateCheckbox" className="ms-2">
                <Trans i18nKey="Include rate in amount" />
              </Label>
            </div>
            <Row className="justify-content-center">
              {ratesStatus === "loading" && <Spinner color="primary" />}
              {ratesStatus === "failed" && (
                <Col xs="12" className="mt-2">
                  <Alert color="danger">
                    {ratesError ? (
                      ratesError
                    ) : (
                      <Trans i18nKey="Unknown error occurred" />
                    )}
                  </Alert>
                </Col>
              )}
              {ratesMap.map((providers, index) => (
                <Col
                  xs="6"
                  sm="6"
                  md="4"
                  lg="3"
                  xl="3"
                  key={index}
                  className="mb-4"
                >
                  <Card className="text-center shadow-sm border-0 rounded">
                    <div className="bg-light text-white fw-bold p-2">
                      <img
                        src={getProviderLogo(providers.provider)}
                        alt={providers.provider}
                        height="35"
                        className="ms-2"
                      />
                    </div>
                    <CardBody className="p-0">
                      {providers.installmentsRates.map((rate, i) => {
                        const { installment_amount, total_amount } =
                          calculateAmount(rate, i + 1);
                        return (
                          <div
                            key={i}
                            className="py-3 hover-bg"
                            style={{
                              borderBottom:
                                i !== providers.installmentsRates.length - 1
                                  ? "1px solid #ddd"
                                  : "none",
                              transition: "background-color 0.3s",
                            }}
                          >
                            <CardText>
                              <strong>
                                {i + 1 === 1
                                  ? t("Single Payment")
                                  : `${i + 1} ${t("Installment")}`}
                              </strong>{" "}
                              <br />%{rate.toFixed(2)} <br />
                              {i + 1}×{installment_amount.toFixed(2)} TL <br />
                              <small className="text-muted">
                                <Trans i18nKey="Amount to be received" />:{" "}
                                {total_amount.toFixed(2)} TL
                              </small>
                            </CardText>
                          </div>
                        );
                      })}
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  rates: state.PosProvider.rates,
  ratesStatus: state.PosProvider.ratesStatus,
  ratesError: state.PosProvider.ratesError,
});
const mapDispatchToProps = (dispact: any) => ({
  getRates: () => dispact(ratesThunk()),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Rates);
