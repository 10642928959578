import tosla from "../assets/images/posproviders/tosla.svg";
import lidio from "../assets/images/posproviders/lidio.svg";
import elekse from "../assets/images/posproviders/elekse.png";
import isyerimpos from "../assets/images/posproviders/isyerimpos.png";
import paybull from "../assets/images/posproviders/paybull.png";
import moka from "../assets/images/posproviders/moka.png";
import sipay from "../assets/images/posproviders/sipay.png";

export function getProviderLogo(provider: string) {
  switch (provider) {
    case "tosla":
      return tosla;
    case "lidio":
      return lidio;
    case "elekse":
      return elekse;
    case "isyerimpos":
      return isyerimpos;
    case "paybull":
      return paybull;
    case "moka":
      return moka;
    case "sipay":
      return sipay;
    default:
      return "";
  }
}
