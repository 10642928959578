import PropTypes from "prop-types";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";
import { connect, ConnectedProps } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import logoLight from "../../assets/images/pospazar.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { RootState } from "slices";
import { forgotPasswordThunk } from "slices/thunks";
import { Trans, useTranslation } from "react-i18next";
import { useEffect } from "react";
import { routerPath } from "Routes/allRoutes";

const ForgetPasswordPage = (props: PropsFromRedux) => {
  const {
    forgotPasswordStatus,
    forgotPasswordErrorMessage,
    forgotPasswordToken,
    forgotPassword,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(t("Email is required")),
    }),
    onSubmit: (values) => {
      forgotPassword(values.email);
    },
  });

  document.title = "Şifremi Unuttum | PosPazar";

  useEffect(() => {
    if (forgotPasswordToken && forgotPasswordStatus === "succeeded") {
      navigate(routerPath.ResetPassword + `?token=${forgotPasswordToken}`);
    }
  }, [forgotPasswordToken, forgotPasswordStatus, navigate]);
  return (
    <ParticlesAuth>
      <div className="auth-page-content mt-lg-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" height="70" />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">
                      <Trans i18nKey="Forgot your password?" />
                    </h5>

                    <i className="ri-mail-send-line display-5 text-success mb-3"></i>
                  </div>
                  <div className="p-2">
                    {forgotPasswordStatus === "failed" && (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgotPasswordErrorMessage
                          ? forgotPasswordErrorMessage
                          : t("Unknown error occurred")}
                      </Alert>
                    )}
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-4">
                        <Label className="form-label">
                          <Trans i18nKey="Email" />
                        </Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder={t("Email")}
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.email}</div>
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="text-center mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          <Trans i18nKey="Send Reset Link" />
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

const mapStateToProps = (state: RootState) => ({
  forgotPasswordToken: state.Auth.forgotPasswordToken,
  forgotPasswordStatus: state.Auth.forgotPasswordStatus,
  forgotPasswordErrorMessage: state.Auth.forgotPasswordErrorMessage,
});

const mapDispatchToProps = (dispatch: any) => ({
  forgotPassword: (email: string) => {
    dispatch(forgotPasswordThunk(email));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default connector(withRouter(ForgetPasswordPage));
