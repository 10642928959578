import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPosProviders,
  getRates,
  post3DPayment,
} from "helpers/api/inner_urls";

export const getAllProviders = createAsyncThunk(
  "posprovider/getAllProviders",
  async () => {
    const { data } = await getPosProviders();
    return data;
  }
);

export const new3dPayment = createAsyncThunk<
  void,
  {
    provider_key: string;
    body: PosPazarTypes.ThreeDPayload;
  },
  { rejectValue: PosPazarTypes.ApiRequestError }
>("posprovider/new3dPayment", async (payload, { rejectWithValue }) => {
  try {
    const { provider_key, body } = payload;
    const { data } = await post3DPayment(provider_key, body);
    if (data.content_type === "html") {
      setTimeout(() => {
        window.document.open();
        window.document.write(String(data.content));
        window.document.close();
      }, 3000);
    } else {
      window.location.href = data.content;
    }
  } catch (error: any) {
    console.log("error: ", error?.response);
    if (error?.response?.data?.message) {
      return rejectWithValue({
        message: error.response.data.message,
        status: error.response.status,
        data: error.response.data,
      });
    }
    return rejectWithValue({
      message: error.message,
      status: error.response.status,
    });
  }
});

export const ratesThunk = createAsyncThunk<
  PosPazarTypes.RatesResponse,
  void,
  { rejectValue: PosPazarTypes.ApiRequestError }
>("posprovider/getRates", async (_, { rejectWithValue }) => {
  try {
    const { data } = await getRates();
    return data;
  } catch (error: any) {
    if (error?.response?.data?.message) {
      return rejectWithValue({
        message: error.response.data.message,
        status: error.response.status,
        data: error.response.data,
      });
    }
    return rejectWithValue({
      message: error.message,
      status: error.response.status,
    });
  }
});
