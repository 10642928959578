import { useEffect, useMemo, useState } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import { connect, ConnectedProps } from "react-redux";
import { getTransactions } from "slices/thunks";
import { Trans, useTranslation, withTranslation } from "react-i18next";
import CustomTable from "Components/CustomTable";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { routerPath } from "Routes/allRoutes";
import { mapTransactionType } from "helpers/data/mapping";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";

const Transaction = (props: PropsFromRedux) => {
  const { transactions, pageNumber, error, fetchTransactions } = props;
  const { t, i18n } = useTranslation();

  const [chooseLang, setChooseLang] = useState<string>("tr");
  const [filter, setFilter] = useState<{
    created_at: string;
    status: string;
    type: string;
  }>({
    created_at: "",
    status: "",
    type: "",
  });

  useEffect(() => {
    fetchTransactions({});
  }, [fetchTransactions]);

  const columns = useMemo<Array<PosPazarTypes.CustomTableColumn>>(
    () => [
      {
        header: t("Type"),
        accessor: "related_object",
        cell: (row: PosPazarTypes.TransactionListItem) => {
          return t(mapTransactionType(row.transaction_type) ?? "");
        },
      },
      {
        header: t("Status"),
        accessor: "status",
        cell: (row: PosPazarTypes.TransactionListItem) => {
          const status = row.status;
          let color = "";
          switch (status) {
            case "approved":
              color = "success";
              break;
            case "pending":
              color = "warning";
              break;
            case "rejected":
              color = "dark";
              break;
            case "cancelled":
              color = "danger";
              break;
            default:
              color = "secondary";
              break;
          }
          return (
            <Badge color={color} pill={true}>
              <Trans i18nKey={status} />
            </Badge>
          );
        },
      },
      {
        header: t("Amount"),
        accessor: "amount",
        cell: (row: PosPazarTypes.TransactionListItem) => {
          return row.amount + " ₺";
        },
      },
      {
        header: t("Total Amount"),
        accessor: "total_amount",
        cell: (row: PosPazarTypes.TransactionListItem) => {
          return row.total_amount + " ₺";
        },
      },
      {
        header: t("Installment"),
        accessor: "installment",
      },
      {
        header: t("Rate"),
        accessor: "rate",
      },
      {
        header: t("Created At"),
        accessor: "created_at",
        cell: (row: PosPazarTypes.TransactionListItem) => {
          const date = new Date(row.created_at);
          return date.toLocaleDateString("tr-TR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          });
        },
      },
      {
        header: "",
        accessor: "actions",
        cell: (row: PosPazarTypes.TransactionListItem) => {
          return (
            <Link to={routerPath.TransactionDetail.replace(":uniq", row.uniq)}>
              <Trans i18nKey="Details" />
            </Link>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  useEffect(() => {
    if (error) {
      toast.error(error, { autoClose: 5000 });
    }
  }, [error]);

  useEffect(() => {
    const handleLanguageChange = (lng: string) => {
      setChooseLang(lng === "en" ? "en" : "tr");
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);

  function handlePagination(pageNumber?: number) {
    const queries = { ...filter, page: pageNumber };
    fetchTransactions(queries);
  }

  function handleFilter() {
    fetchTransactions(filter);
  }

  function clearFilter() {
    setFilter({ created_at: "", status: "", type: "" });
    fetchTransactions({});
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody className="pt-0 px-0">
              <Row className="bg-light p-3 mx-0">
                <Col xs={3}>
                  <div>
                    <Label>{t("Type")}</Label>
                    <Input
                      type="select"
                      className="form-select"
                      placeholder={t("Type")}
                      value={filter.type}
                      onChange={(e) => {
                        setFilter({ ...filter, type: e.target.value });
                      }}
                    >
                      <option value="">-</option>
                      <option value="manuelpayment">
                        {t("Manuel Payment")}
                      </option>
                      <option value="paymentlinkpayment">
                        {t("Payment Link")}
                      </option>
                      <option value="refund">{t("Refund")}</option>
                    </Input>
                  </div>
                </Col>
                <Col xs={3}>
                  <div>
                    <Label>{t("Status")}</Label>
                    <Input
                      type="select"
                      className="form-select"
                      placeholder={t("Status")}
                      value={filter.status}
                      onChange={(e) => {
                        setFilter({ ...filter, status: e.target.value });
                      }}
                    >
                      <option value="">-</option>
                      <option value="approved">{t("approved")}</option>
                      <option value="pending">{t("pending")}</option>
                      <option value="rejected">{t("rejected")}</option>
                      <option value="cancelled">{t("cancelled")}</option>
                    </Input>
                  </div>
                </Col>
                <Col xs={4}>
                  <div>
                    <Label>{t("Created At")}</Label>
                    <Flatpickr
                      className="form-control"
                      options={{
                        mode: "range",
                        dateFormat: "Y-m-d",
                        locale: chooseLang === "tr" ? Turkish : undefined,
                      }}
                      value={
                        filter.created_at
                          ? filter.created_at
                              .split("_")
                              .map((date) => new Date(date))
                          : undefined
                      }
                      onChange={(selectedDates) => {
                        if (selectedDates.length === 2) {
                          const startDate =
                            selectedDates[0].toLocaleDateString("en-CA");
                          const endDate =
                            selectedDates[1].toLocaleDateString("en-CA");
                          setFilter({
                            ...filter,
                            created_at: `${startDate}_${endDate}`,
                          });
                        } else {
                          setFilter({ ...filter, created_at: "" });
                        }
                      }}
                      placeholder={t("Created At")}
                    />
                  </div>
                </Col>
                <Col
                  xs={2}
                  className="d-flex align-items-end justify-content-end"
                >
                  <ButtonGroup>
                    <Button
                      color="primary"
                      className="btn-icon"
                      onClick={handleFilter}
                    >
                      <i className="mdi mdi-filter"></i>
                    </Button>
                    <Button
                      color="primary"
                      className="btn-icon"
                      onClick={clearFilter}
                    >
                      <i className="mdi mdi-filter-remove"></i>
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <div className="p-2">
                <CustomTable
                  columns={columns}
                  data={transactions}
                  onPageChange={(pn) => {
                    if (pn !== pageNumber) {
                      handlePagination(pn);
                    }
                  }}
                  {...props}
                />
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  transactions: state.Transaction.transactions,
  totalData: state.Transaction.count,
  pageNumber: state.Transaction.pageNumber,
  error: state.Transaction.error,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchTransactions: (queries: any) => dispatch(getTransactions(queries)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withTranslation()(Transaction));
