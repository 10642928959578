import { getProviderLogo } from "common/providerLogo";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const CustomOption = (props: any) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div className="provider-option" ref={innerRef} {...innerProps}>
      <img src={data.logo} alt={data.label} />
    </div>
  );
};

const CustomSingleValue = (props: any) => {
  const { data } = props;
  return (
    <div className="provider-toggle">
      <img src={data.logo} alt={data.label} />
    </div>
  );
};

interface ProviderSelectProps {
  providers: PosPazarTypes.PosProvider[];
  onChange: (selected: string) => void;
  selected?: string;
}
const ProviderSelect: React.FC<ProviderSelectProps> = (props) => {
  const { providers, onChange, selected } = props;
  const { t } = useTranslation();
  const [currentSelected, setCurrentSelected] = useState("");

  const options = useMemo(() => {
    const _providers = [...providers]
      .sort((a, b) => a.order - b.order)
      .map((provider) => ({
        value: provider.key,
        label: provider.name,
        logo: getProviderLogo(provider.key),
      }));
    return _providers;
  }, [providers]);

  useEffect(() => {
    if (providers && providers.length > 0) {
      if (selected) {
        const found = providers.find((provider) => provider.key === selected);
        handleChange(found?.key || providers[0].key);
      } else {
        handleChange(providers[0].key);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providers, selected]);

  const handleChange = (val: string) => {
    setCurrentSelected(val);
    onChange(val);
  };

  return (
    <Select
      className="provider-select"
      options={options}
      isSearchable={false}
      components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
      onChange={(item: any) => handleChange(item?.value)}
      value={options.find((option) => option.value === currentSelected)}
      placeholder={t("Select")}
      // menuIsOpen={true}
    />
  );
};

export default ProviderSelect;
