import React from "react";
import { Trans } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Form, Input, Label } from "reactstrap";
import { RootState } from "slices";

const Profile: React.FC<PropsFromRedux> = (props) => {
  const { userInfo } = props;
  return (
    <Form>
      <div className="mb-3">
        <Label htmlFor="first_name">
          <Trans i18nKey="Name" />
        </Label>
        <Input
          type="text"
          id="first_name"
          name="first_name"
          value={userInfo?.first_name}
          readOnly={true}
        />
      </div>
      <div className="mb-3">
        <Label htmlFor="last_name">
          <Trans i18nKey="Surname" />
        </Label>
        <Input
          type="text"
          id="last_name"
          name="last_name"
          value={userInfo?.last_name}
          readOnly={true}
        />
      </div>
      <div className="mb-3">
        <Label htmlFor="username">
          <Trans i18nKey="User Name" />
        </Label>
        <Input
          type="text"
          id="username"
          name="username"
          value={userInfo?.username}
          readOnly={true}
        />
      </div>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  userInfo: state.Dashboard.userInfo,
});
const mapDispatchToProps = {};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Profile);
