import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import { loginOtpThunk, loginVerifyOtpThunk } from "../../slices/thunks";
import logoLight from "../../assets/images/pospazar.png";
import { RootState } from "slices";
import { routerPath } from "Routes/allRoutes";
import { Trans, useTranslation } from "react-i18next";
import TwoFactorModal from "Components/TwoFactorModal";
import { resetOtp } from "slices/auth/authReducer";

const Login = (props: PropsFromRedux) => {
  const {
    loginStatus,
    loginErrorMessage,
    loginHasOtp,
    otpToken,
    verifyOtpErrorMessage,
    loginOtp,
    loginVerifyOtp,
    resetOtpValues,
    router,
  } = props;
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const { t } = useTranslation();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required(t("Please Enter Your Email")),
      password: Yup.string().required(t("Please Enter Your Password")),
    }),
    onSubmit: (values) => {
      loginOtp(values);
    },
  });

  useEffect(() => {
    switch (loginStatus) {
      case "loading":
        break;
      case "succeeded":
        router.navigate(routerPath.Dashboard);
        break;
      case "failed":
        break;
      default:
        break;
    }
  }, [loginStatus, router]);

  function handleOtpSubmit(code: string) {
    loginVerifyOtp(otpToken, code);
  }

  document.title = "Giriş | PosPazar";
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content mt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="70" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">
                        <Trans i18nKey="Welcome Back" /> !
                      </h5>
                    </div>
                    {loginErrorMessage ? (
                      <Alert color="danger"> {loginErrorMessage} </Alert>
                    ) : null}
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-3">
                          <Label htmlFor="username" className="form-label">
                            <Trans i18nKey="User Name" />
                          </Label>
                          <Input
                            name="username"
                            className="form-control"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.username || ""}
                            invalid={
                              validation.touched.username &&
                              validation.errors.username
                                ? true
                                : false
                            }
                          />
                          {validation.touched.username &&
                          validation.errors.username ? (
                            <FormFeedback type="invalid">
                              {validation.errors.username}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <div className="float-end">
                            <Link to="/forgot-password" className="text-muted">
                              <Trans i18nKey="Forgot password" />
                            </Link>
                          </div>
                          <Label
                            className="form-label"
                            htmlFor="password-input"
                          >
                            <Trans i18nKey="Password" />
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={passwordShow ? "text" : "password"}
                              className="form-control pe-5"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon"
                              onClick={() => setPasswordShow(!passwordShow)}
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </button>
                          </div>
                        </div>

                        <div className="mt-4">
                          <Button
                            color="success"
                            disabled={loginStatus === "loading"}
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            {loginStatus === "loading" && (
                              <Spinner size="sm" className="me-2" />
                            )}
                            <Trans i18nKey="Sign In" />
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

                <div className="mt-4 text-center">
                  <p className="mb-0">
                    <Trans i18nKey="Don't have an account" />?{" "}
                    <Link
                      to="/registration-application"
                      className="fw-semibold text-primary text-decoration-underline"
                    >
                      {" "}
                      <Trans i18nKey="Signup" />{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
      <TwoFactorModal
        isOpen={loginHasOtp === "yes"}
        setIsOpen={(val) => {
          val === false && resetOtpValues();
        }}
        submit={handleOtpSubmit}
        timeout={180}
        error={verifyOtpErrorMessage}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootState) => ({
  loginStatus: state.Auth.loginStatus,
  loginErrorMessage: state.Auth.loginErrorMessage,
  loginHasOtp: state.Auth.loginHasOtp,
  otpToken: state.Auth.otpToken,
  verifyOtpErrorMessage: state.Auth.verifyOtpErrorMessage,
});

const mapDispatchToProps = (dispatch: any) => ({
  loginOtp: (values: PosPazarTypes.LoginPaylod) =>
    dispatch(loginOtpThunk(values)),
  loginVerifyOtp: (token: string, otp_code: string) =>
    dispatch(loginVerifyOtpThunk({ token, otp_code })),
  resetOtpValues: () => dispatch(resetOtp()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector> & {
  router: {
    navigate: (path: string) => void;
  };
};

export default connector(withRouter(Login));
