import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import logoLight from "../../assets/images/pospazar.png";
import ParticlesAuth from "pages/AuthenticationInner/ParticlesAuth";
import { useNavigate } from "react-router-dom";
import { RootState } from "slices";
import { resetPasswordThunk } from "slices/thunks";
import { connect, ConnectedProps } from "react-redux";
import TwoFactorModal from "Components/TwoFactorModal";
import { Trans, useTranslation } from "react-i18next";
import { routerPath } from "Routes/allRoutes";

const ResetPassword = (props: PropsFromRedux) => {
  const { resetPassword, resetPasswordErrorMessage, resetPasswordStatus } =
    props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  document.title = "Şifreyi Sıfırla | PosPazar";

  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const [confrimPasswordShow, setConfrimPasswordShow] =
    useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [timeout, setTimeout] = useState<number>(180);

  const [formData, setFormData] = useState({
    password: "",
    confirm_password: "",
    token: "",
  });
  const [errors, setErrors] = useState({
    password: "",
    confirm_password: "",
  });

  useEffect(() => {
    let timer: NodeJS.Timeout;
    timer = setInterval(() => {
      setTimeout((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => {
      if (timer !== undefined) clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tokenParam = queryParams.get("token");

    if (tokenParam) {
      setFormData((prev) => ({ ...prev, token: tokenParam }));
    } else {
      navigate(routerPath.ForgotPassword);
    }
  }, [navigate]);

  useEffect(() => {
    switch (resetPasswordStatus) {
      case "succeeded":
        navigate(routerPath.Login);
        break;
      default:
        break;
    }
  }, [resetPasswordStatus, navigate]);

  function validateForm() {
    let isValid = true;
    if (!formData.password) {
      setErrors((prev) => ({
        ...prev,
        password: "Password is required",
      }));
      isValid = false;
    } else if (formData.password.length < 6) {
      setErrors((prev) => ({
        ...prev,
        password: "Password must be at least 6 characters",
      }));
      isValid = false;
    }

    if (formData.password !== formData.confirm_password) {
      setErrors((prev) => ({
        ...prev,
        confirm_password: "Password and Confirm Password must match",
      }));
      isValid = false;
    }

    if (isValid) {
      setModalIsOpen(true);
    }
  }

  function submitOtp(code: string) {
    resetPassword({
      token: formData.token,
      otp_code: code,
      new_password: formData.password,
    });
  }

  return (
    <>
      <ParticlesAuth>
        <div className="auth-page-content mt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/#" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="70" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">
                        <Trans i18nKey="Create new password" />
                      </h5>
                    </div>

                    <div className="p-2">
                      <Form>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="password-input"
                          >
                            <Trans i18nKey="Password" />
                          </Label>
                          <div className="position-relative auth-pass-inputgroup">
                            <Input
                              type={passwordShow ? "text" : "password"}
                              className="form-control pe-5 password-input"
                              placeholder={t("Password")}
                              id="password-input"
                              name="password"
                              value={formData.password}
                              onChange={(e) => {
                                setFormData((prev) => ({
                                  ...prev,
                                  password: e.target.value,
                                }));
                                setErrors((prev) => ({
                                  ...prev,
                                  password: "",
                                }));
                              }}
                              invalid={errors.password ? true : false}
                            />
                            {errors.password ? (
                              <FormFeedback type="invalid">
                                <Trans i18nKey={errors.password} />
                              </FormFeedback>
                            ) : null}
                            <Button
                              color="link"
                              onClick={() => setPasswordShow(!passwordShow)}
                              className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                              type="button"
                              id="password-addon"
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </Button>
                          </div>
                        </div>

                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="confirm-password-input"
                          >
                            <Trans i18nKey="Confirm Password" />
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              type={confrimPasswordShow ? "text" : "password"}
                              className="form-control pe-5 password-input"
                              placeholder={t("Confirm Password")}
                              id="confirm-password-input"
                              name="confirm_password"
                              value={formData.confirm_password}
                              onChange={(e) => {
                                setFormData((prev) => ({
                                  ...prev,
                                  confirm_password: e.target.value,
                                }));
                                setErrors((prev) => ({
                                  ...prev,
                                  confirm_password: "",
                                }));
                              }}
                              invalid={errors.confirm_password ? true : false}
                            />
                            {errors.confirm_password ? (
                              <FormFeedback type="invalid">
                                <Trans i18nKey={errors.confirm_password} />
                              </FormFeedback>
                            ) : null}
                            <Button
                              color="link"
                              onClick={() =>
                                setConfrimPasswordShow(!confrimPasswordShow)
                              }
                              className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                              type="button"
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </Button>
                          </div>
                        </div>

                        <div className="mt-4">
                          <Button
                            color="success"
                            className="w-100"
                            onClick={validateForm}
                          >
                            <Trans i18nKey="Reset Password" />
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
      <TwoFactorModal
        isOpen={modalIsOpen}
        setIsOpen={(val) => {
          setModalIsOpen(val);
        }}
        submit={(val) => {
          submitOtp(val);
        }}
        timeout={timeout}
        error={resetPasswordErrorMessage}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  resetPasswordStatus: state.Auth.resetPasswordStatus,
  resetPasswordErrorMessage: state.Auth.resetPasswordErrorMessage,
});

const mapDispatchToProps = (dispatch: any) => ({
  resetPassword: (body: PosPazarTypes.ResetPasswordPayload) => {
    dispatch(resetPasswordThunk(body));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ResetPassword);
