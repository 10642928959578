import React from "react";

const LightDark = () => {
  function changeMode() {
    let _mode = localStorage.getItem("theme") === "dark" ? "light" : "dark";
    localStorage.setItem("theme", _mode);
    document.documentElement.setAttribute("data-bs-theme", _mode);
  }

  return (
    <div className="ms-1 header-item d-none d-sm-flex">
      <button
        onClick={changeMode}
        type="button"
        className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode"
      >
        <i className="bx bx-moon fs-22"></i>
      </button>
    </div>
  );
};

export default LightDark;
