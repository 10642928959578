export const convertISOToDateTimeLocal = (isoString: string) => {
  if (!isoString) {
    return "";
  }
  const date = new Date(isoString);

  if (!date || isNaN(date.getTime())) {
    return "";
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const convertDateTimeLocalToISO = (localString: string) => {
  try {
    const date = new Date(localString);

    if (isNaN(date.getTime())) {
      return "";
    }

    return date.toISOString();
  } catch (error) {
    return "";
  }
};
