import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import DashboardEcommerce from "../pages/Dashboard";

//AuthenticationInner pages
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";
import Cover404 from "../pages/AuthenticationInner/Errors/Cover404";
import Alt404 from "../pages/AuthenticationInner/Errors/Alt404";
import Error500 from "../pages/AuthenticationInner/Errors/Error500";
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";
import PaymentLink from "pages/Payment/PaymentLink";
import Transaction from "pages/Transaction/Transaction";
import Dealer from "pages/Dealer/DealerIndex";
import DealerUserList from "pages/Dealer/DealerUser/DealerUserList";
import ManuelPayment from "pages/Payment/ManuelPayment";
import PaymentLinksList from "pages/Payment/PaymentLinksList";
import PaymentRequest from "pages/Payment/PaymentRequest";
import RegistrationApplication from "pages/Authentication/RegistrationApplication";
import PaymentSuccess from "pages/AuthenticationInner/Payments/PaymentSuccess";
import PaymentFailed from "pages/AuthenticationInner/Payments/PaymentFailed";
import Rates from "pages/Rates/Rates";
import ResetPassword from "pages/Authentication/ResetPassword";
import DealerUserEdit from "pages/Dealer/DealerUser/DealerUserEdit";
import SubDealerEdit from "pages/Dealer/DealerIndex/SubDealerEdit";
import TransactionDetail from "pages/Transaction/TransactionDetail";

const routerPath = Object.freeze({
  Dashboard: "/dashboard",
  Index: "/index",
  Payment: "/payment",
  LinkPayment: "/payment/link-payment",
  LinkPaymentEdit: "/payment/link-payment/:payment_id",
  ManualPayment: "/payment/manual-payment",
  Transaction: "/transaction",
  TransactionDetail: "/transaction/detail/:uniq",
  Dealer: "/dealer",
  DealerSubCreate: "/dealer/sub/create",
  DealerSubDetail: "/dealer/sub/detail/:dealer_id",
  DealerUser: "/dealer/user",
  DealerUserCreate: "/dealer/user/create",
  DealerUserDetail: "/dealer/user/detail/:user_id",
  Rates: "/rates",

  // Public Routes
  Login: "/login",
  ResetPassword: "/reset-password",
  ForgotPassword: "/forgot-password",
  RegistrationApplication: "/registration-application",
  PaymentRequest: "/pay/:uuid",
  PaymentSuccess: "/payment-success",
  PaymentFailed: "/payment-failed",
});

const authProtectedRoutes = [
  { path: routerPath.Dashboard, component: <DashboardEcommerce /> },
  { path: routerPath.Index, component: <DashboardEcommerce /> },

  { path: routerPath.LinkPayment, component: <PaymentLink /> },
  { path: routerPath.LinkPaymentEdit, component: <PaymentLink /> },
  { path: routerPath.ManualPayment, component: <ManuelPayment /> },
  { path: routerPath.Payment, component: <PaymentLinksList /> },

  { path: routerPath.Transaction, component: <Transaction /> },
  { path: routerPath.TransactionDetail, component: <TransactionDetail /> },

  { path: routerPath.Dealer, component: <Dealer /> },
  { path: routerPath.DealerSubCreate, component: <SubDealerEdit /> },
  { path: routerPath.DealerSubDetail, component: <SubDealerEdit /> },
  { path: routerPath.DealerUser, component: <DealerUserList /> },
  { path: routerPath.DealerUserCreate, component: <DealerUserEdit /> },
  { path: routerPath.DealerUserDetail, component: <DealerUserEdit /> },
  { path: routerPath.Rates, component: <Rates /> },

  //User Profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes: any[] = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: routerPath.Login, component: <Login /> },
  { path: routerPath.ForgotPassword, component: <ForgetPasswordPage /> },
  { path: routerPath.ResetPassword, component: <ResetPassword /> },
  {
    path: routerPath.RegistrationApplication,
    component: <RegistrationApplication />,
  },
  { path: routerPath.PaymentRequest, component: <PaymentRequest /> },

  //AuthenticationInner pages

  { path: "/auth-404-basic", component: <Basic404 /> },
  { path: "/auth-404-cover", component: <Cover404 /> },
  { path: "/auth-404-alt", component: <Alt404 /> },
  { path: "/auth-500", component: <Error500 /> },

  { path: "/auth-offline", component: <Offlinepage /> },
  { path: routerPath.PaymentSuccess, component: <PaymentSuccess /> },
  { path: routerPath.PaymentFailed, component: <PaymentFailed /> },
];

export { authProtectedRoutes, publicRoutes, routerPath };
