import { AppEnv } from "AppEnv";
import { useEffect, useMemo, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { deletePaymentLink, getAllPayments } from "slices/thunks";
import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";
import { Trans, useTranslation, withTranslation } from "react-i18next";
import CustomTable from "Components/CustomTable";
import { RootState } from "slices";
import { resetPatmentLinkDeleteStatus } from "slices/paymentLink/paymentLinkReducer";

const PaymentLinksList = (props: PropsFromRedux) => {
  const {
    paymentLinks,
    paymentLinksStatus,
    paymentLinkDeleteStatus,
    getAllPayments,
    delPaymentLink,
    resetDelStatus,
    pageNumber,
  } = props;
  const { t } = useTranslation();
  const [deleteUuid, setDeleteUuid] = useState<string>("");

  useEffect(() => {
    getAllPayments();
  }, [getAllPayments]);

  useEffect(() => {
    switch (paymentLinkDeleteStatus) {
      case "succeeded":
        setDeleteUuid("");
        resetDelStatus();
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentLinkDeleteStatus]);

  const handleCopyLink = (link: string) => {
    navigator.clipboard.writeText(link);
    toast.info(t("Link copied"), { autoClose: 3000 });
  };

  const handleShareLink = (link: string) => {
    if (navigator.share) {
      navigator
        .share({
          title: "Payment Link",
          text: "Here is your payment link",
          url: link,
        })
        .catch((error) => {
          console.error("Paylaşma iptal edildi.");
        });
    } else {
      toast.warn(t("Sharing feature is not supported"), { autoClose: 3000 });
    }
  };

  const columns = useMemo<Array<PosPazarTypes.CustomTableColumn>>(
    () => [
      {
        header: t("Title"),
        accessor: "title",
      },
      {
        header: t("Amount"),
        accessor: "amount",
        cell: (row: PosPazarTypes.PaymentLinkItem) => {
          return Number(row.amount).toFixed(2) + " ₺";
        },
      },
      {
        header: t("Link"),
        accessor: "uuid",
        cell: (row: PosPazarTypes.PaymentLinkItem) => {
          const link = `${AppEnv.PaymentUrl}/${row.uuid}`;
          return (
            <InputGroup size="sm" key={link}>
              <Input type="text" value={link} readOnly />
              <Button color="secondary" onClick={() => handleCopyLink(link)}>
                <FeatherIcon icon="copy" />
              </Button>
              <Button color="secondary" onClick={() => handleShareLink(link)}>
                <FeatherIcon icon="share-2" />
              </Button>
            </InputGroup>
          );
        },
      },
      {
        header: t("Created At"),
        accessor: "created_at",
        cell: (row: PosPazarTypes.PaymentLinkItem) => {
          const date = new Date(row.created_at);
          return date.toLocaleDateString("tr-TR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          });
        },
      },
      {
        header: t("Status"),
        accessor: "status",
        cell: (row: PosPazarTypes.PaymentLinkItem) => {
          const status = row.status;
          const color =
            status === "active"
              ? "success"
              : status === "pending"
              ? "warning"
              : "danger";
          return (
            <Badge color={color} pill={true}>
              {status}
            </Badge>
          );
        },
      },
      {
        header: "",
        accessor: "uuid",
        cell: (row: PosPazarTypes.PaymentLinkItem) => {
          return (
            <Button
              color="link"
              className="text-danger pt-0"
              onClick={() => setDeleteUuid(row.uuid)}
            >
              {t("Delete")}
            </Button>
          );
        },
      },
      {
        header: "",
        accessor: "uuid",
        cell: (row: PosPazarTypes.PaymentLinkItem) => {
          // dispatch(setPaymentLink(cell.row.original));
          return (
            <Link to={`/payment/link-payment/${row.uuid}`}>{t("Details")}</Link>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <CustomTable
                columns={columns}
                data={paymentLinks}
                onPageChange={(pn) => {
                  if (pn !== pageNumber) {
                    getAllPayments(pn);
                  }
                }}
                status={paymentLinksStatus}
                {...props}
              />
            </CardBody>
          </Card>
        </Container>
      </div>

      <Modal isOpen={!!deleteUuid} onClosed={() => setDeleteUuid("")} centered>
        <ModalHeader>
          <Trans i18nKey="Confirm Deletion" />
        </ModalHeader>
        <ModalBody>
          <Trans i18nKey="Are you sure you want to continue with the deletion? This action cannot be undone." />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setDeleteUuid("")}>
            <Trans i18nKey="Cancel" />
          </Button>
          <Button
            color="danger"
            onClick={() => delPaymentLink(deleteUuid)}
            disabled={paymentLinkDeleteStatus === "loading"}
          >
            {paymentLinkDeleteStatus === "loading" ? (
              <Spinner size="sm" color="light" />
            ) : (
              <Trans i18nKey="Delete" />
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  paymentLinks: state.PaymentLink.paymentLinks,
  paymentLinksStatus: state.PaymentLink.paymentLinksStatus,
  paymentLinkDeleteStatus: state.PaymentLink.paymentLinkDeleteStatus,
  totalData: state.PaymentLink.count,
  pageNumber: state.PaymentLink.pageNumber,
});

const mapDispatchToProps = (dispatch: any) => ({
  getAllPayments: (pageNumber?: number) => dispatch(getAllPayments(pageNumber)),
  delPaymentLink: (uuid: string) => dispatch(deletePaymentLink(uuid)),
  resetDelStatus: () => dispatch(resetPatmentLinkDeleteStatus()),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(withTranslation()(PaymentLinksList));
