import { createSlice } from "@reduxjs/toolkit";
import { getInstallmentOptions } from "./installmentThunk";

type StateType = {
  installments: PosPazarTypes.InstallmentsItem[];
  installmentsStatus: PosPazarTypes.ApiRequestStatus;
  installementsError: string | undefined;
};
export const initialState: StateType = {
  installments: [],
  installmentsStatus: "idle",
  installementsError: undefined,
};

const installmentSlice = createSlice({
  name: "installment",
  initialState,
  reducers: {
    resetInstallmentState(state) {
      state.installments = [] as PosPazarTypes.InstallmentsItem[];
      state.installmentsStatus = "idle";
      state.installementsError = undefined;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getInstallmentOptions.fulfilled, (state, action) => {
        state.installments = action.payload;
        state.installmentsStatus = "succeeded";
        state.installementsError = "";
      })
      .addCase(getInstallmentOptions.rejected, (state, action) => {
        state.installments = [];
        state.installmentsStatus = "failed";
        state.installementsError = action.payload?.message;
      })
      .addCase(getInstallmentOptions.pending, (state) => {
        state.installmentsStatus = "loading";
        state.installementsError = undefined;
        state.installments = [];
      });
  },
});

export const { resetInstallmentState } = installmentSlice.actions;
export default installmentSlice.reducer;
