import { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import {
  getAllCities,
  getAllTownships,
  getAllProviders,
  deletePaymentLink,
  createPaymentLink,
  updatePaymentLink,
  getOnePaymentLink,
} from "slices/thunks";
import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { AppEnv } from "AppEnv";
import { Trans, useTranslation, withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { RootState } from "slices";
import {
  convertDateTimeLocalToISO,
  convertISOToDateTimeLocal,
} from "helpers/data/converter";
import ProviderSelect from "Components/ProviderSelect";
import { routerPath } from "Routes/allRoutes";
import {
  resetPatmentLinkDeleteStatus,
  resetPaymentLinkThunk,
} from "slices/paymentLink/paymentLinkReducer";

const PaymentLink = (props: PropsFromRedux) => {
  const { payment_id } = useParams<{ payment_id: string }>();
  const {
    paymentLink,
    paymentLinkStatus,
    paymentLinkErrorMessage,
    paymentLinkCreateStatus,
    paymentLinkCreateError,
    paymentLinkDeleteStatus,
    paymentLinkDeleteError,
    cities,
    townships,
    posProviders,
    fetchAllCities,
    fetchAllTownships,
    delPaymentLink,
    newPaymentLink,
    updPaymentLink,
    fetchOnePaymentLink,
    fetchAllProviders,
    resetDelStatus,
    resetReducer,
  } = props;
  const { t } = useTranslation();

  const [generatedLink, setGeneratedLink] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState(0);
  const [linkType, setLinkType] = useState<"single" | "multiple">("single");
  const [phoneNumber, setPhoneNumber] = useState("+90");
  const [email, setEmail] = useState("");
  const [cityId, setCityId] = useState(0);
  const [townshipId, setTownshipId] = useState(0);
  const [status, setStatus] = useState<"active" | "passive">("active");
  const [expiredAt, setExpiredAt] = useState<string>("");
  const [maximumUsage, setMaximumUsage] = useState(1);
  const [minimumInstallment, setMinimumInstallment] = useState(1);
  const [maximumInstallment, setMaximumInstallment] = useState(1);
  const [posProvider, setPosProvider] = useState("");
  const [reflectCost, setReflectCost] = useState(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);
  const handleDeleteLink = () => {
    if (paymentLink?.uuid) {
      delPaymentLink(paymentLink.uuid);
    }
    toggleDeleteModal();
  };

  const [errors, setErrors] = useState({
    title: "",
    description: "",
    amount: "",
    phoneNumber: "",
    email: "",
    cityId: "",
    townshipId: "",
    status: "",
    minimumInstallment: "",
    maximumInstallment: "",
    posProvider: "",
  });

  function resetState() {
    setTitle("");
    setDescription("");
    setAmount(0);
    setLinkType("single");
    setPhoneNumber("+90");
    setEmail("");
    setCityId(0);
    setTownshipId(0);
    setStatus("active");
    setExpiredAt("");
    setMaximumUsage(1);
    setMinimumInstallment(1);
    setMaximumInstallment(1);
    setPosProvider("");
    setGeneratedLink("");
  }

  useEffect(() => {
    fetchAllCities();
    fetchAllProviders();
  }, [fetchAllProviders, fetchAllCities]);

  useEffect(() => {
    return () => {
      resetState();
      resetReducer();
    };
  }, [resetReducer]);

  useEffect(() => {
    if (payment_id) {
      fetchOnePaymentLink(payment_id);
    }
  }, [payment_id, fetchOnePaymentLink]);

  useEffect(() => {
    if (payment_id && paymentLink?.uuid) {
      setTitle(paymentLink.title);
      setDescription(paymentLink.description);
      setAmount(paymentLink.amount);
      setLinkType(paymentLink.link_type);
      setPhoneNumber(paymentLink.phone_number);
      setEmail(paymentLink.email ?? "");
      setCityId(paymentLink.city);
      setTownshipId(paymentLink.township);
      setStatus(paymentLink.status);
      setExpiredAt(paymentLink.expired_at);
      setMaximumUsage(paymentLink.maximum_usage ?? 1);
      setMinimumInstallment(paymentLink.minimum_installment);
      setMaximumInstallment(paymentLink.maximum_installment);
      setPosProvider(paymentLink.pos_provider);
      setReflectCost(paymentLink.reflect_cost);
      setGeneratedLink(`${AppEnv.PaymentUrl}/${paymentLink.uuid}`);
    }
  }, [payment_id, paymentLink]);

  useEffect(() => {
    if (cityId) {
      fetchAllTownships(cityId);
    }
  }, [cityId, fetchAllTownships]);

  useEffect(() => {
    if (posProviders.length > 0) {
      setPosProvider(posProviders[0].key);
    }
  }, [posProviders]);

  useEffect(() => {
    if (cities.length > 0) {
      setCityId(cities[0].id);
    }
  }, [cities]);

  useEffect(() => {
    if (townships.length > 0) {
      setTownshipId(townships[0].id);
    }
  }, [townships]);

  useEffect(() => {
    switch (paymentLinkDeleteStatus) {
      case "succeeded":
        const toastId = toast.success(t("Payment link deleted successfully"), {
          autoClose: 3000,
        });
        toast.onChange((payload) => {
          if (payload.id === toastId && payload.status === "removed") {
            resetDelStatus();
            window.location.href = routerPath.Payment;
          }
        });
        break;
      case "failed":
        toast.error(t(paymentLinkDeleteError ?? "Unknown error occurred"), {
          autoClose: 3000,
        });
        break;
      default:
        break;
    }
  }, [paymentLinkDeleteStatus, paymentLinkDeleteError, t, resetDelStatus]);

  useEffect(() => {
    switch (paymentLinkStatus) {
      case "failed":
        const toastId = toast.error(
          t(paymentLinkErrorMessage ?? "Unknown error occurred"),
          { autoClose: 3000 }
        );
        toast.onChange((payload) => {
          if (payload.id === toastId && payload.status === "removed") {
            window.location.href = routerPath.Payment;
          }
        });
        break;
      default:
        break;
    }
  }, [paymentLinkStatus, paymentLinkErrorMessage, t]);

  useEffect(() => {
    switch (paymentLinkCreateStatus) {
      case "succeeded":
        const toastId = toast.success(t("Payment link created successfully"), {
          autoClose: 3000,
        });
        toast.onChange((payload) => {
          if (payload.id === toastId && payload.status === "removed") {
            window.location.href = routerPath.Payment;
          }
        });
        break;
      case "failed":
        toast.error(t(paymentLinkCreateError ?? "Unknown error occurred"), {
          autoClose: 3000,
        });
        break;
      default:
        break;
    }
  }, [paymentLinkCreateStatus, paymentLinkCreateError, t]);

  const validateInputs = () => {
    const newErrors = {
      title: "",
      description: "",
      amount: "",
      phoneNumber: "",
      email: "",
      cityId: "",
      townshipId: "",
      status: "",
      minimumInstallment: "",
      maximumInstallment: "",
      posProvider: "",
    };
    let isValid = true;

    if (!title) {
      newErrors.title = t("Title is required");
      isValid = false;
    }
    if (!description) {
      newErrors.description = t("Description is required");
      isValid = false;
    }
    if (amount <= 0) {
      newErrors.amount = t("Amount must be greater than 0");
      isValid = false;
    }
    if (!phoneNumber.startsWith("+")) {
      newErrors.phoneNumber = t("Phone number must start with +");
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      newErrors.email = t("Invalid email");
      isValid = false;
    }

    if (!cityId) {
      newErrors.cityId = t("City is required");
      isValid = false;
    }
    if (!townshipId) {
      newErrors.townshipId = t("Township is required");
      isValid = false;
    }
    if (!status) {
      newErrors.status = t("Status is required");
      isValid = false;
    }
    if (minimumInstallment < 1 || minimumInstallment > maximumInstallment) {
      newErrors.minimumInstallment = t(
        "Minimum installment must be greater than 1 and less than the maximum installment"
      );
      isValid = false;
    }
    if (maximumInstallment < 1) {
      newErrors.maximumInstallment = t(
        "Maximum installment must be greater than 1"
      );
      isValid = false;
    }
    if (!posProvider) {
      newErrors.posProvider = t("POS provider is required");
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleGenerateLink = async () => {
    if (!validateInputs()) return;
    const body: PosPazarTypes.PaymentLinkPayload = {
      title,
      description,
      amount,
      link_type: linkType,
      email: email,
      phone_number: phoneNumber,
      city: cityId,
      township: townshipId,
      status: status,
      maximum_usage: maximumUsage,
      minimum_installment: minimumInstallment,
      maximum_installment: maximumInstallment,
      pos_provider_key: posProvider,
      reflect_cost: reflectCost,
    };
    if (expiredAt) {
      body.expired_at = expiredAt;
    }
    newPaymentLink(body);
  };

  const handleUpdateLink = async () => {
    if (!validateInputs()) return;
    const body = {
      title,
      description,
      amount,
      link_type: linkType,
      email,
      phone_number: phoneNumber,
      city_id: cityId,
      township_id: townshipId,
      status,
      expired_at: expiredAt,
      maximum_usage: maximumUsage,
      minimum_installment: minimumInstallment,
      maximum_installment: maximumInstallment,
      pos_provider_key: posProvider,
    };
    if (payment_id) {
      updPaymentLink({ uuid: payment_id, body });
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(generatedLink);
    toast.info(t("Link copied"), { autoClose: 3000 });
  };

  const handleShareLink = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Payment Link",
          text: "Here is your payment link",
          url: generatedLink,
        })
        .catch((error) => {
          console.error("Paylaşma iptal edildi.");
        });
    } else {
      toast.warn(t("Sharing feature is not supported"), { autoClose: 3000 });
    }
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Card className="card-height-100">
            <CardHeader>{t("Create Payment Link")}</CardHeader>
            <CardBody>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="posProvider">{t("POS Provider")}</Label>
                    <ProviderSelect
                      providers={posProviders}
                      selected={posProvider}
                      onChange={(val) => {
                        setPosProvider(val);
                      }}
                    />
                    {errors.posProvider && (
                      <div className="text-danger">{errors.posProvider}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="title">{t("Title")}</Label>
                    <Input
                      type="text"
                      id="title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      invalid={Boolean(errors.title)}
                    />
                    {errors.title && (
                      <div className="text-danger">{errors.title}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="description">{t("Description")}</Label>
                    <Input
                      type="textarea"
                      id="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      invalid={Boolean(errors.description)}
                    />
                    {errors.description && (
                      <div className="text-danger">{errors.description}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="amount">{t("Amount")}</Label>
                    <Input
                      type="number"
                      id="amount"
                      value={amount}
                      onChange={(e) => setAmount(Number(e.target.value))}
                      step="0.01"
                      invalid={Boolean(errors.amount)}
                    />
                    {errors.amount && (
                      <div className="text-danger">{errors.amount}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="2" className="d-flex align-items-center">
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      id="includeRate"
                      checked={reflectCost}
                      onChange={(e) => {
                        setReflectCost(e.target.checked === true);
                      }}
                    />
                    <Label className="form-check-label" htmlFor="includeRate">
                      {t("Include rate in amount")}
                    </Label>
                  </div>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="linkType">{t("Link Type")}</Label>
                    <Input
                      type="select"
                      id="linkType"
                      value={linkType}
                      onChange={(e) =>
                        setLinkType(e.target.value as "single" | "multiple")
                      }
                    >
                      <option value="single">{t("Single use")}</option>
                      <option value="multiple">{t("Multiple use")}</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="phoneNumber">{t("Phone Number")}</Label>
                    <Input
                      type="text"
                      id="phoneNumber"
                      value={phoneNumber}
                      onChange={(e) => {
                        const { value } = e.target;
                        const formattedValue = value
                          .replace(/\D/g, "")
                          .slice(2);
                        if (formattedValue.length <= 10) {
                          setPhoneNumber("+90" + formattedValue);
                        }
                      }}
                      invalid={Boolean(errors.phoneNumber)}
                    />
                    {errors.phoneNumber && (
                      <div className="text-danger">{errors.phoneNumber}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="email">{t("Email")}</Label>
                    <Input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      invalid={Boolean(errors.email)}
                    />
                    {errors.email && (
                      <div className="text-danger">{errors.email}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="cityId">{t("City")}</Label>
                    <Input
                      type="select"
                      id="cityId"
                      value={cityId}
                      onChange={(e) => setCityId(Number(e.target.value))}
                      invalid={Boolean(errors.cityId)}
                    >
                      <option value="0" hidden>
                        {t("Select City")}
                      </option>
                      {cities.map((city: any) => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                    </Input>
                    {errors.cityId && (
                      <div className="text-danger">{errors.cityId}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="townshipId">{t("Township")}</Label>
                    <Input
                      type="select"
                      id="townshipId"
                      value={townshipId}
                      onChange={(e) => setTownshipId(Number(e.target.value))}
                      disabled={!cityId}
                      invalid={Boolean(errors.townshipId)}
                    >
                      <option value="0" hidden>
                        {t("Select Township")}
                      </option>
                      {townships.map((township: any) => (
                        <option key={township.id} value={township.id}>
                          {township.name}
                        </option>
                      ))}
                    </Input>
                    {errors.townshipId && (
                      <div className="text-danger">{errors.townshipId}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="status" htmlFor="status">
                      {t("Status")}
                    </Label>
                    <Input
                      type="select"
                      name="status"
                      value={status}
                      onChange={(e) =>
                        setStatus(e.target.value as "active" | "passive")
                      }
                      invalid={Boolean(errors.status)}
                    >
                      <option value="active">{t("Active")}</option>
                      <option value="passive">{t("Inactive")}</option>
                    </Input>
                    {errors.status && (
                      <div className="text-danger">{errors.status}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="expiredAt">{t("Expiration Date")}</Label>
                    <Input
                      type="datetime-local"
                      id="expiredAt"
                      value={convertISOToDateTimeLocal(expiredAt)}
                      onChange={(e) =>
                        setExpiredAt(convertDateTimeLocalToISO(e.target.value))
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="maximumUsage">{t("Maximum Usage")}</Label>
                    <Input
                      type="number"
                      id="maximumUsage"
                      value={maximumUsage}
                      onChange={(e) => setMaximumUsage(Number(e.target.value))}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="minimumInstallment">
                      {t("Minimum Installment")}
                    </Label>
                    <Input
                      type="number"
                      id="minimumInstallment"
                      value={minimumInstallment}
                      onChange={(e) =>
                        setMinimumInstallment(Number(e.target.value))
                      }
                      min="1"
                      max="12"
                      invalid={Boolean(errors.minimumInstallment)}
                    />
                    {errors.minimumInstallment && (
                      <div className="text-danger">
                        {errors.minimumInstallment}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="maximumInstallment">
                      {t("Maximum Installment")}
                    </Label>
                    <Input
                      type="number"
                      id="maximumInstallment"
                      value={maximumInstallment}
                      onChange={(e) =>
                        setMaximumInstallment(Number(e.target.value))
                      }
                      min="1"
                      max="12"
                      invalid={Boolean(errors.maximumInstallment)}
                    />
                    {errors.maximumInstallment && (
                      <div className="text-danger">
                        {errors.maximumInstallment}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                {Boolean(payment_id) ? (
                  <Col md={6}>
                    <Label for="generatedLink">{t("Payment Link")}</Label>
                    <InputGroup>
                      <Input
                        type="text"
                        id="generatedLink"
                        value={generatedLink}
                        placeholder="Link Oluşturulmadı"
                        readOnly
                      />
                      <Button color="secondary" onClick={handleCopyLink}>
                        <FeatherIcon icon="copy" />
                      </Button>
                      <Button color="secondary" onClick={handleShareLink}>
                        <FeatherIcon icon="share-2" />
                      </Button>
                    </InputGroup>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
              <Row className="mt-4 justify-content-between">
                <Col md={12} className="text-end">
                  {payment_id ? (
                    <>
                      <Button color="danger me-1" onClick={toggleDeleteModal}>
                        {t("Delete")}
                      </Button>
                      <Button
                        color="primary"
                        onClick={handleUpdateLink}
                        disabled={paymentLinkCreateStatus === "loading"}
                      >
                        {paymentLinkCreateStatus === "loading" ? (
                          <Trans i18nKey="Generating" />
                        ) : (
                          <Trans i18nKey="Update" />
                        )}
                      </Button>
                    </>
                  ) : (
                    <Button
                      color="primary"
                      onClick={handleGenerateLink}
                      disabled={paymentLinkCreateStatus === "loading"}
                    >
                      {paymentLinkCreateStatus === "loading" ? (
                        <Trans i18nKey="Generating" />
                      ) : (
                        <Trans i18nKey="Generate Link" />
                      )}
                    </Button>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
      <Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal} centered>
        <ModalHeader toggle={toggleDeleteModal}>
          <Trans i18nKey="Confirm Deletion" />
        </ModalHeader>
        <ModalBody>
          <Trans i18nKey="Are you sure you want to continue with the deletion? This action cannot be undone." />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleDeleteModal}>
            <Trans i18nKey="Cancel" />
          </Button>
          <Button
            color="danger"
            onClick={handleDeleteLink}
            disabled={paymentLinkDeleteStatus === "loading"}
          >
            <Trans i18nKey="Delete" />
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  paymentLink: state.PaymentLink.paymentLink,
  paymentLinkStatus: state.PaymentLink.paymentLinkStatus,
  paymentLinkErrorMessage: state.PaymentLink.paymentLinkErrorMessage,
  paymentLinkCreateStatus: state.PaymentLink.paymentLinkCreateStatus,
  paymentLinkCreateError: state.PaymentLink.paymentLinkCreateError,
  paymentLinkDeleteStatus: state.PaymentLink.paymentLinkDeleteStatus,
  paymentLinkDeleteError: state.PaymentLink.paymentLinkDeleteError,
  cities: state.Core.cities,
  townships: state.Core.townships,
  posProviders: state.PosProvider.providers,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchAllCities: () => dispatch(getAllCities()),
  fetchAllTownships: (city_id: number) => dispatch(getAllTownships(city_id)),
  delPaymentLink: (uuid: string) => dispatch(deletePaymentLink(uuid)),
  newPaymentLink: (body: PosPazarTypes.PaymentLinkPayload) =>
    dispatch(createPaymentLink(body)),
  updPaymentLink: (body: any) => dispatch(updatePaymentLink(body)),
  fetchOnePaymentLink: (uuid: string) => dispatch(getOnePaymentLink(uuid)),
  fetchAllProviders: () => dispatch(getAllProviders()),
  resetDelStatus: () => dispatch(resetPatmentLinkDeleteStatus()),
  resetReducer: () => dispatch(resetPaymentLinkThunk()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withTranslation()(PaymentLink));
