import React, { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Input,
  Alert,
} from "reactstrap";

const TwoFactorModal = (props: {
  isOpen: boolean;
  setIsOpen: (arg0: boolean) => void;
  submit: (value: string) => void;
  resend?: () => void;
  timeout?: number;
  error?: string;
}) => {
  const { isOpen, setIsOpen, submit, resend, timeout, error } = props;
  const { t } = useTranslation();
  const [values, setValues] = useState<string[]>(["", "", "", "", "", ""]);
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [counter, setCounter] = useState(-1);

  // Manage input changes
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newValues = [...values];
    newValues[index] = e.target.value;

    if (e.target.value.length === 1 && index < 5) {
      inputRefs.current[index + 1]?.focus(); // Focus on the next input
    }
    setValues(newValues);
  };

  // Allow only numeric input
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  // Disable paste
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  // Move to the previous input on backspace
  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && values[index] === "" && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleSubmit = () => {
    const code = values.join("");
    submit(code);
  };

  useEffect(() => {
    if (isOpen) {
      inputRefs.current[0]?.focus();
    } else {
      setErrorMessage("");
      onClosed();
    }
  }, [isOpen]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
  }, [error]);

  const onClosed = () => {
    setCounter(-1);
    setValues(["", "", "", "", "", ""]);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isOpen && typeof timeout === "number") {
      setCounter(timeout);
      timer = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter <= 1) {
            clearInterval(timer);
            setErrorMessage(t("Verification code has expired"));
            return 0;
          }
          return prevCounter - 1;
        });
      }, 1000);
    }
    return () => {
      if (timer !== undefined) clearInterval(timer);
    };
  }, [isOpen, timeout, t]);

  return (
    <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} centered>
      <ModalHeader toggle={() => setIsOpen(!isOpen)}>
        <Trans i18nKey="Enter verification code" />
      </ModalHeader>
      <ModalBody>
        <Row className="justify-content-center">
          {values.map((value, index) => (
            <Col xs="2" className="px-1" key={index}>
              <Input
                type="text"
                className="p-0"
                value={value}
                onChange={(e) => handleChange(e, index)}
                onKeyPress={handleKeyPress}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={handlePaste}
                maxLength={1} // Maximum 1 character
                innerRef={(el) =>
                  (inputRefs.current[index] = el as HTMLInputElement)
                }
                style={{ textAlign: "center", fontSize: "24px" }} // Centered and large characters
                autoFocus={index === 0}
              />
            </Col>
          ))}
          {errorMessage && (
            <Col xs="12" className=" mt-2">
              <Alert color="danger">{errorMessage}</Alert>
            </Col>
          )}
        </Row>
        <div className="d-flex justify-content-end align-items-center mt-4">
          {counter > -1 && (
            <div className="me-auto">
              {Math.floor(counter / 60)
                .toString()
                .padStart(2, "0")}
              :{(counter % 60).toString().padStart(2, "0")}
            </div>
          )}
          {resend && (
            <Button color="link" onClick={resend}>
              <Trans i18nKey="Resend" />
            </Button>
          )}
          <Button
            color="primary"
            className="ms-3"
            onClick={handleSubmit}
            disabled={values.some((v) => v === "") || counter === 0}
          >
            <Trans i18nKey="Confirm" />
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default TwoFactorModal;
