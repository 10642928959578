import { createSlice } from "@reduxjs/toolkit";
import { getAllProviders, new3dPayment, ratesThunk } from "./posproviderThunk";

type StateType = {
  providers: Array<PosPazarTypes.PosProvider>;
  providersStatus: PosPazarTypes.ApiRequestStatus;
  providersError: string | undefined;
  paymentStatus: PosPazarTypes.ApiRequestStatus;
  paymentError: string | undefined;

  rates: PosPazarTypes.RatesResponse | undefined;
  ratesStatus: PosPazarTypes.ApiRequestStatus;
  ratesError: string | undefined;
};
export const initialState: StateType = {
  providers: [],
  providersStatus: "idle",
  providersError: undefined,
  paymentStatus: "idle",
  paymentError: undefined,

  rates: undefined,
  ratesStatus: "idle",
  ratesError: undefined,
};

const paymentLinkSlice = createSlice({
  name: "posprovider",
  initialState,
  reducers: {
    resetPaymentState(state) {
      state.paymentStatus = "idle";
      state.paymentError = undefined;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllProviders.fulfilled, (state, action) => {
        state.providers = action.payload;
        state.providersStatus = "succeeded";
        state.providersError = undefined;
      })
      .addCase(getAllProviders.rejected, (state, action) => {
        state.providers = [];
        state.providersStatus = "failed";
        state.providersError = action.error.message;
      })
      .addCase(getAllProviders.pending, (state) => {
        state.providersStatus = "loading";
        state.providersError = undefined;
        state.providers = [];
      });
    builder
      .addCase(new3dPayment.rejected, (state, action) => {
        state.paymentStatus = "failed";
        state.paymentError = action.payload?.message;
      })
      .addCase(new3dPayment.pending, (state) => {
        state.paymentStatus = "loading";
        state.paymentError = undefined;
      })
      .addCase(new3dPayment.fulfilled, (state) => {
        state.paymentStatus = "succeeded";
        state.paymentError = undefined;
      });
    builder
      .addCase(ratesThunk.fulfilled, (state, action) => {
        state.rates = action.payload;
        state.ratesStatus = "succeeded";
        state.ratesError = undefined;
      })
      .addCase(ratesThunk.rejected, (state, action) => {
        state.ratesStatus = "failed";
        state.ratesError = action.payload?.message;
        state.rates = undefined;
      })
      .addCase(ratesThunk.pending, (state) => {
        state.ratesStatus = "loading";
        state.ratesError = undefined;
        state.rates = undefined;
      });
  },
});

export const { resetPaymentState } = paymentLinkSlice.actions;
export default paymentLinkSlice.reducer;
