import { createAsyncThunk } from "@reduxjs/toolkit";
import { getInstallments } from "helpers/api/inner_urls";

export const getInstallmentOptions = createAsyncThunk<
  PosPazarTypes.InstallmentsItem[],
  {
    provider: string;
    amount: number;
    binNumber: string;
    reflect_cost: boolean;
  },
  { rejectValue: PosPazarTypes.ApiRequestError }
>("installment/getInstallmentOptions", async (state, { rejectWithValue }) => {
  try {
    const { provider, amount, binNumber, reflect_cost } = state;
    const { data } = await getInstallments(
      provider,
      amount,
      binNumber,
      reflect_cost
    );
    return data;
  } catch (error: any) {
    if (error?.response?.data?.message) {
      const { message, ...data } = error.response.data;
      return rejectWithValue({
        message: message,
        status: error.response.status,
        data: data,
      });
    }
    return rejectWithValue({
      message: error.message,
      status: error.response.status,
    });
  }
});
