import { isEmail, isPhoneNumber } from "helpers/data/validate";
import { useEffect, useState } from "react";
import { Trans, useTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { routerPath } from "Routes/allRoutes";
import { RootState } from "slices";
import {
  createUserThunk,
  deleteUserThunk,
  getUserByIdThunk,
  updateUserThunk,
} from "slices/thunks";
import { resetUserState } from "slices/user/userReducer";

const DealerUserEdit = (props: PropsFromRedux) => {
  const { user_id } = useParams<{ user_id: string }>();
  const {
    user,
    userCreateError,
    userCreateStatus,
    userDeleteError,
    userDeleteStatus,
    userError,
    userStatus,
    userUpdateError,
    userUpdateStatus,
    getUser,
    createUser,
    updateUser,
    deleteUser,
    resetState,
  } = props;

  const { t } = useTranslation();
  const [userForm, setUserForm] = useState({
    username: "",
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "+90",
    otp_required: false,
  });
  const [errors, setErrors] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
  });

  const [isEdit, setIsEdit] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);
  const handleDeleteUser = () => {
    if (isEdit && user_id) {
      deleteUser(Number(user_id));
    }
    toggleDeleteModal();
  };

  useEffect(() => {
    return () => {
      resetState();
      setUserForm({
        username: "",
        email: "",
        first_name: "",
        last_name: "",
        phone_number: "+90",
        otp_required: false,
      });
      setIsEdit(false);
    };
  }, [resetState]);

  useEffect(() => {
    if (user_id) {
      const userId = Number(user_id);
      setIsEdit(true);
      if (isNaN(userId)) {
        window.location.href = routerPath.DealerUser;
        return;
      }
      getUser(userId);
    } else {
      setIsEdit(false);
    }
  }, [user_id, getUser]);

  useEffect(() => {
    if (user && isEdit) {
      setUserForm({
        username: user.username,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        phone_number: user.phone_number,
        otp_required: user.otp_required,
      });
    }
  }, [user, isEdit]);

  useEffect(() => {
    switch (userCreateStatus) {
      case "succeeded":
        const toastId = toast.success(t("User created successfully"), {
          autoClose: 3000,
        });
        toast.onChange((payload) => {
          if (payload.id === toastId && payload.status === "removed") {
            window.location.href = routerPath.DealerUser;
          }
        });
        break;
      case "failed":
        toast.error(t(userCreateError ?? "Unknown error occurred"), {
          autoClose: 3000,
        });
        break;
      default:
        break;
    }
  }, [userCreateStatus, userCreateError, t]);

  useEffect(() => {
    switch (userUpdateStatus) {
      case "succeeded":
        const toastId = toast.success(t("User updated successfully"), {
          autoClose: 3000,
        });
        toast.onChange((payload) => {
          if (payload.id === toastId && payload.status === "removed") {
            window.location.href = routerPath.DealerUser;
          }
        });
        break;
      case "failed":
        toast.error(t(userUpdateError ?? "Unknown error occurred"), {
          autoClose: 3000,
        });
        break;
      default:
        break;
    }
  }, [userUpdateStatus, userUpdateError, t]);

  useEffect(() => {
    switch (userStatus) {
      case "failed":
        const toastId = toast.error(t(userError ?? "Unknown error occurred"), {
          autoClose: 3000,
        });
        toast.onChange((payload) => {
          if (payload.id === toastId && payload.status === "removed") {
            window.location.href = routerPath.DealerUser;
          }
        });
        break;
      default:
        break;
    }
  }, [userStatus, userError, t]);

  useEffect(() => {
    switch (userDeleteStatus) {
      case "succeeded":
        const toastId = toast.success(t("User deleted successfully"), {
          autoClose: 3000,
        });
        toast.onChange((payload) => {
          if (payload.id === toastId && payload.status === "removed") {
            window.location.href = routerPath.DealerUser;
          }
        });
        break;
      case "failed":
        toast.error(t(userDeleteError ?? "Unknown error occurred"), {
          autoClose: 3000,
        });
        break;
      default:
        break;
    }
  }, [userDeleteStatus, userDeleteError, t]);

  const handleSave = () => {
    if (!formIsValid()) {
      return;
    }
    if (isEdit) {
      updateUser({
        email: userForm.email,
        first_name: userForm.first_name,
        last_name: userForm.last_name,
        phone_number: userForm.phone_number,
        username: userForm.username,
        user_id: Number(user_id),
        otp_required: userForm.otp_required,
      });
    } else {
      createUser({
        email: userForm.email,
        first_name: userForm.first_name,
        last_name: userForm.last_name,
        phone_number: userForm.phone_number,
        username: userForm.email,
        otp_required: userForm.otp_required,
      });
    }
  };

  const formIsValid = () => {
    let isValid = true;
    const newErrors = {
      email: "",
      first_name: "",
      last_name: "",
      phone_number: "",
    };

    const isEmailValid = isEmail(userForm.email);
    if (!isEmailValid.isValid) {
      newErrors.email = t(isEmailValid.message);
      isValid = false;
    }

    if (!userForm.first_name) {
      newErrors.first_name = t("Field is required");
      isValid = false;
    }

    if (!userForm.last_name) {
      newErrors.last_name = t("Field is required");
      isValid = false;
    }

    const isPhoneNumberValid = isPhoneNumber(userForm.phone_number);
    if (!isPhoneNumberValid.isValid) {
      newErrors.phone_number = t(isPhoneNumberValid.message);
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Card className="card-height-100">
            <CardHeader>
              <Trans i18nKey="User" />
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label for="first_name">
                      <Trans i18nKey="Name" />
                    </Label>
                    <Input
                      type="text"
                      id="first_name"
                      value={userForm.first_name}
                      onChange={(e) => {
                        setUserForm({
                          ...userForm,
                          first_name: e.target.value,
                        });
                        setErrors({ ...errors, first_name: "" });
                      }}
                      invalid={!!errors.first_name}
                    />
                    {errors.first_name && (
                      <div className="text-danger">{errors.first_name}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="last_name">
                      <Trans i18nKey="Surname" />
                    </Label>
                    <Input
                      type="text"
                      id="last_name"
                      value={userForm.last_name}
                      onChange={(e) => {
                        setUserForm({ ...userForm, last_name: e.target.value });
                        setErrors({ ...errors, last_name: "" });
                      }}
                      invalid={!!errors.last_name}
                    />
                    {errors.last_name && (
                      <div className="text-danger">{errors.last_name}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="email">
                      <Trans i18nKey="Email" />
                    </Label>
                    <Input
                      type="email"
                      id="email"
                      value={userForm.email}
                      onChange={(e) => {
                        setUserForm({ ...userForm, email: e.target.value });
                        setErrors({ ...errors, email: "" });
                      }}
                      invalid={!!errors.email}
                    />
                    {errors.email && (
                      <div className="text-danger">{errors.email}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="phone_number">
                      <Trans i18nKey="Phone Number" />
                    </Label>
                    <Input
                      type="text"
                      id="phone_number"
                      value={userForm.phone_number}
                      onChange={(e) => {
                        const { value } = e.target;
                        const formattedValue = value
                          .replace(/\D/g, "")
                          .slice(2);

                        if (formattedValue.length <= 10) {
                          setUserForm({
                            ...userForm,
                            phone_number: "+90" + formattedValue,
                          });
                          setErrors({ ...errors, phone_number: "" });
                        }
                      }}
                      maxLength={13}
                      invalid={!!errors.phone_number}
                    />
                    {errors.phone_number && (
                      <div className="text-danger">{errors.phone_number}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      id="otp_required"
                      checked={userForm.otp_required}
                      onChange={(e) => {
                        setUserForm({
                          ...userForm,
                          otp_required: e.target.checked,
                        });
                      }}
                    />
                    <Label className="form-check-label" htmlFor="otp_required">
                      <Trans i18nKey="SMS Verification at Login" />
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="text-end">
              <Button
                color="danger"
                className="me-2"
                onClick={toggleDeleteModal}
                hidden={!isEdit}
              >
                <Trans i18nKey="Delete" />
              </Button>
              <Button
                color="primary"
                disabled={isEdit && userStatus !== "succeeded"}
                onClick={handleSave}
              >
                <Trans i18nKey="Save" />
              </Button>
            </CardFooter>
          </Card>
        </Container>
      </div>

      <Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal} centered>
        <ModalHeader toggle={toggleDeleteModal}>
          <Trans i18nKey="Confirm Deletion" />
        </ModalHeader>
        <ModalBody>
          <Trans i18nKey="Are you sure you want to continue with the deletion? This action cannot be undone." />
        </ModalBody>
        <ModalFooter>
          {isEdit && (
            <Button color="secondary" onClick={toggleDeleteModal}>
              <Trans i18nKey="Cancel" />
            </Button>
          )}
          <Button
            color="danger"
            onClick={handleDeleteUser}
            disabled={userDeleteStatus === "loading"}
          >
            <Trans i18nKey="Delete" />
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.User.user,
  userStatus: state.User.userStatus,
  userError: state.User.userError,

  userCreateStatus: state.User.userCreateStatus,
  userCreateError: state.User.userCreateError,
  userUpdateStatus: state.User.userUpdateStatus,
  userUpdateError: state.User.userUpdateError,
  userDeleteStatus: state.User.userDeleteStatus,
  userDeleteError: state.User.userDeleteError,
});

const mapDispatchToProps = (dispact: any) => ({
  getUser: (id: number) => dispact(getUserByIdThunk(id)),
  createUser: (payload: PosPazarTypes.DealerUserCreatePayload) =>
    dispact(createUserThunk(payload)),
  updateUser: (payload: PosPazarTypes.DealerUserUpdatePayload) =>
    dispact(updateUserThunk(payload)),
  deleteUser: (id: number) => dispact(deleteUserThunk(id)),
  resetState: () => {
    dispact(resetUserState());
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withTranslation()(DealerUserEdit));
