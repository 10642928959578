type ValidationType = {
  isValid: boolean;
  message: string;
};

export const isEmail = (email: string): ValidationType => {
  if (!email) return { isValid: false, message: "Email is required" };
  if (!email.trim()) return { isValid: false, message: "Email is required" };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (emailRegex.test(email)) return { isValid: true, message: "" };
  return { isValid: false, message: "Invalid email" };
};

export const isIdentityNumber = (id: string): ValidationType => {
  if (!id) return { isValid: false, message: "Identity number is required" };
  if (id.length !== 11) return { isValid: false, message: "Invalid length" };
  if (!/^\d+$/.test(id))
    return { isValid: false, message: "Invalid characters" };
  const digits = id.split("").map(Number);
  const sumOdd = digits[0] + digits[2] + digits[4] + digits[6] + digits[8];
  const sumEven = digits[1] + digits[3] + digits[5] + digits[7];
  const checkDigit1 = (sumOdd * 7 - sumEven) % 10;
  const checkDigit2 = (sumOdd + sumEven + digits[9]) % 10;
  if (checkDigit1 === digits[9] && checkDigit2 === digits[10])
    return { isValid: true, message: "" };
  return { isValid: false, message: "Invalid identity number" };
};

export const isPhoneNumber = (phone: string): ValidationType => {
  if (!phone) return { isValid: false, message: "Phone number is required" };
  if (!phone.trim())
    return { isValid: false, message: "Phone number is required" };
  if (phone[0] !== "+")
    return { isValid: false, message: "Phone number must start with +" };

  const phoneRegex = /^\+90\d{10}$/;
  if (phoneRegex.test(phone)) return { isValid: true, message: "" };

  return { isValid: false, message: "Invalid phone number" };
};

export const isIBAN = (iban: string, ignoreSpaces: boolean): ValidationType => {
  if (!iban) return { isValid: false, message: "IBAN is required" };
  if (!iban.trim()) return { isValid: false, message: "IBAN is required" };

  if (ignoreSpaces) {
    iban = iban.replace(/\s+/g, "");
  }

  const ibanRegex = /^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/;
  if (!ibanRegex.test(iban))
    return { isValid: false, message: "Invalid IBAN format" };

  const rearrangedIban = iban.slice(4) + iban.slice(0, 4);
  const numericIban = rearrangedIban.replace(/[A-Z]/g, (char) =>
    (char.charCodeAt(0) - 55).toString()
  );

  let remainder = numericIban;
  while (remainder.length > 2) {
    const block = remainder.slice(0, 9);
    remainder =
      (parseInt(block, 10) % 97).toString() + remainder.slice(block.length);
  }

  if (parseInt(remainder, 10) % 97 === 1) return { isValid: true, message: "" };
  return { isValid: false, message: "Invalid IBAN" };
};

export const isFileValid = (file: File): ValidationType => {
  const validExtensions = /\.(jpg|jpeg|pdf)$/i;
  const maxSizeInBytes = 20 * 1024 * 1024; // 20MB

  const fileName = file.name;
  const fileSize = file.size;

  if (!fileName) return { isValid: false, message: "File name is required" };
  if (!validExtensions.test(fileName))
    return { isValid: false, message: "Invalid file extension" };

  if (fileSize > maxSizeInBytes)
    return { isValid: false, message: "File size exceeds 20MB" };

  return { isValid: true, message: "" };
};
