import { combineReducers } from "redux";

import LayoutReducer from "./layouts/reducer";
import AuthReducer from "./auth/authReducer";
import CoreReducer from "./core/coreReducer";
import DashboardReducer from "./dashboard/dashboardReducer";
import DealerReducer from "./dealer/dealerReducer";
import InstallmentReducer from "./installment/installmentReducer";
import PaymentLinkReducer from "./paymentLink/paymentLinkReducer";
import PaymentRequestReducer from "./paymentRequest/paymentRequestReducer";
import PosProviderReducer from "./posprovider/posproviderReducer";
import SubDealerReducer from "./subDealer/subDealerReducer";
import TransactionReducer from "./transaction/transactionReducer";
import UserReducer from "./user/userReducer";

const rootReducer = combineReducers({
  Auth: AuthReducer,
  Core: CoreReducer,
  Dashboard: DashboardReducer,
  Dealer: DealerReducer,
  Installment: InstallmentReducer,
  Layout: LayoutReducer,
  PaymentLink: PaymentLinkReducer,
  PaymentRequest: PaymentRequestReducer,
  PosProvider: PosProviderReducer,
  SubDealer: SubDealerReducer,
  Transaction: TransactionReducer,
  User: UserReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
