import { Container } from "reactstrap";
import SubDealerList from "./SubDealerList";
import { connect } from "react-redux";

const Dealer = () => {
  return (
    <div className="page-content">
      <Container fluid>
        {/* <MainDealer dealer={mainDealer} /> */}
        <SubDealerList />
      </Container>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  list: state.Dealer.list,
});

export default connect(mapStateToProps)(Dealer);
