import { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { RootState } from "slices";
import { getSubDealersThunk } from "slices/thunks";
import CustomTable from "Components/CustomTable";
import { Trans, useTranslation } from "react-i18next";
import { routerPath } from "Routes/allRoutes";

const SubDealerList = (props: PropsFromRedux) => {
  const { subDealers, subDealersStatus, subDealersError, getSubDealers } =
    props;
  const { t } = useTranslation();

  useEffect(() => {
    getSubDealers();
  }, [getSubDealers]);

  const columns = useMemo<PosPazarTypes.CustomTableColumn[]>(() => {
    return [
      { header: t("Name"), accessor: "name" },
      { header: "IBAN", accessor: "iban" },
      {
        header: t("Authorized Person"),
        accessor: "authorized_person_full_name",
      },
      { header: t("City"), accessor: "city_name" },
      { header: t("Township"), accessor: "township_name" },
      {
        header: t("Status"),
        accessor: "status",
        cell(row: PosPazarTypes.SubDealerItem) {
          return row.status === "active" ? t("Active") : t("Inactive");
        },
      },
      {
        header: "",
        accessor: "id",
        cell(row: PosPazarTypes.DealerUserItem) {
          return (
            <Link
              to={routerPath.DealerSubDetail.replace(
                ":dealer_id",
                row.id.toString()
              )}
            >
              {t("Details")}
            </Link>
          );
        },
      },
    ];
  }, [t]);

  return (
    <Card>
      <CardHeader className="d-flex justify-content-between align-items-center">
        <CardTitle>
          <Trans i18nKey="Sub Dealers" />
        </CardTitle>
        <Link to={routerPath.DealerSubCreate} className="btn btn-primary">
          <Trans i18nKey="New" />
        </Link>
      </CardHeader>
      <CardBody>
        <CustomTable
          columns={columns}
          data={subDealers}
          status={subDealersStatus}
          errorTooltip={subDealersError}
        />
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: RootState) => ({
  subDealers: state.SubDealer.subDealers,
  subDealersStatus: state.SubDealer.subDealersStatus,
  subDealersError: state.SubDealer.subDealersError,
});

const mapDispatchToProps = (dispatch: any) => ({
  getSubDealers: () => dispatch(getSubDealersThunk()),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SubDealerList);
