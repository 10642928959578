import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteSubDealer,
  getSubDealers,
  patchSubDealer,
  postSubDealer,
} from "helpers/api/inner_urls";

export const getSubDealersThunk = createAsyncThunk<
  PosPazarTypes.SubDealerItem[],
  void,
  { rejectValue: PosPazarTypes.ApiRequestError }
>("subDealer/getSubDealers", async (_, { rejectWithValue }) => {
  try {
    const response = await getSubDealers();
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message) {
      return rejectWithValue({
        message: error.response.data.message,
        status: error.response.status,
        data: error.response.data,
      });
    }
    return rejectWithValue({
      message: error.message,
      status: error.response.status,
    });
  }
});

export const getSubDealerByIdThunk = createAsyncThunk<
  PosPazarTypes.SubDealerItem,
  number,
  { rejectValue: PosPazarTypes.ApiRequestError }
>("subDealer/getSubDealerById", async (subDealerId, { rejectWithValue }) => {
  try {
    const response = await getSubDealers();
    const subDealer = response.data.find(
      (subDealer) => subDealer.id === subDealerId
    );
    if (subDealer) {
      return subDealer;
    }
    return rejectWithValue({
      message: "Dealer not found",
      status: 404,
    });
  } catch (error: any) {
    if (error?.response?.data?.message) {
      return rejectWithValue({
        message: error.response.data.message,
        status: error.response.status,
        data: error.response.data,
      });
    }
    return rejectWithValue({
      message: error.message,
      status: error.response.status,
    });
  }
});

export const createtSubDealerThunk = createAsyncThunk<
  void,
  PosPazarTypes.SubDealerCreatePayload,
  { rejectValue: PosPazarTypes.ApiRequestError }
>("subDealer/createSubDealer", async (payload, { rejectWithValue }) => {
  try {
    await postSubDealer(payload);
  } catch (error: any) {
    if (error?.response?.data?.message) {
      return rejectWithValue({
        message: error.response.data.message,
        status: error.response.status,
        data: error.response.data,
      });
    }
    return rejectWithValue({
      message: error.message,
      status: error.response.status,
    });
  }
});

export const updateSubDealerThunk = createAsyncThunk<
  void,
  { subDealerId: number; payload: PosPazarTypes.SubDealerCreatePayload },
  { rejectValue: PosPazarTypes.ApiRequestError }
>(
  "subDealer/updateSubDealer",
  async ({ subDealerId, payload }, { rejectWithValue }) => {
    try {
      await patchSubDealer(subDealerId, payload);
    } catch (error: any) {
      if (error?.response?.data?.message) {
        return rejectWithValue({
          message: error.response.data.message,
          status: error.response.status,
          data: error.response.data,
        });
      }
      return rejectWithValue({
        message: error.message,
        status: error.response.status,
      });
    }
  }
);

export const deleteSubDealerThunk = createAsyncThunk<
  void,
  number,
  { rejectValue: PosPazarTypes.ApiRequestError }
>("subDealer/deleteSubDealer", async (subDealerId, { rejectWithValue }) => {
  try {
    await deleteSubDealer(subDealerId);
  } catch (error: any) {
    if (error?.response?.data?.message) {
      return rejectWithValue({
        message: error.response.data.message,
        status: error.response.status,
        data: error.response.data,
      });
    }
    return rejectWithValue({
      message: error.message,
      status: error.response.status,
    });
  }
});
