import { createSlice } from "@reduxjs/toolkit";
import {
  createPaymentLink,
  deletePaymentLink,
  getAllPayments,
  getOnePaymentLink,
  updatePaymentLink,
} from "./paymentLinkThunk";

type StateType = {
  paymentLinks: PosPazarTypes.PaymentLinkItem[];
  paymentLinksStatus: PosPazarTypes.ApiRequestStatus;
  nextPageUrl: string | null;
  prevPageUrl: string | null;
  count: number;
  pageNumber: number;
  paymentLink: PosPazarTypes.PaymentLink | undefined;
  paymentLinkStatus: PosPazarTypes.ApiRequestStatus;
  paymentLinkErrorMessage?: string;
  paymentLinkCreateStatus: PosPazarTypes.ApiRequestStatus;
  paymentLinkCreateError: string | undefined;
  paymentLinkDeleteStatus: PosPazarTypes.ApiRequestStatus;
  paymentLinkDeleteError: string | undefined;
};

export const initialState: StateType = {
  paymentLinks: [],
  paymentLinksStatus: "idle",
  nextPageUrl: null,
  prevPageUrl: null,
  count: 0,
  pageNumber: 1,
  paymentLink: undefined,
  paymentLinkStatus: "idle",
  paymentLinkErrorMessage: undefined,
  paymentLinkCreateStatus: "idle",
  paymentLinkCreateError: undefined,
  paymentLinkDeleteStatus: "idle",
  paymentLinkDeleteError: undefined,
};

const paymentLinkSlice = createSlice({
  name: "paymentLink",
  initialState,
  reducers: {
    resetPatmentLinkDeleteStatus: (state) => {
      state.paymentLinkDeleteStatus = "idle";
    },
    resetPaymentLinkThunk: (state) => {
      state.paymentLink = undefined;
      state.paymentLinkStatus = "idle";
      state.paymentLinkCreateStatus = "idle";
      state.paymentLinkCreateError = undefined;
      state.paymentLinkDeleteStatus = "idle";
      state.paymentLinkDeleteError = undefined;
      state.paymentLinkStatus = "idle";
      state.paymentLinkErrorMessage = undefined;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllPayments.fulfilled, (state, action) => {
        const { results, next, previous, count } = action.payload;
        state.paymentLinks = results;
        state.nextPageUrl = next;
        state.prevPageUrl = previous;
        state.count = count;
        let previousPageNumber = 0;
        try {
          const previousPageUrl = new URL(previous ?? "");
          const previousPage = previousPageUrl.searchParams.get("page");
          if (previousPage) {
            previousPageNumber = Number(previousPage);
          } else {
            previousPageNumber = 1;
          }
        } catch {
          previousPageNumber = 0;
        }
        state.pageNumber = previousPageNumber + 1;
        state.paymentLinksStatus = "succeeded";
      })
      .addCase(getAllPayments.pending, (state) => {
        state.paymentLinksStatus = "loading";
        state.paymentLinks = [];
      })
      .addCase(getAllPayments.rejected, (state, action) => {
        state.paymentLinksStatus = "failed";
        state.paymentLinks = [];
      });
    builder
      .addCase(getOnePaymentLink.fulfilled, (state, action) => {
        state.paymentLink = action.payload;
        state.paymentLinkStatus = "succeeded";
        state.paymentLinkErrorMessage = undefined;
      })
      .addCase(getOnePaymentLink.pending, (state) => {
        state.paymentLinkStatus = "loading";
        state.paymentLink = undefined;
        state.paymentLinkErrorMessage = undefined;
      })
      .addCase(getOnePaymentLink.rejected, (state, action) => {
        state.paymentLinkStatus = "failed";
        state.paymentLink = undefined;
        state.paymentLinkErrorMessage = action.payload?.message;
      });
    builder
      .addCase(createPaymentLink.fulfilled, (state, action) => {
        state.paymentLinkCreateStatus = "succeeded";
        state.paymentLinkCreateError = undefined;
      })
      .addCase(createPaymentLink.rejected, (state, action) => {
        state.paymentLinkCreateStatus = "failed";
        state.paymentLinkCreateError = action.error.message;
      })
      .addCase(createPaymentLink.pending, (state) => {
        state.paymentLinkCreateStatus = "loading";
        state.paymentLinkCreateError = undefined;
      });
    builder
      .addCase(updatePaymentLink.fulfilled, (state, action) => {
        state.paymentLinkCreateStatus = "succeeded";
        state.paymentLinkCreateError = undefined;
      })
      .addCase(updatePaymentLink.rejected, (state, action) => {
        state.paymentLinkCreateStatus = "failed";
        state.paymentLinkCreateError = action.error.message;
      })
      .addCase(updatePaymentLink.pending, (state) => {
        state.paymentLinkCreateStatus = "loading";
        state.paymentLinkCreateError = undefined;
      });
    builder
      .addCase(deletePaymentLink.fulfilled, (state, action) => {
        state.paymentLinks = state.paymentLinks.filter(
          (item) => item.uuid !== action.payload
        );
        state.paymentLinkDeleteStatus = "succeeded";
        state.paymentLink = undefined;
        state.paymentLinkDeleteError = undefined;
        if (state.count > 0) {
          state.count -= 1;
        }
      })
      .addCase(deletePaymentLink.pending, (state) => {
        state.paymentLinkDeleteStatus = "loading";
        state.paymentLinkDeleteError = undefined;
      })
      .addCase(deletePaymentLink.rejected, (state, action) => {
        state.paymentLinkDeleteStatus = "failed";
        state.paymentLinkDeleteError = action.error.message;
      });
  },
});

export const { resetPatmentLinkDeleteStatus, resetPaymentLinkThunk } =
  paymentLinkSlice.actions;
export default paymentLinkSlice.reducer;
