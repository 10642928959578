import React, { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Row, Spinner, Table, Tooltip } from "reactstrap";

interface CustomTableProps {
  data: Array<{ [key: string]: any }>;
  columns: PosPazarTypes.CustomTableColumn[];
  striped?: boolean;
  bordered?: boolean;
  hover?: boolean;
  responsive?: boolean;
  totalData?: number;
  pageNumber?: number;
  status?: PosPazarTypes.ApiRequestStatus;
  errorTooltip?: string;
  onPageChange?: (pageNumber: number) => void;
}

const CustomTable: React.FC<CustomTableProps> = (props) => {
  const {
    data,
    columns,
    striped = false,
    bordered = false,
    hover = false,
    responsive = true,
    pageNumber = 1,
    totalData = 0,
    status,
    errorTooltip,
    onPageChange,
  } = props;
  const { t } = useTranslation();
  const pageSize = useMemo(
    () => Math.max(1, Math.ceil(totalData / 10)),
    [totalData]
  );

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      {status === "loading" && <Spinner color="primary" />}
      {status === "failed" && (
        <div>
          <i
            id="CustomTableErrorTooltip"
            className="ri-error-warning-fill text-danger"
            color="red"
          />
          <Tooltip
            isOpen={tooltipOpen}
            target="CustomTableErrorTooltip"
            toggle={toggleTooltip}
          >
            <Trans i18nKey={errorTooltip ?? "Unknown error occurred"} />
          </Tooltip>
        </div>
      )}
      <Table
        striped={striped}
        bordered={bordered}
        hover={hover}
        responsive={responsive}
      >
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column.header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column, colIndex) => (
                <td key={colIndex}>
                  {column.cell ? column.cell(row) : row[column.accessor]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      {onPageChange && (
        <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
          <div className="col-sm">
            <div className="text-muted">
              <Trans
                i18nKey="Showing {{partitionCount}} out of {{totalCount}}"
                values={{ partitionCount: data.length, totalCount: totalData }}
                components={{
                  partitionCount: <span className="fw-semibold ms-1" />,
                  totalCount: <span className="fw-semibold" />,
                }}
              />
            </div>
          </div>
          <div className="col-sm-auto">
            <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
              <li
                className={
                  pageNumber === 1 ? "page-item disabled" : "page-item"
                }
              >
                <Link
                  to="#"
                  className="page-link"
                  onClick={() => onPageChange(pageNumber - 1)}
                >
                  {t("Previous")}
                </Link>
              </li>
              {Array.from({ length: pageSize }, (_, i) => i + 1).map((nmr) => (
                <li key={`pageNumber-${nmr}`} className="page-item">
                  <Link
                    to="#"
                    className={
                      pageNumber === nmr ? "page-link active" : "page-link"
                    }
                    onClick={() => onPageChange(nmr)}
                  >
                    {nmr}
                  </Link>
                </li>
              ))}
              <li
                className={
                  pageNumber === pageSize ? "page-item disabled" : "page-item"
                }
              >
                <Link
                  to="#"
                  className="page-link"
                  onClick={() => onPageChange(pageNumber + 1)}
                >
                  {t("Next")}
                </Link>
              </li>
            </ul>
          </div>
        </Row>
      )}
    </>
  );
};

export default CustomTable;
