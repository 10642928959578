import { createSlice } from "@reduxjs/toolkit";
import {
  changePasswordThunk,
  getCurrenciesRates,
  getDashboardInfo,
  getUserInfo,
} from "./dashboardThunk";

type StateType = {
  userInfo: PosPazarTypes.UserInfoReducer | undefined;
  userInfoStatus: PosPazarTypes.ApiRequestStatus;
  userInfoError: string | undefined;
  dashboardInfo: PosPazarTypes.DashboardReducer | undefined;
  dashboardStatus: PosPazarTypes.ApiRequestStatus;
  dashboardError: string | undefined;
  currencyRates: PosPazarTypes.CurrencyRate[];
  currencyRatesStatus: PosPazarTypes.ApiRequestStatus;
  currencyRatesError: string | null;

  changePasswordStatus: PosPazarTypes.ApiRequestStatus;
  changePasswordError: string | undefined;
};
const initialState: StateType = {
  userInfo: undefined,
  userInfoStatus: "idle",
  userInfoError: undefined,
  dashboardInfo: undefined,
  dashboardStatus: "idle",
  dashboardError: undefined,
  currencyRates: [],
  currencyRatesStatus: "idle",
  currencyRatesError: null,
  changePasswordStatus: "idle",
  changePasswordError: undefined,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetPasswordReducer: (state) => {
      state.changePasswordStatus = "idle";
      state.changePasswordError = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.userInfo = action.payload;
        state.userInfoStatus = "succeeded";
      })
      .addCase(getUserInfo.pending, (state) => {
        state.userInfoStatus = "loading";
        state.userInfoError = undefined;
        state.userInfo = undefined;
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.userInfoStatus = "failed";
        state.userInfoError = action.payload?.message;
      });
    builder
      .addCase(getDashboardInfo.fulfilled, (state, action) => {
        state.dashboardStatus = "succeeded";
        state.dashboardInfo = action.payload;
      })
      .addCase(getDashboardInfo.pending, (state) => {
        state.dashboardStatus = "loading";
        state.dashboardInfo = undefined;
        state.dashboardError = undefined;
      })
      .addCase(getDashboardInfo.rejected, (state, action) => {
        state.dashboardStatus = "failed";
        state.dashboardError = action.error.message;
      });
    builder
      .addCase(getCurrenciesRates.fulfilled, (state, action) => {
        state.currencyRates = action.payload;
        state.currencyRatesStatus = "succeeded";
      })
      .addCase(getCurrenciesRates.pending, (state) => {
        state.currencyRatesStatus = "loading";
        state.currencyRatesError = null;
      })
      .addCase(getCurrenciesRates.rejected, (state, action) => {
        state.currencyRatesStatus = "failed";
        state.currencyRatesError = action.error.message ?? null;
      });
    builder
      .addCase(changePasswordThunk.pending, (state) => {
        state.changePasswordStatus = "loading";
        state.changePasswordError = undefined;
      })
      .addCase(changePasswordThunk.fulfilled, (state) => {
        state.changePasswordStatus = "succeeded";
      })
      .addCase(changePasswordThunk.rejected, (state, action) => {
        state.changePasswordStatus = "failed";
        state.changePasswordError = action.payload?.message;
      });
  },
});

export const { resetPasswordReducer } = dashboardSlice.actions;
export default dashboardSlice.reducer;
