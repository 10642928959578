export function mapTransactionType(
  type?: PosPazarTypes.TransactionRelatedType
) {
  switch (type) {
    case "manuelpayment":
      return "Manuel Payment";
    case "linkpayment":
      return "Payment Link";
    case "refund":
      return "Refund";
    default:
      return type;
  }
}
