import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthorization } from "helpers/api/inner_api";
import {
  forgotPassword,
  requestOtpLogin,
  resetPassword,
  setBlackList,
  verifyOtpLogin,
} from "helpers/api/inner_urls";
import { resetAuthState } from "./authReducer";

export const loginOtpThunk = createAsyncThunk<
  PosPazarTypes.LoginOtpResponse | PosPazarTypes.LoginNoOtpResponse,
  PosPazarTypes.LoginPaylod,
  { rejectValue: PosPazarTypes.ApiRequestError }
>("auth/requestOtp", async (user, { rejectWithValue }) => {
  try {
    const { data } = await requestOtpLogin(user);
    if (!data.otp) {
      const _data = { ...data } as any;
      delete _data.otp;
      setAuthorization(_data.access);
      localStorage.setItem("authUser", JSON.stringify(_data));
    }
    return data;
  } catch (error: any) {
    if (error?.response?.data?.message) {
      return rejectWithValue({
        message: error.response.data.message,
        status: error.response.status,
        data: error.response.data,
      });
    }
    return rejectWithValue({
      message: error.message,
      status: error.response.status,
    });
  }
});

export const loginVerifyOtpThunk = createAsyncThunk<
  void,
  { token: string; otp_code: string },
  { rejectValue: PosPazarTypes.ApiRequestError }
>("auth/verifyOtp", async (body, { rejectWithValue }) => {
  try {
    const { data } = await verifyOtpLogin(body);
    setAuthorization(data.access);
    localStorage.setItem("authUser", JSON.stringify(data));
  } catch (error: any) {
    if (error?.response?.data?.error) {
      return rejectWithValue({
        message: error.response.data.error,
        status: error.response.status,
        data: error.response.data,
      });
    }
    return rejectWithValue({
      message: error.message,
      status: error.response.status,
    });
  }
});

export const logoutThunk = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch }) => {
    dispatch(resetAuthState());
    const tokens = localStorage.getItem("authUser");
    const { refresh } = tokens ? JSON.parse(tokens) : null;
    localStorage.removeItem("authUser");
    if (refresh) {
      await setBlackList({ refresh });
    }
  }
);

export const forgotPasswordThunk = createAsyncThunk<
  { detail: String; token: String },
  String,
  { rejectValue: PosPazarTypes.ApiRequestError }
>("auth/forgotPassword", async (email, { rejectWithValue }) => {
  try {
    const { data } = await forgotPassword({
      email,
    });
    return data;
  } catch (error: any) {
    if (error?.response?.data?.non_field_errors) {
      return rejectWithValue({
        message: error.response.data.non_field_errors,
        status: error.response.status,
        data: error.response.data,
      });
    }
    return rejectWithValue({
      message: error.message,
      status: error.response.status,
    });
  }
});

export const resetPasswordThunk = createAsyncThunk<
  String,
  PosPazarTypes.ResetPasswordPayload,
  { rejectValue: PosPazarTypes.ApiRequestError }
>("auth/resetPassword", async (body, { rejectWithValue }) => {
  try {
    const { data } = await resetPassword(body);
    return data.detail;
  } catch (error: any) {
    if (error?.response?.data?.non_field_errors) {
      return rejectWithValue({
        message: error.response.data.non_field_errors,
        status: error.response.status,
        data: error.response.data,
      });
    }
    return rejectWithValue({
      message: error.message,
      status: error.response.status,
    });
  }
});
