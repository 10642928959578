import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCurrenciesRatesReq } from "helpers/api/external_urls";
import {
  getDashboardData,
  getMe,
  patchChangePassword,
} from "helpers/api/inner_urls";
import { normalizeCurrencyData } from "helpers/data/customCurrencyNormalization";

export const getUserInfo = createAsyncThunk<
  PosPazarTypes.UserInfoReducer,
  void,
  { rejectValue: PosPazarTypes.ApiRequestError }
>("dashboard/getUserInfo", async (_, { rejectWithValue }) => {
  try {
    const { data } = await getMe();
    return data;
  } catch (error: any) {
    if (error?.response?.data?.message) {
      return rejectWithValue({
        message: error.response.data.message,
        status: error.response.status,
        data: error.response.data,
      });
    }
    return rejectWithValue({
      message: error.message,
      status: error.response.status,
    });
  }
});

export const getDashboardInfo = createAsyncThunk(
  "dashboard/getDashboardInfo",
  async () => {
    const { data } = await getDashboardData();
    return data;
  }
);

export const getCurrenciesRates = createAsyncThunk(
  "dashboard/getCurrenciesRates",
  async () => {
    const { data } = await getCurrenciesRatesReq();
    return normalizeCurrencyData(data);
  }
);

export const changePasswordThunk = createAsyncThunk<
  void,
  PosPazarTypes.ChangePasswordPayload,
  { rejectValue: PosPazarTypes.ApiRequestError }
>("dashboard/changePassword", async (body, { rejectWithValue }) => {
  try {
    await patchChangePassword(body);
  } catch (error: any) {
    if (error?.response?.data?.message) {
      return rejectWithValue({
        message: error.response.data.message,
        status: error.response.status,
        data: error.response.data,
      });
    }
    return rejectWithValue({
      message: error.message,
      status: error.response.status,
    });
  }
});
