import { createSlice } from "@reduxjs/toolkit";
import {
  logoutThunk,
  loginOtpThunk,
  loginVerifyOtpThunk,
  forgotPasswordThunk,
  resetPasswordThunk,
} from "./authThunk";

type StateType = {
  loginStatus: PosPazarTypes.ApiRequestStatus;
  loginErrorMessage: string | undefined;
  isUserLogout: boolean;
  forgotPasswordStatus: PosPazarTypes.ApiRequestStatus;
  forgotPasswordErrorMessage?: string;
  forgotPasswordToken?: String;
  resetPasswordStatus: PosPazarTypes.ApiRequestStatus;
  resetPasswordErrorMessage?: string;

  loginHasOtp: "unknown" | "yes" | "no";
  loginOtpStatus: PosPazarTypes.ApiRequestStatus;
  otpToken: string;
  verifyOtpErrorMessage?: string;
};

export const initialState: StateType = {
  loginStatus: "idle",
  loginErrorMessage: undefined,
  isUserLogout: false,
  forgotPasswordStatus: "idle",
  forgotPasswordErrorMessage: undefined,
  forgotPasswordToken: undefined,
  resetPasswordStatus: "idle",
  resetPasswordErrorMessage: undefined,

  loginHasOtp: "unknown",
  loginOtpStatus: "idle",
  otpToken: "",
  verifyOtpErrorMessage: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuthState: () => initialState,
    resetOtp: (state: StateType) => {
      state.loginHasOtp = "unknown";
      state.loginOtpStatus = "idle";
      state.otpToken = "";
      state.verifyOtpErrorMessage = undefined;
    },
  },
  extraReducers(builder) {
    builder.addCase(logoutThunk.fulfilled, (state) => {
      state.isUserLogout = true;
    });
    builder
      .addCase(loginOtpThunk.pending, (state) => {
        state.loginHasOtp = "unknown";
        state.loginStatus = "loading";
        state.loginOtpStatus = "loading";
        state.otpToken = "";
      })
      .addCase(loginOtpThunk.fulfilled, (state, action) => {
        if (action.payload.otp) {
          state.loginHasOtp = "yes";
          state.loginErrorMessage = "";
          state.loginOtpStatus = "succeeded";
          state.loginStatus = "idle";
          state.otpToken = action.payload.token;
        } else {
          state.loginHasOtp = "no";
          state.loginOtpStatus = "idle";
          state.loginStatus = "succeeded";
        }
      })
      .addCase(loginOtpThunk.rejected, (state, action) => {
        state.loginHasOtp = "unknown";
        state.loginStatus = "failed";
        state.loginErrorMessage = action.payload?.message;
        state.otpToken = "";
      });
    builder
      .addCase(loginVerifyOtpThunk.fulfilled, (state) => {
        state.loginStatus = "succeeded";
        state.loginErrorMessage = undefined;
        state.loginOtpStatus = "idle";
        state.verifyOtpErrorMessage = undefined;
      })
      .addCase(loginVerifyOtpThunk.rejected, (state, action) => {
        state.loginStatus = "failed";
        state.loginOtpStatus = "idle";
        state.verifyOtpErrorMessage = action.payload?.message;
      })
      .addCase(loginVerifyOtpThunk.pending, (state) => {
        state.loginStatus = "loading";
        state.loginErrorMessage = undefined;
        state.verifyOtpErrorMessage = undefined;
      });
    builder
      .addCase(forgotPasswordThunk.pending, (state) => {
        state.forgotPasswordStatus = "loading";
        state.forgotPasswordErrorMessage = undefined;
        state.forgotPasswordToken = undefined;
      })
      .addCase(forgotPasswordThunk.fulfilled, (state, action) => {
        state.forgotPasswordStatus = "succeeded";
        state.forgotPasswordErrorMessage = undefined;
        state.forgotPasswordToken = action.payload.token;
      })
      .addCase(forgotPasswordThunk.rejected, (state, action) => {
        state.forgotPasswordStatus = "failed";
        state.forgotPasswordErrorMessage = action.payload?.message;
        state.forgotPasswordToken = undefined;
      });
    builder
      .addCase(resetPasswordThunk.pending, (state) => {
        state.resetPasswordStatus = "loading";
        state.resetPasswordErrorMessage = undefined;
      })
      .addCase(resetPasswordThunk.fulfilled, (state) => {
        state.resetPasswordStatus = "succeeded";
        state.resetPasswordErrorMessage = undefined;
      })
      .addCase(resetPasswordThunk.rejected, (state, action) => {
        state.resetPasswordStatus = "failed";
        state.resetPasswordErrorMessage = action.payload?.message;
      });
  },
});
export const { resetAuthState, resetOtp } = authSlice.actions;
export default authSlice.reducer;
