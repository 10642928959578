import { connect, ConnectedProps } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { RootState } from "slices";
import classnames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  getAllCities,
  getAllProviders,
  getAllTownships,
  getInstallmentOptions,
  new3dPayment,
} from "slices/thunks";
import CreditCard from "Components/CreditCard";
import {
  isEmail,
  isIdentityNumber,
  isPhoneNumber,
} from "helpers/data/validate";
import { toast } from "react-toastify";
import ProviderSelect from "Components/ProviderSelect";
import { useTranslation } from "react-i18next";
import { resetInstallmentState } from "slices/installment/installmentReducer";
import { resetPaymentState } from "slices/posprovider/posproviderReducer";

const ManuelPayment = (props: PropsFromRedux) => {
  const {
    cities,
    townships,
    providers,
    installments,
    installmentsStatus,
    installementsError,
    fetchCities,
    fetchProviders,
    fetchTownships,
    fetchInstallments,
    requestPayment,
    paymentStatus,
    paymentError,
    resetState,
  } = props;
  const { t } = useTranslation();

  const [activeArrowTab, setactiveArrowTab] = useState<number>(1);
  const [formData, setFormData] = useState({
    provider: "",
    amount: "",
    currency: "TRY",
    first_name: "",
    last_name: "",
    identity_number: "",
    email: "",
    phone_number: "+90",
    city_id: 0,
    township_id: 0,
    address: "",
    description: "",
    card_no: "",
    card_holder_name: "",
    expire_date: "",
    cvv: "",
    installment: 0,
    commission: false,
  });
  const [cardInfoIsValid, setCardInfoIsValid] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const refAmountPercent = useRef<HTMLInputElement>(null);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, [resetState]);

  useEffect(() => {
    fetchProviders();
    fetchCities();
  }, [fetchCities, fetchProviders]);

  useEffect(() => {
    if (providers.length) {
      setFormData((prev) => ({
        ...prev,
        provider: providers[0].key,
      }));
    }
  }, [providers]);

  useEffect(() => {
    if (cities.length) {
      setFormData((prev) => ({
        ...prev,
        city_id: cities[0].id,
      }));
    }
  }, [cities]);

  useEffect(() => {
    if (townships.length) {
      setFormData((prev) => ({
        ...prev,
        township_id: townships[0].id,
      }));
    }
  }, [townships]);

  useEffect(() => {
    if (formData.city_id) {
      fetchTownships(formData.city_id);
    }
  }, [fetchTownships, formData.city_id]);

  useEffect(() => {
    if (
      formData.provider &&
      Number(formData.amount) > 0 &&
      formData.card_no.length === 16
    ) {
      const binNumber = formData.card_no.substring(0, 6);
      fetchInstallments(
        formData.provider,
        Number(formData.amount),
        binNumber,
        formData.commission
      );
    }
  }, [
    fetchInstallments,
    formData.provider,
    formData.amount,
    formData.card_no,
    formData.commission,
  ]);

  useEffect(() => {
    switch (paymentStatus) {
      case "succeeded":
        toast.success(t("You are being redirected to the payment page..."));
        break;
      case "failed":
        toast.error(paymentError || t("Payment failed."));
        break;
      case "loading":
        toast.info(t("Payment request sent, please wait..."));
        break;
      default:
        break;
    }
  }, [paymentStatus, paymentError, t]);

  useEffect(() => {
    if (installmentsStatus === "failed") {
      toast.error(installementsError || t("Unknown error occurred"), {
        autoClose: 5000,
      });
    }
  }, [installmentsStatus, installementsError, t]);

  const validateCustomerInfo = useCallback(() => {
    const _errors: any = {};
    if (!formData.provider) {
      _errors.provider = t("This field cannot be left blank");
    }
    if (!formData.amount) {
      _errors.amount = t("This field cannot be left blank");
    } else if (isNaN(Number(formData.amount)) || Number(formData.amount) <= 0) {
      _errors.amount = t("Amount must be greater than 0");
    }
    if (!formData.first_name) {
      _errors.first_name = t("This field cannot be left blank");
    }
    if (!formData.last_name) {
      _errors.last_name = t("This field cannot be left blank");
    }
    const isIdentityNumberValid = isIdentityNumber(formData.identity_number);
    if (!isIdentityNumberValid.isValid) {
      _errors.identity_number = t(isIdentityNumberValid.message);
    }
    const isEmailValid = isEmail(formData.email);
    if (!isEmailValid.isValid) {
      _errors.email = t(isEmailValid.message);
    }

    const isPhoneNumberValid = isPhoneNumber(formData.phone_number);
    if (!isPhoneNumberValid.isValid) {
      _errors.phone_number = t(isPhoneNumberValid.message);
    }
    if (!formData.city_id) {
      _errors.city_id = t("This field cannot be left blank");
    }
    if (!formData.township_id) {
      _errors.township_id = t("This field cannot be left blank");
    }
    if (!formData.address) {
      _errors.address = t("This field cannot be left blank");
    }
    return _errors;
  }, [
    formData.address,
    formData.amount,
    formData.city_id,
    formData.email,
    formData.identity_number,
    formData.phone_number,
    formData.provider,
    formData.township_id,
    formData.first_name,
    formData.last_name,
    t,
  ]);

  function toggleArrowTab(tab: number) {
    if (activeArrowTab !== tab && tab <= 3 && tab > 0) {
      if (activeArrowTab > tab) {
        setactiveArrowTab(tab);
        return;
      }
      const customerInfoErrors = validateCustomerInfo();
      if (
        activeArrowTab === 1 &&
        Object.keys(customerInfoErrors).length === 0
      ) {
        setactiveArrowTab(tab);
      } else if (
        activeArrowTab === 1 &&
        Object.keys(customerInfoErrors).length > 0
      ) {
        setErrors(customerInfoErrors);
      } else if (activeArrowTab === 2 && cardInfoIsValid) {
        setactiveArrowTab(tab);
      }
    }
  }

  function handleSubmit() {
    if (formData.installment !== 0) {
      const expiredDate = formData.expire_date.split("/");
      const body: PosPazarTypes.ThreeDPayload = {
        card_holder_name: formData.card_holder_name,
        card_number: formData.card_no,
        card_expiration_month: Number(expiredDate[0]),
        card_expiration_year: Number("20" + expiredDate[1]),
        card_cvv: formData.cvv,
        amount: Number(formData.amount),
        installment: formData.installment,
        description: formData.description,
        customer_first_name: formData.first_name,
        customer_last_name: formData.last_name,
        customer_city_id: formData.city_id,
        customer_township_id: formData.township_id,
        customer_identification_number: formData.identity_number,
        reflect_cost: formData.commission,
        customer_address: formData.address,
        customer_email: formData.email,
        customer_phone_number: formData.phone_number,
      };
      requestPayment(formData.provider, body);
    } else {
      toast.error(t("Please select an installment option"));
    }
  }
  return (
    <div className="page-content">
      <Container fluid>
        <Card className="card-height-100">
          <CardHeader>{t("Manual Payment")}</CardHeader>
          <CardBody>
            <div className="step-arrow-nav mb-4">
              <Nav
                className="nav-pills custom-nav nav-justified"
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    href="#"
                    id="steparrow-gen-info-tab"
                    className={classnames({
                      active: activeArrowTab === 1,
                      done: activeArrowTab > 1,
                    })}
                    onClick={() => {
                      toggleArrowTab(1);
                    }}
                  >
                    {t("Customer Info")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    id="steparrow-gen-info-tab"
                    className={classnames({
                      active: activeArrowTab === 2,
                      done: activeArrowTab > 2,
                    })}
                    onClick={() => {
                      toggleArrowTab(2);
                    }}
                  >
                    {t("Card Info")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    id="steparrow-gen-info-tab"
                    className={classnames({
                      active: activeArrowTab === 3,
                      done: activeArrowTab > 3,
                    })}
                    onClick={() => {
                      toggleArrowTab(3);
                    }}
                  >
                    {t("Installment Options")}
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <TabContent activeTab={activeArrowTab}>
              <TabPane id="steparrow-gen-info" tabId={1}>
                <Row>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label for="provider">{t("POS Provider")}</Label>
                      <ProviderSelect
                        providers={providers}
                        selected={formData.provider}
                        onChange={(val) => {
                          setErrors((prev: any) => {
                            delete prev.provider;
                            return prev;
                          });
                          setFormData((prev) => ({
                            ...prev,
                            provider: val,
                          }));
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="currency">
                        {t("Currency")}
                      </Label>
                      <Input
                        type="select"
                        className="form-select"
                        id="currency"
                        name="currency"
                        disabled={!formData.provider}
                        value={formData.currency}
                        onChange={(e) => {
                          setErrors((prev: any) => {
                            delete prev.currency;
                            return prev;
                          });
                          setFormData((prev) => ({
                            ...prev,
                            currency: e.target.value,
                          }));
                        }}
                        invalid={!!errors.currency}
                      >
                        <option value="TRY" hidden>
                          TRY
                        </option>
                      </Input>
                      {errors.currency && (
                        <FormFeedback>{errors.currency}</FormFeedback>
                      )}
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="amount">
                        {t("Amount")}
                      </Label>
                      <InputGroup>
                        <Input
                          type="number"
                          style={{ textAlign: "right", flex: 5 }}
                          value={formData.amount.split(".")[0]}
                          onKeyDown={(e) => {
                            if (e.key === "." || e.key === ",") {
                              e.preventDefault();
                              refAmountPercent.current?.focus();
                            } else if (e.key === "e") {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, "");
                            setErrors((prev: any) => {
                              delete prev.amount;
                              return prev;
                            });
                            setFormData((prev) => ({
                              ...prev,
                              amount: `${value}.${
                                formData.amount.split(".")[1] || "00"
                              }`,
                            }));
                          }}
                          invalid={!!errors.amount}
                        />
                        <Input
                          innerRef={refAmountPercent}
                          type="number"
                          style={{ textAlign: "left", width: 1 }}
                          value={formData.amount.split(".")[1] || "00"}
                          onKeyDown={(e) => {
                            if (e.key === "e" || e.key === ",") {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            const value = e.target.value
                              .replace(/[^0-9]/g, "")
                              .padStart(2, "0")
                              .slice(-2);
                            setErrors((prev: any) => {
                              delete prev.amount;
                              return prev;
                            });
                            setFormData((prev) => ({
                              ...prev,
                              amount: `${
                                formData.amount.split(".")[0]
                              }.${value}`,
                            }));
                          }}
                        />
                      </InputGroup>
                      {errors.amount && (
                        <FormFeedback id="amountFeedback" className="d-block">
                          {errors.amount}
                        </FormFeedback>
                      )}
                    </div>
                  </Col>
                  <Col lg="2" className="d-flex align-items-center">
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="includeRate"
                        checked={formData.commission}
                        onChange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            commission: e.target.checked === true,
                          }));
                        }}
                      />
                      <Label className="form-check-label" htmlFor="includeRate">
                        {t("Include rate in amount")}
                      </Label>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="identity_number">
                        {t("Identity Number")}
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="identity_number"
                        name="identity_number"
                        placeholder={t("Identity Number")}
                        disabled={!formData.provider}
                        value={formData.identity_number}
                        onChange={(e) => {
                          const { value } = e.target;
                          const formattedValue = value.replace(/\D/g, "");
                          if (formattedValue.length <= 11) {
                            setErrors((prev: any) => {
                              delete prev.identity_number;
                              return prev;
                            });
                            setFormData((prev) => ({
                              ...prev,
                              identity_number: formattedValue,
                            }));
                          }
                        }}
                        maxLength={11}
                        invalid={!!errors.identity_number}
                      />
                      {errors.identity_number && (
                        <FormFeedback>{errors.identity_number}</FormFeedback>
                      )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="first_name">
                        {t("Name")}
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="first_name"
                        name="first_name"
                        placeholder={t("Name")}
                        disabled={!formData.provider}
                        value={formData.first_name}
                        onChange={(e) => {
                          setErrors((prev: any) => {
                            delete prev.first_name;
                            return prev;
                          });
                          setFormData((prev) => ({
                            ...prev,
                            first_name: e.target.value,
                          }));
                        }}
                        invalid={!!errors.first_name}
                      />
                      {errors.first_name && (
                        <FormFeedback>{errors.first_name}</FormFeedback>
                      )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="last_name">
                        {t("Surname")}
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="last_name"
                        name="last_name"
                        placeholder={t("Surname")}
                        disabled={!formData.provider}
                        value={formData.last_name}
                        onChange={(e) => {
                          setErrors((prev: any) => {
                            delete prev.last_name;
                            return prev;
                          });
                          setFormData((prev) => ({
                            ...prev,
                            last_name: e.target.value,
                          }));
                        }}
                        invalid={!!errors.last_name}
                      />
                      {errors.last_name && (
                        <FormFeedback>{errors.last_name}</FormFeedback>
                      )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="email">
                        {t("Email")}
                      </Label>
                      <Input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder={t("Email")}
                        disabled={!formData.provider}
                        value={formData.email}
                        onChange={(e) => {
                          setErrors((prev: any) => {
                            delete prev.email;
                            return prev;
                          });
                          setFormData((prev) => ({
                            ...prev,
                            email: e.target.value,
                          }));
                        }}
                        invalid={!!errors.email}
                      />
                      {errors.email && (
                        <FormFeedback>{errors.email}</FormFeedback>
                      )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="phone_number">
                        {t("Phone Number")}
                      </Label>
                      <Input
                        type="tel"
                        className="form-control"
                        id="phone_number"
                        name="phone_number"
                        placeholder={t("Phone Number")}
                        disabled={!formData.provider}
                        value={formData.phone_number}
                        onChange={(e) => {
                          const { value } = e.target;
                          const formattedValue = value
                            .replace(/\D/g, "")
                            .slice(2);
                          if (formattedValue.length <= 10) {
                            setErrors((prev: any) => {
                              delete prev.phone_number;
                              return prev;
                            });
                            setFormData((prev) => ({
                              ...prev,
                              phone_number: "+90" + formattedValue,
                            }));
                          }
                        }}
                        maxLength={13}
                        invalid={!!errors.phone_number}
                      />
                      {errors.phone_number && (
                        <FormFeedback>{errors.phone_number}</FormFeedback>
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="cityId">{t("City")}</Label>
                      <Input
                        type="select"
                        id="cityId"
                        name="cityId"
                        value={formData.city_id}
                        onChange={(e) => {
                          setErrors((prev: any) => {
                            delete prev.city_id;
                            return prev;
                          });
                          setFormData((prev) => ({
                            ...prev,
                            city_id: Number(e.target.value),
                          }));
                        }}
                        invalid={Boolean(errors.city_id)}
                      >
                        <option value="0" hidden>
                          {t("Select City")}
                        </option>
                        {cities.map((city: any) => (
                          <option key={city.id} value={city.id}>
                            {city.name}
                          </option>
                        ))}
                      </Input>
                      {errors.city_id && (
                        <div className="text-danger">{errors.city_id}</div>
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="townshipId">{t("Township")}</Label>
                      <Input
                        type="select"
                        id="townshipId"
                        name="townshipId"
                        value={formData.township_id}
                        onChange={(e) => {
                          setErrors((prev: any) => {
                            delete prev.township_id;
                            return prev;
                          });
                          setFormData((prev) => ({
                            ...prev,
                            township_id: Number(e.target.value),
                          }));
                        }}
                        disabled={!formData.city_id}
                        invalid={Boolean(errors.township_id)}
                      >
                        <option value="0" hidden>
                          {t("Select Township")}
                        </option>
                        {townships.map((township: any) => (
                          <option key={township.id} value={township.id}>
                            {township.name}
                          </option>
                        ))}
                      </Input>
                      {errors.township_id && (
                        <div className="text-danger">{errors.township_id}</div>
                      )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="address">
                        {t("Address")}
                      </Label>
                      <Input
                        type="textarea"
                        className="form-control"
                        placeholder={t("Address")}
                        id="address"
                        name="address"
                        rows={3}
                        disabled={!formData.provider}
                        value={formData.address}
                        onChange={(e) => {
                          setErrors((prev: any) => {
                            delete prev.address;
                            return prev;
                          });
                          setFormData((prev) => ({
                            ...prev,
                            address: e.target.value,
                          }));
                        }}
                        invalid={!!errors.address}
                      />
                      {errors.address && (
                        <FormFeedback>{errors.address}</FormFeedback>
                      )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="description">
                        {t("Description")}
                      </Label>
                      <textarea
                        className="form-control"
                        placeholder={t("Description")}
                        id="description"
                        name="description"
                        rows={3}
                        disabled={!formData.provider}
                        value={formData.description}
                        onChange={(e) => {
                          setErrors((prev: any) => {
                            delete prev.description;
                            return prev;
                          });
                          setFormData((prev) => ({
                            ...prev,
                            description: e.target.value,
                          }));
                        }}
                      ></textarea>
                    </div>
                  </Col>
                </Row>
              </TabPane>

              <TabPane id="steparrow-description-info" tabId={2}>
                <CreditCard
                  value={{
                    cardNumber: formData.card_no,
                    cardHolder: formData.card_holder_name,
                    expiry: formData.expire_date,
                    cvc: formData.cvv,
                  }}
                  onChange={(e: PosPazarTypes.CardInfoProps) => {
                    setFormData((prev) => ({
                      ...prev,
                      card_no: e.cardNumber,
                      card_holder_name: e.cardHolder,
                      expire_date: e.expiry,
                      cvv: e.cvc,
                    }));
                  }}
                  isValid={(e) => setCardInfoIsValid(e)}
                  {...props}
                />
              </TabPane>

              <TabPane id="pills-experience" tabId={3}>
                <div className="installment-options mt-4">
                  <h5>{t("Installment Options")}</h5>
                  <div className="d-flex flex-wrap">
                    {installments.map((option) => (
                      <div
                        key={`installment-${option.installment}`}
                        className={`p-3 m-2 border rounded ${
                          formData.installment === option.installment
                            ? "bg-primary text-white"
                            : "bg-light"
                        }`}
                        style={{
                          cursor: "pointer",
                          width: "calc(25% - 16px)", // 4 divs per row with some margin
                          textAlign: "center",
                        }}
                        onClick={() => {
                          setFormData((prev) => ({
                            ...prev,
                            installment: option.installment,
                          }));
                        }}
                      >
                        <span className="text-lowercase">
                          {option.installment} {t("Installment")}
                        </span>
                        <br />
                        <strong>
                          {t("Total")} {option.amount.toFixed(2)} TL
                        </strong>
                      </div>
                    ))}
                  </div>
                </div>
              </TabPane>
            </TabContent>
            <div className="d-flex align-items-start gap-3 mt-4">
              <Button
                type="button"
                className="btn btn-light btn-label previestab"
                onClick={() => {
                  toggleArrowTab(activeArrowTab - 1);
                }}
                hidden={activeArrowTab === 1}
              >
                <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                {activeArrowTab === 2 ? t("Customer Info") : t("Card Info")}
              </Button>
              {activeArrowTab !== 3 ? (
                <Button
                  type="button"
                  className="btn btn-success btn-label right ms-auto nexttab nexttab"
                  onClick={() => {
                    toggleArrowTab(activeArrowTab + 1);
                  }}
                  disabled={
                    activeArrowTab === 2 && installmentsStatus !== "succeeded"
                  }
                >
                  {installmentsStatus === "loading" ? (
                    <span className="label-icon align-middle fs-16">
                      <Spinner size="sm" color="light" />
                    </span>
                  ) : (
                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                  )}

                  {activeArrowTab === 1
                    ? t("Card Info")
                    : t("Installment Options")}
                </Button>
              ) : (
                <Button
                  type="button"
                  className="btn btn-success btn-label right ms-auto"
                  onClick={handleSubmit}
                  disabled={
                    paymentStatus === "loading" || paymentStatus === "succeeded"
                  }
                >
                  {paymentStatus === "loading" && (
                    <span className="label-icon align-middle fs-16">
                      <Spinner size="sm" color="light" />
                    </span>
                  )}
                  {paymentStatus !== "loading" &&
                    paymentStatus !== "failed" && (
                      <i className="ri-check-line label-icon align-middle fs-16 ms-2"></i>
                    )}
                  {paymentStatus === "failed" && (
                    <i className="ri-error-warning-line label-icon align-middle fs-16 ms-2"></i>
                  )}
                  {t("Pay")}
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  installments: state.Installment.installments,
  installmentsStatus: state.Installment.installmentsStatus,
  installementsError: state.Installment.installementsError,
  paymentStatus: state.PosProvider.paymentStatus,
  paymentError: state.PosProvider.paymentError,

  providers: state.PosProvider.providers,
  cities: state.Core.cities,
  townships: state.Core.townships,
});
const mapDispatchToProps = (dispatch: any) => ({
  fetchProviders: () => dispatch(getAllProviders()),
  fetchCities: () => dispatch(getAllCities()),
  fetchTownships: (cityId: number) => dispatch(getAllTownships(cityId)),
  fetchInstallments: (
    provider: string,
    amount: number,
    binNumber: string,
    reflect_cost: boolean
  ) =>
    dispatch(
      getInstallmentOptions({ provider, amount, binNumber, reflect_cost })
    ),
  requestPayment: (provider_key: string, body: PosPazarTypes.ThreeDPayload) =>
    dispatch(new3dPayment({ provider_key, body })),
  resetState: () => {
    dispatch(resetPaymentState());
    dispatch(resetInstallmentState());
  },
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ManuelPayment);
