import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteDealerUser,
  getDealerUsers,
  patchDealerUser,
  postDealerUser,
} from "helpers/api/inner_urls";

export const getUsersThunk = createAsyncThunk<
  PosPazarTypes.DealerUserItem[],
  void,
  { rejectValue: PosPazarTypes.ApiRequestError }
>("user/getUsers", async (_, { rejectWithValue }) => {
  try {
    const { data } = await getDealerUsers();
    return data;
  } catch (error: any) {
    if (error?.response?.data?.message) {
      return rejectWithValue({
        message: error.response.data.message,
        status: error.response.status,
        data: error.response.data,
      });
    }
    return rejectWithValue({
      message: error.message,
      status: error.response.status,
    });
  }
});

export const getUserByIdThunk = createAsyncThunk<
  PosPazarTypes.DealerUserItem,
  number,
  { rejectValue: PosPazarTypes.ApiRequestError }
>("user/getUserById", async (userId, { rejectWithValue }) => {
  try {
    const { data } = await getDealerUsers();
    const user = data.find((user) => user.id === userId);
    if (user) {
      return user;
    }
    return rejectWithValue({
      message: "User not found",
      status: 404,
    });
  } catch (error: any) {
    if (error?.response?.data?.message) {
      return rejectWithValue({
        message: error.response.data.message,
        status: error.response.status,
        data: error.response.data,
      });
    }
    return rejectWithValue({
      message: error.message,
      status: error.response.status,
    });
  }
});

export const createUserThunk = createAsyncThunk<
  void,
  PosPazarTypes.DealerUserCreatePayload,
  { rejectValue: PosPazarTypes.ApiRequestError }
>("user/createUser", async (payload, { rejectWithValue }) => {
  try {
    await postDealerUser(payload);
  } catch (error: any) {
    if (error?.response?.data?.message) {
      return rejectWithValue({
        message: error.response.data.message,
        status: error.response.status,
        data: error.response.data,
      });
    }
    return rejectWithValue({
      message: error.message,
      status: error.response.status,
    });
  }
});

export const updateUserThunk = createAsyncThunk<
  void,
  PosPazarTypes.DealerUserUpdatePayload,
  { rejectValue: PosPazarTypes.ApiRequestError }
>("user/updateUser", async (payload, { rejectWithValue }) => {
  try {
    await patchDealerUser(payload);
  } catch (error: any) {
    if (error?.response?.data?.message) {
      return rejectWithValue({
        message: error.response.data.message,
        status: error.response.status,
        data: error.response.data,
      });
    }
    return rejectWithValue({
      message: error.message,
      status: error.response.status,
    });
  }
});

export const deleteUserThunk = createAsyncThunk<
  void,
  number,
  { rejectValue: PosPazarTypes.ApiRequestError }
>("user/deleteUser", async (userId, { rejectWithValue }) => {
  try {
    await deleteDealerUser(userId);
  } catch (error: any) {
    if (error?.response?.data?.message) {
      return rejectWithValue({
        message: error.response.data.message,
        status: error.response.status,
        data: error.response.data,
      });
    }
    return rejectWithValue({
      message: error.message,
      status: error.response.status,
    });
  }
});
