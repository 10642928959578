import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTransactionReq,
  getTransactionsReq,
  postCancelTransaction,
  postRefundTransaction,
} from "helpers/api/inner_urls";

export const getTransactions = createAsyncThunk<
  PosPazarTypes.ApiPagination<PosPazarTypes.TransactionListItem>,
  { page?: number; created_at?: string; status?: string; type?: string },
  { rejectValue: PosPazarTypes.ApiRequestError }
>("transaction/getTransactions", async (queries, { rejectWithValue }) => {
  try {
    let queryStr = "";
    try {
      const _queries: any = { ...queries };
      if (!(_queries.page > 1)) {
        delete _queries.page;
      }
      for (const key of Object.keys(_queries)) {
        if (!_queries[key] || _queries[key] === "") {
          delete _queries[key];
        }
      }
      queryStr = new URLSearchParams(_queries).toString();
    } catch {}
    const { data } = await getTransactionsReq(queryStr);
    return data;
  } catch (error: any) {
    if (error?.response?.data?.message) {
      return rejectWithValue({
        message: error.response.data.message,
        status: error.response.status,
        data: error.response.data,
      });
    }
    return rejectWithValue({
      message: error.message,
      status: error.response.status,
    });
  }
});

export const getTransaction = createAsyncThunk<
  PosPazarTypes.TransactionDetail,
  string,
  { rejectValue: PosPazarTypes.ApiRequestError }
>("transaction/getTransaction", async (uniq: string, { rejectWithValue }) => {
  try {
    const { data } = await getTransactionReq(uniq);
    return data;
  } catch (error: any) {
    if (error?.response?.data?.message) {
      return rejectWithValue({
        message: error.response.data.message,
        status: error.response.status,
        data: error.response.data,
      });
    }
    return rejectWithValue({
      message: error.message,
      status: error.response.status,
    });
  }
});

export const cancelTransactionThunk = createAsyncThunk<
  void,
  string,
  { rejectValue: PosPazarTypes.ApiRequestError }
>(
  "transaction/cancelTransaction",
  async (uniq: string, { rejectWithValue, dispatch }) => {
    try {
      await postCancelTransaction(uniq);
      dispatch(getTransaction(uniq));
    } catch (error: any) {
      if (error?.response?.data?.message) {
        return rejectWithValue({
          message: error.response.data.message,
          status: error.response.status,
          data: error.response.data,
        });
      }
      return rejectWithValue({
        message: error.message,
        status: error.response.status,
      });
    }
  }
);

export const refundTransactionThunk = createAsyncThunk<
  void,
  { uniq: string; body: { amount: number } },
  { rejectValue: PosPazarTypes.ApiRequestError }
>(
  "transaction/refundTransaction",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { uniq, body } = payload;
      await postRefundTransaction(uniq, body.amount);
      dispatch(getTransaction(uniq));
    } catch (error: any) {
      if (error?.response?.data?.message) {
        return rejectWithValue({
          message: error.response.data.message,
          status: error.response.status,
          data: error.response.data,
        });
      }
      return rejectWithValue({
        message: error.message,
        status: error.response.status,
      });
    }
  }
);
