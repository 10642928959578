import {
  postPaymentLinkReq,
  deletePaymentLinkReq,
  getPaymentLinkDetails,
  getPaymentLinks,
  patchPaymentLink,
} from "helpers/api/inner_urls";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { routerPath } from "Routes/allRoutes";

export const getAllPayments = createAsyncThunk(
  "paymentLink/getPaymentLinks",
  async (pageNumber?: number) => {
    const { data } = await getPaymentLinks(pageNumber);
    return data;
  }
);

export const getOnePaymentLink = createAsyncThunk<
  PosPazarTypes.PaymentLink,
  string,
  { rejectValue: PosPazarTypes.ApiRequestError }
>(
  "paymentLink/getPaymentLink",
  async (paymentId: string, { rejectWithValue }) => {
    try {
      const { data } = await getPaymentLinkDetails(paymentId);
      return data;
    } catch (error: any) {
      if (error?.response?.data?.message) {
        return rejectWithValue({
          message: error.response.data.message,
          status: error.response.status,
          data: error.response.data,
        });
      }
      return rejectWithValue({
        message: error.message,
        status: error.response.status,
      });
    }
  }
);

export const createPaymentLink = createAsyncThunk(
  "paymentLink/createPaymentLink",
  async (paymentLink: any) => {
    await postPaymentLinkReq(paymentLink);
  }
);

export const updatePaymentLink = createAsyncThunk(
  "paymentLink/updatePaymentLink",
  async (payload: any) => {
    const { uuid, body } = payload;
    await patchPaymentLink(uuid, body);
    window.location.href = routerPath.Payment;
  }
);

export const deletePaymentLink = createAsyncThunk(
  "paymentLink/deletePaymentLink",
  async (uuid: string) => {
    await deletePaymentLinkReq(uuid);
    return uuid;
  }
);
