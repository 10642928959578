import { APIClient } from "./inner_api";

const api = new APIClient();

//#region Auth
export const requestOtpLogin = async (body: PosPazarTypes.LoginPaylod) =>
  api.post<PosPazarTypes.LoginOtpResponse | PosPazarTypes.LoginNoOtpResponse>(
    "/panel/token/otp/send/",
    body
  );
export const verifyOtpLogin = async (body: {
  token: string;
  otp_code: string;
}) => api.post<PosPazarTypes.LoginResponse>("/panel/token/otp/verify/", body);
export const refreshToken = async (body: { refresh: String }) =>
  api.post("/panel/token/refresh", body);
export const setBlackList = async (body: { refresh: String }) =>
  api.post("/panel/token/blacklist/", body);
export const forgotPassword = async (body: { email: String }) =>
  api.post<{ detail: String; token: String }>(
    "/panel/password/reset-request/",
    body
  );
export const resetPassword = async (body: PosPazarTypes.ResetPasswordPayload) =>
  api.post<{ detail: String }>(`/panel/password/reset/`, body);
//#endregion Auth

//#region Core
export const getCoreCountries = async () =>
  api.publicGet<Array<PosPazarTypes.Country>>("/panel/countries/");
export const getCoreCities = async () =>
  api.publicGet<Array<PosPazarTypes.City>>(`/panel/cities/?country_code=tr`);
export const getCoreTownships = async (city_id: number) =>
  api.publicGet<Array<PosPazarTypes.Township>>(
    `/panel/townships/?city_id=${city_id}`
  );
export const getCoreProviders = async () =>
  api.publicGet<Array<PosPazarTypes.PosProvider>>("/panel/pos-providers/");
export const postMerchantApplication = async (
  body: PosPazarTypes.MerchantApplicationBody
) => api.publicPost("/panel/application/", body);
export const getIdentificationCheck = async (identificationNumber: string) =>
  api.get(
    `/panel/application/check?identification_number=${identificationNumber}`
  );
//#endregion Core

//#region Dashboard
export const getDashboardData = async () =>
  api.get<PosPazarTypes.DashboardReducer>("/panel/dashboard/");
export const getMe = async () =>
  api.get<PosPazarTypes.UserInfoReducer>("/panel/me/");
export const getDealers = async () =>
  api.get<Array<PosPazarTypes.DealerList>>("/panel/merchant/");
export const patchActiveDealerReq = async (body: { merchant_id: number }) =>
  api.patch("/panel/merchant/select/", body);
export const patchChangePassword = async (
  body: PosPazarTypes.ChangePasswordPayload
) => api.patch("/panel/me/password/", body);
//#endregion Dashboard

//#region Pos Providers
export const getPosProviders = async () =>
  api.get<Array<PosPazarTypes.PosProvider>>("/panel/pos-provider/");
export const getInstallments = async (
  provider: string,
  amount: number,
  binNumber: string,
  reflect_cost: boolean
) =>
  api.get<PosPazarTypes.InstallmentsItem[]>(
    `/panel/pos-provider/${provider}/installments/?amount=${amount}&bin_number=${binNumber}&reflect_cost=${reflect_cost}`
  );
export const post3DPayment = async (
  provider_key: string,
  body: PosPazarTypes.ThreeDPayload
) =>
  api.post<PosPazarTypes.ThreeDResponse>(
    `/panel/pos-provider/${provider_key}/payment/`,
    body
  );
export const getRates = async () => {
  return api.get<PosPazarTypes.RatesResponse>("/panel/rates/");
};
//#endregion Pos Providers

//#region Payment Links
export const getPaymentLinks = async (page?: number) =>
  api.get<PosPazarTypes.ApiPagination<PosPazarTypes.PaymentLinkItem>>(
    `/panel/payment-link/${page && page !== 1 ? `?page=${page}` : ""}`
  );
export const postPaymentLinkReq = async (
  body: PosPazarTypes.PaymentLinkPayload
) => api.post("/panel/payment-link/", body);
export const getPaymentLinksPaginated = async (page = 1, limit = 10) =>
  api.get(`/panel/payment-links?page=${page}&page_size=${limit}`);
export const getPaymentLinkDetails = async (uuid: String) =>
  api.get<PosPazarTypes.PaymentLink>(`/panel/payment-link/${uuid}/`);
export const patchPaymentLink = async (uuid: String, body: any) =>
  api.patch(`/panel/payment-link/${uuid}/`, body);
export const deletePaymentLinkReq = async (uuid: String) =>
  api.delete(`/panel/payment-link/${uuid}/`);
//#endregion Payment Links

//#region Dealer Users
export const getDealerUsers = async () =>
  api.get<Array<PosPazarTypes.DealerUserItem>>("/panel/merchant/users/");
export const postDealerUser = async (
  payload: PosPazarTypes.DealerUserCreatePayload
) => api.post<void>("/panel/merchant/users/", payload);
export const patchDealerUser = async (
  payload: PosPazarTypes.DealerUserUpdatePayload
) => api.patch<void>("/panel/merchant/users/", payload);
export const deleteDealerUser = async (id: number) =>
  api.delete(`/panel/merchant/users/`, { user_id: id });
//#endregion Dealer Users

//#region Sub Dealers
export const getSubDealers = async () =>
  api.get<PosPazarTypes.SubDealerItem[]>("/panel/merchant/sub-merchant/");
export const postSubDealer = async (
  payload: PosPazarTypes.SubDealerCreatePayload
) => api.post<void>("/panel/merchant/sub-merchant/", payload);
export const patchSubDealer = async (
  subDealerId: number,
  payload: PosPazarTypes.SubDealerCreatePayload
) =>
  api.patch<void>(`/panel/merchant/sub-merchant/`, {
    ...payload,
    merchant_id: subDealerId,
  });
export const deleteSubDealer = async (subDealerId: number) =>
  api.delete(`/panel/merchant/sub-merchant/`, {
    merchant_id: subDealerId,
  });
//#endregion Sub Dealers

//#region Link Payment
export const getLinkPayment = async (uuid: string) =>
  api.get<PosPazarTypes.LinkPayment>(`/panel/link-payment/${uuid}/`);
export const postLinkPayment = async (
  uuid: string,
  body: PosPazarTypes.LinkPaymentPayload
) => api.post<any>(`/panel/link-payment/${uuid}/`, body);
//#endregion Link Payment

//#region Transactions
export const getTransactionsReq = async (qs?: string) =>
  api.get<PosPazarTypes.ApiPagination<PosPazarTypes.TransactionListItem>>(
    `/panel/transaction/${qs ? `?${qs}` : ""}`
  );
export const getTransactionReq = async (uniq: string) =>
  api.get<PosPazarTypes.TransactionDetail>(`/panel/transaction/${uniq}/`);
export const postCancelTransaction = async (uniq: string) =>
  api.post(`/panel/transaction/${uniq}/cancel/`);
export const postRefundTransaction = async (uniq: string, amount: number) =>
  api.post(`/panel/transaction/${uniq}/refund/`, { amount });
//#endregion Transactions
