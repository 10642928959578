import React, { useEffect } from "react";

//import Scss
import "./assets/scss/themes.scss";

//imoprt Route
import Route from "./Routes";

function App() {
  useEffect(() => {
    const theme = localStorage.getItem("theme") || "light";
    document.documentElement.setAttribute("data-bs-theme", theme);
  }, []);
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
