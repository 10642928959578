import { mapTransactionType } from "helpers/data/mapping";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { routerPath } from "Routes/allRoutes";
import { RootState } from "slices";
import {
  cancelTransactionThunk,
  getTransaction,
  refundTransactionThunk,
} from "slices/thunks";
import { resetTransactionReducer } from "slices/transaction/transactionReducer";

const TransactionDetail = (props: PropsFromRedux) => {
  const { uniq } = useParams<{ uniq: string }>();
  const {
    fetchTransaction,
    transaction,
    transactionError,
    transactionStatus,
    dealers,
    cancelTransaction,
    refundTransaction,
    transactionCalcelStatus,
    transactionCancelError,
    transactionRefundStatus,
    transactionRefundError,
    resetState,
  } = props;
  const { t } = useTranslation();
  const [dealerName, setDealerName] = useState("");
  const [modalContent, setModalContent] = useState<"" | "refund" | "cancel">(
    ""
  );
  const [refundAmount, setRefundAmount] = useState(0);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const toggleCancelModal = () => {
    if (!isCancelModalOpen) {
      setModalContent("");
      setRefundAmount(0);
    }
    setIsCancelModalOpen(!isCancelModalOpen);
  };

  useEffect(() => {
    return () => {
      resetState();
    };
  }, [resetState]);

  useEffect(() => {
    const _dealer = dealers.find(
      (dealer) => dealer.id === transaction?.merchant
    );
    if (_dealer) {
      setDealerName(_dealer.name);
    } else {
      setDealerName("");
    }
  }, [dealers, transaction]);

  useEffect(() => {
    if (uniq) fetchTransaction(uniq);
  }, [fetchTransaction, uniq]);

  useEffect(() => {
    if (transactionStatus === "failed") {
      const toastId = toast.error(
        t(transactionError ?? "Unknown error occurred")
      );
      toast.onChange((popup) => {
        if (popup.status === "removed" && popup.id === toastId) {
          window.location.href = routerPath.Transaction;
        }
      });
    }
  }, [transactionStatus, transactionError, t]);

  const getStatus = (status?: string) => {
    if (!status) return "";
    let color = "";
    switch (status) {
      case "approved":
        color = "success";
        break;
      case "pending":
        color = "warning";
        break;
      case "rejected":
        color = "dark";
        break;
      case "cancelled":
        color = "danger";
        break;
      default:
        color = "secondary";
        break;
    }
    return (
      <Badge color={color} pill={true}>
        <Trans i18nKey={status} />
      </Badge>
    );
  };

  const handleCancelTransaction = () => {
    if (transaction) {
      cancelTransaction(transaction.uniq);
    }
  };

  const handleRefundTransaction = () => {
    if (transaction) {
      refundTransaction(transaction.uniq, refundAmount);
    }
  };

  useEffect(() => {
    switch (transactionCalcelStatus) {
      case "succeeded":
        toast.success(t("Successfully"));
        break;
      case "failed":
        toast.error(t(transactionCancelError ?? "Unknown error occurred"));
        break;
      default:
        break;
    }
    setModalContent("");
    setRefundAmount(0);
    setIsCancelModalOpen(false);
  }, [transactionCalcelStatus, transactionCancelError, t]);

  useEffect(() => {
    switch (transactionRefundStatus) {
      case "succeeded":
        toast.success(t("Successfully"));
        break;
      case "failed":
        toast.error(t(transactionRefundError ?? "Unknown error occurred"));
        break;
      default:
        break;
    }
    setModalContent("");
    setRefundAmount(0);
    setIsCancelModalOpen(false);
  }, [transactionRefundStatus, transactionRefundError, t]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Card className="card-height-100">
            <CardBody>
              <Row>
                <Col md="4">
                  <strong>
                    <Trans i18nKey="Type" />
                  </strong>
                  <p>
                    {t(mapTransactionType(transaction?.transaction_type) ?? "")}
                  </p>
                </Col>
                <Col md="4">
                  <strong>
                    <Trans i18nKey="Created At" />
                  </strong>
                  <p>
                    {transaction?.created_at &&
                      new Date(transaction.created_at).toLocaleString()}
                  </p>
                </Col>
                <Col md="4">
                  <strong>
                    <Trans i18nKey="Updated At" />
                  </strong>
                  <p>
                    {transaction?.updated_at &&
                      new Date(transaction.updated_at).toLocaleString()}
                  </p>
                </Col>
                <Col md="4">
                  <strong>
                    <Trans i18nKey="Provider" />
                  </strong>
                  <p>{t(transaction?.pos_provider ?? "")}</p>
                </Col>
                <Col md="4">
                  <strong>
                    <Trans i18nKey="Amount" />
                  </strong>
                  <p>{transaction?.amount}</p>
                </Col>
                <Col md="4">
                  <strong>
                    <Trans i18nKey="Total Amount" />
                  </strong>
                  <p>{transaction?.total_amount}</p>
                </Col>
                <Col md="4">
                  <strong>
                    <Trans i18nKey="Installment" />
                  </strong>
                  <p>{transaction?.installment}</p>
                </Col>
                <Col md="4">
                  <strong>
                    <Trans i18nKey="Status" />
                  </strong>
                  <p>{getStatus(transaction?.status)}</p>
                </Col>
                <Col md="4">
                  <strong>
                    <Trans i18nKey="Dealer" />
                  </strong>
                  <p>{dealerName}</p>
                </Col>
                <Col md="4">
                  <strong>
                    <Trans i18nKey="Related Object ID" />
                  </strong>
                  <p>{transaction?.object_id}</p>
                </Col>
                <Col md="4">
                  <strong>
                    <Trans i18nKey="City" />
                  </strong>
                  <p>{transaction?.city}</p>
                </Col>
                <Col md="4">
                  <strong>
                    <Trans i18nKey="Township" />
                  </strong>
                  <p>{transaction?.township}</p>
                </Col>
                {transaction?.transaction_type === "manuelpayment" && (
                  <>
                    <Col md="12">
                      <p className="text-muted pb-0 mb-0">
                        <Trans i18nKey="User" />
                      </p>
                      <hr className="mt-0" />
                    </Col>
                    <Col md="4">
                      <strong>
                        <Trans i18nKey="Name" />
                      </strong>
                      <p>{transaction?.detail?.first_name}&nbsp;</p>
                    </Col>
                    <Col md="4">
                      <strong>
                        <Trans i18nKey="Surname" />
                      </strong>
                      <p>{transaction?.detail?.last_name}&nbsp;</p>
                    </Col>
                    <Col md="4">
                      <strong>
                        <Trans i18nKey="Identity Number" />
                      </strong>
                      <p>{transaction?.detail?.identification_number}&nbsp;</p>
                    </Col>
                    <Col md="4">
                      <strong>
                        <Trans i18nKey="Phone Number" />
                      </strong>
                      <p>{transaction?.detail?.phone_number}&nbsp;</p>
                    </Col>
                    <Col md="4">
                      <strong>
                        <Trans i18nKey="Email" />
                      </strong>
                      <p>{transaction?.detail?.email}&nbsp;</p>
                    </Col>
                    <Col md="4">
                      <strong>
                        <Trans i18nKey="Address" />
                      </strong>
                      <p>{transaction?.detail?.address}&nbsp;</p>
                    </Col>
                  </>
                )}
              </Row>
            </CardBody>
            <CardFooter className="text-end">
              <Button
                color="danger"
                className="me-2"
                outline
                onClick={() => {
                  setModalContent("refund");
                  setIsCancelModalOpen(true);
                }}
              >
                <Trans i18nKey="Refund" />
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  setModalContent("cancel");
                  setIsCancelModalOpen(true);
                }}
              >
                <Trans i18nKey="Cancel" />
              </Button>
            </CardFooter>
          </Card>
        </Container>
      </div>
      <Modal isOpen={isCancelModalOpen} toggle={toggleCancelModal} centered>
        <ModalHeader toggle={toggleCancelModal}>
          <Trans i18nKey="Confirm" />
        </ModalHeader>
        {modalContent === "cancel" && (
          <>
            <ModalBody>
              <Trans i18nKey="Are you sure you want to cancel the transaction? This action cannot be undone." />
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleCancelModal}>
                <Trans i18nKey="Cancel" />
              </Button>
              <Button
                color="danger"
                onClick={handleCancelTransaction}
                disabled={transactionCalcelStatus === "loading"}
              >
                <Trans i18nKey="Confirm" />
              </Button>
            </ModalFooter>
          </>
        )}
        {modalContent === "refund" && (
          <>
            <ModalBody>
              <Trans i18nKey="Please enter the amount to be refunded" />:
              <div className="text-muted">
                <Trans i18nKey="Total Amount" />: {transaction?.total_amount}
              </div>
              <Input
                type="number"
                className="form-control"
                onChange={(e) => setRefundAmount(Number(e.target.value))}
                value={refundAmount}
                max={transaction?.total_amount}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleCancelModal}>
                <Trans i18nKey="Cancel" />
              </Button>
              <Button
                color="danger"
                onClick={handleRefundTransaction}
                disabled={transactionRefundStatus === "loading"}
              >
                <Trans i18nKey="Refund" />
              </Button>
            </ModalFooter>
          </>
        )}
        {/* <Trans i18nKey="Are you sure you want to continue with the deletion? This action cannot be undone." /> */}
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  transaction: state.Transaction.transaction,
  transactionStatus: state.Transaction.transactionStatus,
  transactionError: state.Transaction.transactionError,
  dealers: state.Dealer.dealerList,

  transactionCalcelStatus: state.Transaction.transactionCancelStatus,
  transactionCancelError: state.Transaction.transactionCancelError,
  transactionRefundStatus: state.Transaction.transactionRefundStatus,
  transactionRefundError: state.Transaction.transactionRefundError,
});
const mapDispatchToProps = (dispact: any) => ({
  fetchTransaction: (uniq: string) => dispact(getTransaction(uniq)),
  cancelTransaction: (uniq: string) => dispact(cancelTransactionThunk(uniq)),
  refundTransaction: (uniq: string, amount: number) =>
    dispact(refundTransactionThunk({ uniq, body: { amount } })),
  resetState: () => dispact(resetTransactionReducer()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TransactionDetail);
