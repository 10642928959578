import { useMemo, useEffect } from "react";
import { Container, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { RootState } from "slices";
import { getUsersThunk } from "slices/thunks";
import { connect, ConnectedProps } from "react-redux";
import { Trans, useTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routerPath } from "Routes/allRoutes";
import CustomTable from "Components/CustomTable";

const DealerUserList = (props: PropsFromRedux) => {
  const { users, usersStatus, usersError, getUsers } = props;
  const { t } = useTranslation();

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const columns = useMemo<PosPazarTypes.CustomTableColumn[]>(() => {
    return [
      { header: t("Name"), accessor: "first_name" },
      { header: t("Surname"), accessor: "last_name" },
      { header: t("User Name"), accessor: "username" },
      { header: t("Email"), accessor: "email" },
      { header: t("Phone Number"), accessor: "phone_number" },
      {
        header: t("SMS Verification at Login"),
        accessor: "otp_required",
        cell(row: PosPazarTypes.DealerUserItem) {
          return row.otp_required ? t("Yes") : t("No");
        },
      },
      {
        header: "",
        accessor: "id",
        cell(row: PosPazarTypes.DealerUserItem) {
          return (
            <Link
              to={routerPath.DealerUserDetail.replace(
                ":user_id",
                row.id.toString()
              )}
            >
              {t("Details")}
            </Link>
          );
        },
      },
    ];
  }, [t]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardHeader className="d-flex justify-content-between align-items-center">
              <CardTitle>
                <Trans i18nKey="Users" />
              </CardTitle>
              <Link
                to={routerPath.DealerUserCreate}
                className="btn btn-primary"
              >
                <Trans i18nKey="New" />
              </Link>
            </CardHeader>
            <CardBody>
              <CustomTable
                columns={columns}
                data={users}
                status={usersStatus}
                errorTooltip={usersError}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: state.User.users,
  usersStatus: state.User.usersStatus,
  usersError: state.User.usersError,
});

const mapDispatchToProps = (dispatch: any) => ({
  getUsers: () => dispatch(getUsersThunk()),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withTranslation()(DealerUserList));
