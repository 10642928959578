import withRouter from "../Components/Common/withRouter";
import { ToastContainer } from "react-toastify";

const NonAuthLayout = ({ children }: any) => {
  return (
    <div>
      {children}
      <ToastContainer />
    </div>
  );
};

export default withRouter(NonAuthLayout);
