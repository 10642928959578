import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/images/pospazar.png";
import CreditCard from "Components/CreditCard";
import {
  getAllCities,
  getAllTownships,
  getOnePaymentLinkRequest,
  postLinkPaymentRequest,
} from "slices/thunks";
import { RootState } from "slices";
import { useTranslation } from "react-i18next";
import {
  isEmail,
  isIdentityNumber,
  isPhoneNumber,
} from "helpers/data/validate";

const PaymentRequest: React.FC<PropsFromRedux> = (props) => {
  const {
    paymentLink,
    cities,
    townships,
    fetchAllCities,
    fetchAllTownships,
    fetchOnePaymentLinkRequest,
    paymentLinkStatus,
    paymentRequestStatus,
    updLinkPaymentRequest,
  } = props;
  const { t } = useTranslation();

  const { uuid } = useParams<{ uuid: string }>();
  const navigate = useNavigate();
  const [form, setForm] = useState({
    card_number: "",
    card_holder_name: "",
    expire_date: "",
    card_cvv: "",
    customer_first_name: "",
    customer_last_name: "",
    customer_identification_number: "",
    customer_email: "",
    customer_phone_number: "+90",
    customer_address: "",
    customer_city_id: 0,
    customer_township_id: 0,
    installment: 0,
    description: "",
  });
  const [cardIsValid, setCardIsValid] = useState(false);
  const [errors, setErrors] = useState({
    customer_first_name: "",
    customer_last_name: "",
    customer_identification_number: "",
    customer_email: "",
    customer_phone_number: "",
    customer_address: "",
    customer_city_id: "",
    customer_township_id: "",
    installment: "",
  });

  useEffect(() => {
    fetchAllCities();
  }, [fetchAllCities]);

  useEffect(() => {
    if (form.customer_city_id) {
      fetchAllTownships(form.customer_city_id);
    }
  }, [form.customer_city_id, fetchAllTownships]);

  useEffect(() => {
    if (paymentLink && Object.keys(paymentLink).length > 0) {
      setForm((prev) => ({
        ...prev,
        customer_city_id: paymentLink.city,
        customer_township_id: paymentLink.township,
        installment: paymentLink.minimum_installment,
        description: paymentLink.description,
      }));
    }
  }, [paymentLink]);

  useEffect(() => {
    if (!uuid || uuid.length !== 36) {
      toast.error(t("Invalid or expired payment page."), {
        autoClose: 5000,
      });
      setTimeout(() => {
        navigate("/");
      }, 5000);
    } else {
      fetchOnePaymentLinkRequest(uuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, navigate, fetchOnePaymentLinkRequest]);

  useEffect(() => {
    if (paymentLinkStatus === "failed") {
      toast.error(t("Invalid or expired payment page."), {
        autoClose: 5000,
      });
      setTimeout(() => {
        navigate("/");
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentLinkStatus]);

  useEffect(() => {
    switch (paymentRequestStatus) {
      case "loading":
        toast.info(t("Payment request sent, please wait..."), {
          autoClose: 5000,
        });
        break;
      case "failed":
        toast.error(t("Payment failed."), {
          autoClose: 5000,
        });
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentRequestStatus]);

  const formIsValid = () => {
    const errors = {
      customer_first_name: "",
      customer_last_name: "",
      customer_identification_number: "",
      customer_email: "",
      customer_phone_number: "",
      customer_address: "",
      customer_city_id: "",
      customer_township_id: "",
      installment: "",
    };
    let isValid = true;
    if (!form.customer_first_name) {
      errors.customer_first_name = t("Field is required");
      isValid = false;
    }
    if (!form.customer_last_name) {
      errors.customer_last_name = t("Field is required");
      isValid = false;
    }
    const validId = isIdentityNumber(form.customer_identification_number);
    if (!validId.isValid) {
      errors.customer_identification_number = t(validId.message);
      isValid = false;
    }
    const validEmail = isEmail(form.customer_email);
    if (!validEmail.isValid) {
      errors.customer_email = t(validEmail.message);
      isValid = false;
    }
    const validPhone = isPhoneNumber(form.customer_phone_number);
    if (!validPhone.isValid) {
      errors.customer_phone_number = t(validPhone.message);
      isValid = false;
    }
    if (!form.customer_address) {
      errors.customer_address = t("Field is required");
      isValid = false;
    }
    if (!form.customer_city_id) {
      errors.customer_city_id = t("Field is required");
      isValid = false;
    }
    if (!form.customer_township_id) {
      errors.customer_township_id = t("Field is required");
      isValid = false;
    }
    if (!form.installment) {
      errors.installment = t("Field is required");
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  };

  const submitForm = () => {
    if (!uuid) {
      return;
    }
    if (!formIsValid()) {
      return;
    }
    if (!cardIsValid) {
      toast.error("Lütfen geçerli bir kart bilgisi giriniz", {
        autoClose: 5000,
      });
      return;
    }
    updLinkPaymentRequest({
      uuid,
      body: {
        card_number: form.card_number,
        card_holder_name: form.card_holder_name,
        card_expiration_month: Number(form.expire_date.split("/")[0]),
        card_expiration_year: Number(form.expire_date.split("/")[1]),
        card_cvv: form.card_cvv,
        customer_first_name: form.customer_first_name,
        customer_last_name: form.customer_last_name,
        customer_identification_number: form.customer_identification_number,
        customer_email: form.customer_email,
        customer_phone_number: form.customer_phone_number,
        customer_address: form.customer_address,
        customer_city_id: form.customer_city_id,
        customer_township_id: form.customer_township_id,
        installment: form.installment,
        description: form.description,
      },
    });
  };

  const onChangeCreditCard = (e: PosPazarTypes.CardInfoProps) => {
    setForm({
      ...form,
      card_holder_name: e.cardHolder,
      card_number: e.cardNumber,
      expire_date: e.expiry,
      card_cvv: e.cvc,
    });
  };

  const renderInstallmentOptions = () => {
    const options = [];
    if (paymentLink?.minimum_installment && paymentLink?.maximum_installment) {
      for (
        let i = paymentLink.minimum_installment;
        i <= paymentLink.maximum_installment;
        i++
      ) {
        options.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
    }
    return options;
  };

  return (
    <Container className="auth-page-content mt-lg-5">
      <Row className="justify-content-center">
        <Col md={12} className="text-center w-75">
          <Link to="/">
            <img src={logo} alt="Logo" className="mb-4 w-50" />
          </Link>
        </Col>
        <Col md={9}>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="formTitle">{t("Title")}</Label>
                <Input
                  type="text"
                  name="title"
                  id="formTitle"
                  value={paymentLink?.title || ""}
                  readOnly
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="formTitle">{t("Description")}</Label>
                <Input
                  type="text"
                  name="title"
                  id="formTitle"
                  value={form.description || ""}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="formAmount">{t("Amount")}</Label>
                <Input
                  type="text"
                  name="amount"
                  id="formAmount"
                  value={paymentLink?.amount + " ₺"}
                  readOnly
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="formCityId">{t("City")}</Label>
                <Input
                  type="select"
                  id="city_id"
                  value={form.customer_city_id}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      customer_city_id: Number(e.target.value),
                      customer_township_id: 0,
                    });
                    setErrors({ ...errors, customer_city_id: "" });
                    fetchAllTownships(Number(e.target.value));
                  }}
                  invalid={Boolean(errors.customer_city_id)}
                >
                  <option value="0" hidden>
                    {t("Select City")}
                  </option>
                  {cities.map((city: any) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </Input>
                {errors.customer_city_id && (
                  <FormFeedback>{errors.customer_city_id}</FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="formTownshipId">{t("Township")}</Label>
                <Input
                  type="select"
                  id="townshipId"
                  value={form.customer_township_id}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      customer_township_id: Number(e.target.value),
                    });
                    setErrors({ ...errors, customer_township_id: "" });
                  }}
                  disabled={!form.customer_city_id}
                  invalid={Boolean(errors.customer_township_id)}
                >
                  <option value="0" hidden>
                    {t("Select Township")}
                  </option>
                  {townships.map((township: any) => (
                    <option key={township.id} value={township.id}>
                      {township.name}
                    </option>
                  ))}
                </Input>
                {errors.customer_township_id && (
                  <FormFeedback>{errors.customer_township_id}</FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="formCustomerAddress">{t("Address")}</Label>
                <Input
                  type="text"
                  name="customer_address"
                  id="formCustomerAddress"
                  value={form.customer_address}
                  onChange={(e) => {
                    setForm({ ...form, customer_address: e.target.value });
                    setErrors({ ...errors, customer_address: "" });
                  }}
                  invalid={Boolean(errors.customer_address)}
                />
                {errors.customer_address && (
                  <FormFeedback>{errors.customer_address}</FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="formCustomerFirstName">{t("Name")}</Label>
                <Input
                  type="text"
                  name="customer_first_name"
                  id="formCustomerFirstName"
                  value={form.customer_first_name}
                  onChange={(e) => {
                    setForm({ ...form, customer_first_name: e.target.value });
                    setErrors({ ...errors, customer_first_name: "" });
                  }}
                  invalid={Boolean(errors.customer_first_name)}
                />
                {errors.customer_first_name && (
                  <FormFeedback>{errors.customer_first_name}</FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="formCustomerLastName">{t("Surname")}</Label>
                <Input
                  type="text"
                  name="customer_last_name"
                  id="formCustomerLastName"
                  value={form.customer_last_name}
                  onChange={(e) => {
                    setForm({ ...form, customer_last_name: e.target.value });
                    setErrors({ ...errors, customer_last_name: "" });
                  }}
                  invalid={Boolean(errors.customer_last_name)}
                />
                {errors.customer_last_name && (
                  <FormFeedback>{errors.customer_last_name}</FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="formCustomerIdentificationNumber">
                  {t("Identity Number")}
                </Label>
                <Input
                  type="text"
                  name="customer_identification_number"
                  id="formCustomerIdentificationNumber"
                  value={form.customer_identification_number}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      customer_identification_number: e.target.value,
                    });
                    setErrors({
                      ...errors,
                      customer_identification_number: "",
                    });
                  }}
                  invalid={Boolean(errors.customer_identification_number)}
                />
                {errors.customer_identification_number && (
                  <FormFeedback>
                    {errors.customer_identification_number}
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row></Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="formCustomerEmail">{t("Email")}</Label>
                <Input
                  type="email"
                  name="customer_email"
                  id="formCustomerEmail"
                  value={form.customer_email}
                  onChange={(e) => {
                    setForm({ ...form, customer_email: e.target.value });
                    setErrors({ ...errors, customer_email: "" });
                  }}
                  invalid={Boolean(errors.customer_email)}
                />
                {errors.customer_email && (
                  <FormFeedback>{errors.customer_email}</FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="formCustomerPhoneNumber">{t("Phone Number")}</Label>
                <Input
                  type="tel"
                  name="customer_phone_number"
                  id="formCustomerPhoneNumber"
                  value={form.customer_phone_number}
                  onChange={(e) => {
                    const { value } = e.target;
                    const formattedValue = value.replace(/\D/g, "").slice(2);
                    if (formattedValue.length <= 10) {
                      setForm({
                        ...form,
                        customer_phone_number: "+90" + formattedValue,
                      });
                      setErrors({ ...errors, customer_phone_number: "" });
                    }
                  }}
                  maxLength={13}
                  invalid={Boolean(errors.customer_phone_number)}
                />
                {errors.customer_phone_number && (
                  <FormFeedback>{errors.customer_phone_number}</FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="formInstallment">{t("Installment")}</Label>
                <Input
                  type="select"
                  name="installment"
                  id="formInstallment"
                  value={form.installment}
                  onChange={(e) => {
                    setForm({ ...form, installment: Number(e.target.value) });
                  }}
                  invalid={Boolean(errors.installment)}
                >
                  {renderInstallmentOptions()}
                </Input>
                {errors.installment && (
                  <FormFeedback>{errors.installment}</FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <CreditCard
              value={{
                cardHolder: form.card_holder_name,
                cardNumber: form.card_number,
                expiry: form.expire_date,
                cvc: form.card_cvv,
              }}
              onChange={onChangeCreditCard}
              isValid={setCardIsValid}
              {...props}
            />
          </FormGroup>
          <Button
            color="primary"
            type="button"
            onClick={submitForm}
            className="w-100 mt-3"
            disabled={
              !(
                paymentLinkStatus === "succeeded" &&
                paymentRequestStatus === "idle"
              )
            }
          >
            {t("Pay")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  paymentLink: state.PaymentRequest.paymentLink,
  paymentLinkStatus: state.PaymentRequest.paymentLinkStatus,
  paymentRequestStatus: state.PaymentRequest.paymentRequestStatus,
  cities: state.Core.cities,
  townships: state.Core.townships,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchOnePaymentLinkRequest: (uuid: string) =>
    dispatch(getOnePaymentLinkRequest(uuid)),
  fetchAllCities: () => dispatch(getAllCities()),
  fetchAllTownships: (cityId: number) => dispatch(getAllTownships(cityId)),
  updLinkPaymentRequest: (paylod: {
    uuid: string;
    body: PosPazarTypes.LinkPaymentPayload;
  }) => dispatch(postLinkPaymentRequest(paylod)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PaymentRequest);
