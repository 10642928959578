import { createSlice } from "@reduxjs/toolkit";
import {
  createUserThunk,
  deleteUserThunk,
  getUserByIdThunk,
  getUsersThunk,
  updateUserThunk,
} from "./userThunk";

type StateType = {
  users: Array<PosPazarTypes.DealerUserItem>;
  usersStatus: PosPazarTypes.ApiRequestStatus;
  usersError: string | undefined;

  user: PosPazarTypes.DealerUserItem | null;
  userStatus: PosPazarTypes.ApiRequestStatus;
  userError: string | undefined;

  userCreateStatus: PosPazarTypes.ApiRequestStatus;
  userCreateError: string | undefined;
  userUpdateStatus: PosPazarTypes.ApiRequestStatus;
  userUpdateError: string | undefined;
  userDeleteStatus: PosPazarTypes.ApiRequestStatus;
  userDeleteError: string | undefined;
};

const initialState: StateType = {
  users: [],
  usersStatus: "idle",
  usersError: undefined,

  user: null,
  userStatus: "idle",
  userError: undefined,

  userCreateStatus: "idle",
  userCreateError: undefined,
  userUpdateStatus: "idle",
  userUpdateError: undefined,
  userDeleteStatus: "idle",
  userDeleteError: undefined,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUserState(state) {
      state.user = null;
      state.userStatus = "idle";
      state.userError = undefined;
      state.userCreateStatus = "idle";
      state.userCreateError = undefined;
      state.userUpdateStatus = "idle";
      state.userUpdateError = undefined;
      state.userDeleteStatus = "idle";
      state.userDeleteError = undefined;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUsersThunk.fulfilled, (state, action) => {
        state.users = action.payload;
        state.usersStatus = "succeeded";
        state.usersError = undefined;
      })
      .addCase(getUsersThunk.rejected, (state, action) => {
        state.users = [];
        state.usersStatus = "failed";
        state.usersError = action.payload?.message;
      })
      .addCase(getUsersThunk.pending, (state) => {
        state.usersStatus = "loading";
        state.usersError = undefined;
        state.users = [];
      });
    builder
      .addCase(getUserByIdThunk.fulfilled, (state, action) => {
        state.user = action.payload;
        state.userStatus = "succeeded";
        state.userError = undefined;
      })
      .addCase(getUserByIdThunk.rejected, (state, action) => {
        state.user = null;
        state.userStatus = "failed";
        state.userError = action.payload?.message;
      })
      .addCase(getUserByIdThunk.pending, (state) => {
        state.userStatus = "loading";
        state.userError = undefined;
        state.user = null;
      });
    builder
      .addCase(createUserThunk.pending, (state) => {
        state.userCreateStatus = "loading";
        state.userCreateError = undefined;
      })
      .addCase(createUserThunk.fulfilled, (state) => {
        state.userCreateStatus = "succeeded";
        state.userCreateError = undefined;
      })
      .addCase(createUserThunk.rejected, (state, action) => {
        state.userCreateStatus = "failed";
        state.userCreateError = action.payload?.message;
      });
    builder
      .addCase(updateUserThunk.pending, (state) => {
        state.userUpdateStatus = "loading";
        state.userUpdateError = undefined;
      })
      .addCase(updateUserThunk.fulfilled, (state) => {
        state.userUpdateStatus = "succeeded";
        state.userUpdateError = undefined;
      })
      .addCase(updateUserThunk.rejected, (state, action) => {
        state.userUpdateStatus = "failed";
        state.userUpdateError = action.payload?.message;
      });
    builder
      .addCase(deleteUserThunk.pending, (state) => {
        state.userDeleteStatus = "loading";
        state.userDeleteError = undefined;
      })
      .addCase(deleteUserThunk.fulfilled, (state) => {
        state.userDeleteStatus = "succeeded";
        state.userDeleteError = undefined;
      })
      .addCase(deleteUserThunk.rejected, (state, action) => {
        state.userDeleteStatus = "failed";
        state.userDeleteError = action.payload?.message;
      });
  },
});

export const { resetUserState } = userSlice.actions;
export default userSlice.reducer;
